import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PeopleView from "@/views/PeopleView.vue";
import PlanetView from "@/views/PlanetView.vue";
import ProfitView from "@/views/ProfitView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/people",
    name: "people",
    component: PeopleView,
  },
  {
    path: "/planet",
    name: "planet",
    component: PlanetView,
  },
  {
    path: "/profit",
    name: "profit",
    component: ProfitView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Using the variables to satisfy ESLint, even if not directly utilized
    if (to || from || savedPosition) {
      // This block is intentionally left blank as the variables are not needed here
    }
    return { top: 0 }; // Scrolls to the top of the page on route change
  },
});

export default router;
