<template>
  <HeroSection v-if="!getLoading" />
  <PeopleSection v-if="!getLoading" />
  <PlanetSection v-if="!getLoading" />
  <ProfitSection v-if="!getLoading" />
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import PeopleSection from "@/components/PeopleSection.vue";
import PlanetSection from "@/components/PlanetSection.vue";
import ProfitSection from "@/components/ProfitSection.vue";

HeroSection;
export default {
  name: "HomeView",
  computed: {
    getLoading() {
      return this.$store.getters.getLoading;
    },
  },
  created() {
    this.$store.dispatch("fetchInfoValues");
    this.$store.dispatch("fetchPillars");
  },
  components: {
    HeroSection,
    PeopleSection,
    PlanetSection,
    ProfitSection,
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
