<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 2000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <rect
      id="Artboard1"
      x="0"
      y="0"
      width="2000"
      height="2000"
      style="fill: none"
    />
    <g id="Artboard11" serif:id="Artboard1">
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3223.91,1722.98L3398.17,1651.83L3209.72,1603.8L3223.91,1722.98Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3694,429.747C3910.43,607.351 4086.84,951.956 3851.53,1276.85C3698.78,1487.76 3276.41,1514.02 3017.96,1501.18C2906.84,1495.66 2615.79,1548.34 2432.92,1347.81C2149.76,1037.32 2341.9,483.901 2858.54,313.69C3140.74,220.715 3468.3,244.539 3694,429.747Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3397.66,1651.83L3633.17,1615.36C3669.31,1609.76 3703.49,1595.27 3732.64,1573.2L3842.48,1490C3862.51,1474.83 3856.17,1443.24 3831.84,1436.97L3491.18,1349.21L3397.66,1651.83Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3008.69,1428.66L2694.95,1427.14L2730.67,759.332L2938.58,760.133L3008.69,1428.66Z"
          style="fill: rgb(95, 146, 67); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2556.17,877.339L2552.83,877.339C2552.83,881.452 2551.17,885.161 2548.47,887.859C2545.78,890.554 2542.07,892.216 2537.95,892.217C2533.84,892.216 2530.13,890.554 2527.43,887.859C2524.74,885.161 2523.08,881.452 2523.07,877.339C2523.08,873.225 2524.74,869.516 2527.43,866.818C2530.13,864.123 2533.84,862.461 2537.95,862.46C2542.07,862.461 2545.78,864.123 2548.47,866.818C2551.17,869.516 2552.83,873.225 2552.83,877.339L2556.17,877.339C2556.17,867.277 2548.01,859.122 2537.95,859.121C2527.89,859.122 2519.74,867.277 2519.74,877.339C2519.74,887.4 2527.89,895.555 2537.95,895.556C2548.01,895.555 2556.17,887.4 2556.17,877.339Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3502.7,623.2L3499.36,623.2C3499.36,627.314 3497.7,631.023 3495.01,633.72C3492.31,636.416 3488.6,638.077 3484.49,638.078C3480.37,638.077 3476.66,636.416 3473.97,633.72C3471.27,631.023 3469.61,627.314 3469.61,623.2C3469.61,619.086 3471.27,615.377 3473.97,612.679C3476.66,609.984 3480.37,608.323 3484.49,608.322C3488.6,608.323 3492.31,609.984 3495.01,612.679C3497.7,615.377 3499.36,619.086 3499.36,623.2L3502.7,623.2C3502.7,613.138 3494.55,604.984 3484.49,604.983C3474.42,604.984 3466.27,613.138 3466.27,623.2C3466.27,633.262 3474.42,641.416 3484.49,641.417C3494.55,641.416 3502.7,633.262 3502.7,623.2Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3795.2,1097.12L3791.86,1097.12C3791.86,1101.23 3790.2,1104.94 3787.5,1107.64C3784.81,1110.33 3781.1,1111.99 3776.98,1111.99C3772.87,1111.99 3769.16,1110.33 3766.46,1107.64C3763.77,1104.94 3762.11,1101.23 3762.11,1097.12C3762.11,1093 3763.77,1089.29 3766.46,1086.6C3769.16,1083.9 3772.87,1082.24 3776.98,1082.24C3781.1,1082.24 3784.81,1083.9 3787.5,1086.6C3790.2,1089.29 3791.86,1093 3791.86,1097.12L3795.2,1097.12C3795.2,1087.05 3787.04,1078.9 3776.98,1078.9C3766.92,1078.9 3758.77,1087.05 3758.77,1097.12C3758.77,1107.18 3766.92,1115.33 3776.98,1115.33C3787.05,1115.33 3795.2,1107.18 3795.2,1097.12Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3664.4,907.078C3674.97,908.258 3681.8,896.87 3676.94,886.071C3667.86,865.882 3651.55,839.687 3627.62,838.034C3602.78,836.318 3587.55,853.871 3579.72,866.98C3575.83,873.486 3567.64,875.034 3561.02,870.438C3552.67,864.639 3540.32,858.169 3524.99,856.25C3517.18,855.273 3509.72,856.833 3502.95,859.662C3489.41,865.324 3494.18,888.078 3509.28,889.763L3664.4,907.078Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3071.83,584.468C3059.46,582.965 3051.38,568.203 3056.99,557.251C3067.48,536.776 3086.38,511.425 3114.39,515.982C3143.47,520.714 3161.44,544.733 3170.7,561.719C3175.3,570.148 3184.91,574.109 3192.63,570.672C3202.37,566.335 3216.79,562.312 3234.73,564.255C3243.86,565.244 3252.61,569.022 3260.56,574.053C3276.46,584.121 3271.04,608.667 3253.36,606.52L3071.83,584.468Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2943.97,754.256C2943.97,782.766 2895.42,805.877 2835.52,805.877C2775.62,805.877 2727.06,782.765 2727.06,754.256C2727.06,725.747 2775.62,702.636 2835.52,702.636C2895.42,702.636 2943.97,725.747 2943.97,754.256Z"
          style="fill: rgb(95, 146, 67); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2899.76,754.256C2899.76,771.144 2871,784.833 2835.52,784.833C2800.03,784.833 2771.27,771.143 2771.27,754.256C2771.27,737.369 2800.03,723.679 2835.52,723.679C2871,723.679 2899.76,737.369 2899.76,754.256Z"
          style="fill: rgb(81, 129, 58); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2983.58,1428.66C2983.58,1467.13 2918.06,1498.32 2837.24,1498.31C2756.41,1498.31 2690.89,1467.13 2690.89,1428.66C2690.89,1390.19 2756.41,1359.01 2837.24,1359.01C2918.06,1359.01 2983.58,1390.2 2983.58,1428.66Z"
          style="fill: rgb(95, 146, 67); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3397.64,1651.83L3583.21,1498.31L3590.19,1141.45L3393.89,1262.75L3397.64,1651.83Z"
          style="fill: rgb(135, 168, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2828.24,1126.76L2829.1,1506.91L3397.66,1651.83L3394.01,1262.75L2828.24,1126.76Z"
          style="fill: rgb(154, 190, 133); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3057.8,1006.94L2841.89,1120.37L2828.24,1126.76L3394.01,1262.75L3590.19,1141.45L3057.8,1006.94Z"
          style="fill: rgb(154, 190, 133); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2797.98,814.871C2798.13,814.926 2816.94,823.045 2840.17,823.058C2853.3,823.058 2867.9,820.442 2881.2,812.157C2882.2,811.537 2882.5,810.227 2881.88,809.231C2881.26,808.235 2879.95,807.93 2878.96,808.55C2866.51,816.302 2852.75,818.808 2840.17,818.809C2829.05,818.81 2818.88,816.844 2811.51,814.874C2807.83,813.89 2804.85,812.906 2802.8,812.172C2801.77,811.805 2800.98,811.5 2800.44,811.289C2800.18,811.183 2799.98,811.101 2799.84,811.047L2799.7,810.986L2799.66,810.969C2798.58,810.505 2797.33,811.002 2796.87,812.08C2796.4,813.158 2796.9,814.408 2797.98,814.871Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2834.95,634.908C2834.95,634.908 2783.72,495.316 2958.98,455.462C2958.98,455.462 2976.35,584.683 2834.95,634.908Z"
          style="fill: rgb(123, 187, 87); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2835.65,630.111C2835.65,630.111 2710.01,653.443 2651.75,514.236C2651.75,514.236 2831.71,492.448 2835.65,630.111Z"
          style="fill: rgb(123, 187, 87); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2842.43,777.886C2842.45,777.726 2843.79,764.102 2843.79,738.603C2843.79,697.669 2840.33,626.13 2822.39,530.57C2821.85,527.721 2819.11,525.845 2816.26,526.38C2813.41,526.915 2811.53,529.659 2812.07,532.508C2829.88,627.374 2833.29,698.259 2833.29,738.603C2833.29,751.168 2832.96,760.771 2832.63,767.212C2832.47,770.432 2832.3,772.863 2832.18,774.478C2832.12,775.285 2832.07,775.889 2832.04,776.286C2832.02,776.484 2832.01,776.631 2832,776.725L2831.99,776.828L2831.98,776.852C2831.7,779.737 2833.81,782.308 2836.69,782.593C2839.58,782.879 2842.15,780.771 2842.43,777.886Z"
          style="fill: rgb(115, 175, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2851.03,598.353L2950.02,471.332C2951.13,469.903 2950.88,467.842 2949.45,466.728C2948.02,465.614 2945.96,465.87 2944.85,467.299L2845.86,594.319C2844.75,595.749 2845,597.81 2846.43,598.924C2847.86,600.038 2849.92,599.782 2851.03,598.353Z"
          style="fill: rgb(104, 159, 73); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2669.07,528.03L2807.84,609.728C2809.4,610.648 2811.41,610.127 2812.33,608.566C2813.25,607.004 2812.73,604.993 2811.17,604.074L2672.4,522.376C2670.83,521.456 2668.82,521.977 2667.9,523.538C2666.98,525.1 2667.5,527.111 2669.07,528.03Z"
          style="fill: rgb(104, 159, 73); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2722.27,916.392C2722.27,982.063 2954.91,982.063 2954.91,916.392L2963.06,993.499C2963.06,1057.72 2718.6,1057.72 2718.6,993.499L2722.27,916.392Z"
          style="fill: rgb(251, 199, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3312.22,1125.46C3312.22,1147.74 3270,1165.79 3217.91,1165.79C3165.82,1165.79 3123.6,1147.74 3123.6,1125.46C3123.6,1103.19 3165.82,1085.13 3217.91,1085.13C3270,1085.13 3312.22,1103.19 3312.22,1125.46Z"
          style="fill: rgb(121, 170, 92); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3352.26,998.121C3347.12,1069.18 3288.43,1113.21 3221.18,1096.47C3153.92,1079.72 3103.57,1008.55 3108.71,937.492C3113.85,866.436 3172.54,822.406 3239.79,839.148C3307.04,855.891 3357.4,927.065 3352.26,998.121Z"
          style="fill: rgb(227, 172, 80); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3322.21,1014.07C3317.07,1085.13 3258.39,1129.16 3191.13,1112.41C3123.88,1095.67 3073.53,1024.5 3078.67,953.44C3083.81,882.384 3142.5,838.354 3209.75,855.096C3277,871.839 3327.35,943.013 3322.21,1014.07Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3167.23,1000.56L3189.61,1031.79C3190.14,1032.53 3190.98,1032.95 3191.89,1032.94C3192.79,1032.93 3193.62,1032.48 3194.13,1031.74L3243.19,959.78C3244.05,958.522 3243.73,956.808 3242.47,955.951C3241.21,955.093 3239.5,955.417 3238.64,956.675L3191.8,1025.38L3171.71,997.348C3170.82,996.111 3169.1,995.827 3167.86,996.714C3166.63,997.601 3166.34,999.322 3167.23,1000.56Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2775.11,747.806L2774.3,747.398L2775.11,747.817L2775.11,747.806L2774.3,747.398L2775.11,747.817C2775.13,747.78 2777.57,743.059 2785.37,737.968C2793.17,732.881 2806.37,727.399 2828.02,726.016C2829.19,725.941 2830.08,724.932 2830,723.761C2829.93,722.59 2828.92,721.701 2827.75,721.775C2805.15,723.213 2791.09,729.058 2782.65,734.672C2774.21,740.282 2771.45,745.633 2771.31,745.902C2770.79,746.951 2771.21,748.228 2772.26,748.754C2773.31,749.28 2774.59,748.855 2775.11,747.806Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2627.43,1175.2L2551.32,1222.61L2616.57,1497.59L3223.91,1722.98L3289.64,1672.86L3290.12,1347.77L2627.43,1175.2Z"
          style="fill: rgb(78, 129, 57); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2627.43,1175.2L2551.32,1222.61L2616.57,1497.59L2848.45,1406.22L3153.22,1449.09L3290.12,1347.77L2627.43,1175.2Z"
          style="fill: rgb(98, 159, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2858.42,1136L3374.83,1264.62C3375.86,1264.88 3376.91,1264.25 3377.17,1263.22C3377.42,1262.18 3376.8,1261.14 3375.76,1260.88L2859.35,1132.26C2858.32,1132 2857.27,1132.63 2857.02,1133.67C2856.76,1134.7 2857.39,1135.74 2858.42,1136Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2551.32,1222.61L3224.39,1397.89L3223.91,1722.98L2550.84,1547.7L2551.32,1222.61Z"
          style="fill: rgb(141, 213, 99); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2754.01,1446.52L2756.04,1446.52C2756.09,1422.55 2764.18,1402.77 2777.63,1388.95C2791.08,1375.14 2809.92,1367.23 2831.74,1367.23C2839.81,1367.23 2848.28,1368.31 2857.03,1370.59C2885.04,1377.88 2910.51,1396.04 2928.94,1419.54C2947.37,1443.04 2958.73,1471.82 2958.72,1500.2L2958.72,1500.38L2960.76,1500.36L2958.72,1500.35C2958.68,1524.33 2950.59,1544.11 2937.14,1557.93C2923.69,1571.74 2904.85,1579.64 2883.02,1579.65C2874.96,1579.65 2866.49,1578.57 2857.74,1576.29C2829.73,1569 2804.26,1550.83 2785.83,1527.33C2767.4,1503.84 2756.04,1475.05 2756.04,1446.68L2756.04,1446.52L2754.01,1446.52L2751.98,1446.49L2751.98,1446.68C2751.99,1476.15 2763.72,1505.72 2782.63,1529.84C2801.55,1553.96 2827.69,1572.66 2856.72,1580.22C2865.78,1582.58 2874.59,1583.72 2883.02,1583.72C2905.83,1583.72 2925.82,1575.4 2940.05,1560.76C2954.3,1546.13 2962.76,1525.24 2962.79,1500.36L2962.79,1500.2C2962.78,1470.73 2951.05,1441.16 2932.13,1417.04C2913.21,1392.92 2887.08,1374.22 2858.05,1366.65C2848.99,1364.29 2840.17,1363.16 2831.74,1363.16C2808.94,1363.15 2788.95,1371.48 2774.71,1386.12C2760.47,1400.75 2752.01,1421.63 2751.98,1446.52L2754.01,1446.52L2751.98,1446.49L2754.01,1446.52Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3123.72,1668.13L2656.97,1546.59C2655.88,1546.3 2654.77,1546.96 2654.49,1548.04C2654.21,1549.13 2654.86,1550.24 2655.94,1550.52L3122.7,1672.07C3123.78,1672.35 3124.89,1671.7 3125.18,1670.61C3125.46,1669.53 3124.81,1668.42 3123.72,1668.13Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3124.11,1403.7L2642.21,1278.21C2641.12,1277.93 2640.01,1278.58 2639.73,1279.67C2639.45,1280.75 2640.1,1281.86 2641.18,1282.15L3123.09,1407.64C3124.18,1407.92 3125.29,1407.27 3125.57,1406.18C3125.85,1405.1 3125.2,1403.99 3124.11,1403.7Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3185.26,1623.9L3185.43,1492.78C3185.44,1491.66 3184.53,1490.75 3183.4,1490.75C3182.28,1490.75 3181.37,1491.66 3181.37,1492.78L3181.19,1623.89C3181.19,1625.01 3182.1,1625.93 3183.22,1625.93C3184.34,1625.93 3185.26,1625.02 3185.26,1623.9Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2581.88,1455.08L2582.06,1335.66C2582.06,1334.54 2581.15,1333.63 2580.03,1333.63C2578.91,1333.63 2578,1334.53 2577.99,1335.66L2577.82,1455.07C2577.82,1456.19 2578.72,1457.1 2579.85,1457.11C2580.97,1457.11 2581.88,1456.2 2581.88,1455.08Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3122.33,1406.01C3122.32,1406.07 3122.1,1408.21 3122.1,1411.81C3122.11,1420.28 3123.29,1436.87 3131.42,1453.48C3135.49,1461.78 3141.3,1470.08 3149.57,1477.3C3157.83,1484.52 3168.53,1490.64 3182.3,1494.62C3183.38,1494.93 3184.5,1494.31 3184.82,1493.23C3185.13,1492.15 3184.51,1491.02 3183.43,1490.71C3170.19,1486.88 3160.05,1481.06 3152.24,1474.24C3140.53,1464.01 3134.02,1451.49 3130.42,1439.95C3126.82,1428.41 3126.17,1417.87 3126.17,1411.81C3126.17,1410.09 3126.22,1408.73 3126.27,1407.81C3126.3,1407.35 3126.32,1407 3126.34,1406.76C3126.35,1406.65 3126.36,1406.56 3126.36,1406.5L3126.37,1406.44L3126.37,1406.43C3126.48,1405.31 3125.67,1404.32 3124.56,1404.2C3123.44,1404.09 3122.44,1404.9 3122.33,1406.01Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3183.88,1621.97C3183.75,1621.93 3175.46,1619.11 3164.83,1619.11C3160.38,1619.11 3155.52,1619.6 3150.67,1621.04C3143.41,1623.19 3136.16,1627.51 3130.72,1635.32C3125.27,1643.13 3121.65,1654.3 3121.18,1670.04C3121.15,1671.16 3122.03,1672.1 3123.15,1672.13C3124.27,1672.16 3125.21,1671.28 3125.24,1670.16C3125.54,1660.04 3127.19,1652.06 3129.7,1645.81C3131.58,1641.11 3133.95,1637.39 3136.61,1634.41C3140.61,1629.96 3145.3,1627.17 3150.19,1625.46C3155.08,1623.76 3160.17,1623.17 3164.83,1623.17C3169.78,1623.17 3174.25,1623.84 3177.47,1624.51C3179.07,1624.84 3180.36,1625.17 3181.25,1625.42C3181.69,1625.55 3182.03,1625.65 3182.25,1625.72C3182.36,1625.75 3182.45,1625.78 3182.5,1625.8L3182.56,1625.82L3182.57,1625.82C3183.64,1626.18 3184.79,1625.61 3185.15,1624.55C3185.51,1623.49 3184.94,1622.33 3183.88,1621.97Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2638.1,1280.32L2638.1,1280.33C2638.12,1280.52 2638.29,1282.48 2638.29,1285.56C2638.3,1292.61 2637.37,1305.5 2631.9,1316.36C2629.17,1321.79 2625.35,1326.71 2619.97,1330.28C2614.59,1333.84 2607.63,1336.12 2598.38,1336.13C2593.52,1336.13 2588.03,1335.49 2581.85,1334.06C2580.76,1333.81 2579.67,1334.49 2579.42,1335.58C2579.16,1336.67 2579.84,1337.77 2580.94,1338.02C2587.37,1339.51 2593.16,1340.19 2598.38,1340.19C2608.29,1340.2 2616.15,1337.7 2622.22,1333.66C2626.78,1330.64 2630.32,1326.76 2633.06,1322.53C2637.16,1316.17 2639.46,1308.99 2640.76,1302.38C2642.06,1295.77 2642.35,1289.72 2642.35,1285.56C2642.35,1282.12 2642.15,1279.99 2642.15,1279.93C2642.04,1278.82 2641.05,1278 2639.93,1278.1C2638.81,1278.21 2637.99,1279.2 2638.1,1280.32Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2581.03,1465.91L2581.06,1465.92C2581.94,1466.06 2598.81,1469.04 2615.49,1480C2623.84,1485.47 2632.13,1492.92 2638.45,1502.98C2644.76,1513.05 2649.13,1525.74 2649.57,1541.86C2649.6,1542.98 2650.53,1543.86 2651.65,1543.83C2652.78,1543.8 2653.66,1542.87 2653.63,1541.75C2653.17,1524.68 2648.43,1511.05 2641.57,1500.32C2631.27,1484.2 2616.28,1474.63 2603.93,1469.06C2591.57,1463.49 2581.79,1461.92 2581.67,1461.9C2580.56,1461.72 2579.52,1462.47 2579.34,1463.58C2579.16,1464.69 2579.92,1465.73 2581.03,1465.91Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2850.32,1516.04C2842.52,1513.11 2834.78,1506.99 2829.74,1500.75L2834.53,1495.02C2839.69,1501.05 2845.84,1506.48 2853.3,1508.57C2862.33,1511.09 2866.95,1507.3 2867.1,1499.66C2867.51,1479.69 2833.34,1474.48 2833.83,1450.45C2834.05,1439.48 2841.28,1433.57 2851.94,1434.94L2852.19,1422.49L2859.52,1424.54L2859.28,1436.74C2867.08,1439.79 2872.13,1445.04 2876.43,1450.95L2870.94,1455.49C2866.51,1449.79 2862.77,1446.14 2856.18,1444.3C2848.73,1442.22 2843.87,1445.57 2843.73,1452.72C2843.37,1470.59 2877.55,1475.18 2877.02,1501.68C2876.78,1513.39 2869.29,1519.48 2857.65,1517.84L2857.4,1530.29L2850.07,1528.24L2850.32,1516.04Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3234.75,1390.88L3278.36,1360.59C3279.23,1359.99 3279.45,1358.79 3278.84,1357.91C3278.23,1357.04 3277.03,1356.82 3276.16,1357.43L3232.55,1387.72C3231.67,1388.32 3231.46,1389.52 3232.06,1390.4C3232.67,1391.27 3233.87,1391.49 3234.75,1390.88Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3245.53,1413.03L3245.53,1684.08C3245.53,1685.14 3246.39,1686 3247.46,1686C3248.52,1686 3249.38,1685.14 3249.38,1684.08L3249.38,1413.03C3249.38,1411.97 3248.52,1411.11 3247.46,1411.11C3246.39,1411.11 3245.53,1411.97 3245.53,1413.03Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3268.06,1389.3L3268.06,1660.34C3268.06,1661.41 3268.93,1662.27 3269.99,1662.27C3271.05,1662.27 3271.92,1661.41 3271.92,1660.34L3271.92,1389.3C3271.92,1388.24 3271.05,1387.37 3269.99,1387.37C3268.93,1387.37 3268.06,1388.24 3268.06,1389.3Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M2624.42,1200.31L3221.3,1356.41C3222.33,1356.68 3223.38,1356.06 3223.65,1355.03C3223.92,1354 3223.3,1352.95 3222.28,1352.68L2625.4,1196.58C2624.37,1196.31 2623.31,1196.92 2623.04,1197.95C2622.77,1198.98 2623.39,1200.04 2624.42,1200.31Z"
          style="fill: rgb(0, 81, 62); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3093.08,803.761L3111.21,828.832C3111.91,829.791 3113.25,830.007 3114.2,829.313C3115.16,828.619 3115.38,827.279 3114.69,826.32L3096.56,801.249C3095.86,800.29 3094.52,800.075 3093.56,800.768C3092.6,801.462 3092.39,802.802 3093.08,803.761Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3383.59,861.815L3358.39,879.773C3357.43,880.46 3357.2,881.799 3357.89,882.763C3358.58,883.727 3359.92,883.951 3360.88,883.264L3386.08,865.306C3387.04,864.619 3387.26,863.281 3386.58,862.317C3385.89,861.353 3384.55,861.128 3383.59,861.815Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3242.57,760.563L3242.67,791.502C3242.68,792.686 3243.64,793.643 3244.82,793.639C3246.01,793.635 3246.96,792.672 3246.96,791.488L3246.85,760.548C3246.85,759.364 3245.89,758.408 3244.7,758.412C3243.52,758.416 3242.56,759.379 3242.57,760.563Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3396.07,1144.97C3394.43,1149.31 3395.85,1153.91 3399.22,1155.21L3413,1156.81C3416.38,1158.11 3414.13,1156.86 3415.78,1152.53C3417.42,1148.19 3420.85,1148.88 3414.94,1144.94L3405.29,1141.5C3404.04,1141.06 3402.72,1140.78 3401.38,1140.78C3398.9,1140.77 3397.36,1141.57 3396.07,1144.97Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3552.98,1155.76L3490.85,1172.2C3489.04,1172.75 3487.09,1172.66 3485.33,1171.94L3425.23,1147.16C3421.2,1145.5 3416.58,1147.28 3414.72,1151.22L3414.09,1152.57C3412.59,1155.74 3413.25,1159.49 3415.78,1161.92C3428.26,1173.95 3465.3,1208.68 3480.28,1211.99C3482.48,1212.48 3521.33,1205.44 3535.16,1202.91C3538.15,1202.36 3540.56,1200.21 3541.47,1197.31L3550.9,1167.12C3552.86,1160.84 3559.27,1153.83 3552.98,1155.76Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3703.18,1636.34C3671.91,1620.46 3618.23,1619.7 3583.27,1634.64C3577.31,1637.18 3572.29,1640.03 3568.2,1643.07C3546.11,1636.49 3516.09,1637.46 3495.39,1646.31C3470.82,1656.81 3468.72,1674.36 3490.69,1685.52C3511.8,1696.24 3547.42,1697.12 3571.97,1687.86C3573.42,1688.74 3574.94,1689.6 3576.58,1690.43C3607.84,1706.31 3661.53,1707.08 3696.49,1692.14C3731.45,1677.2 3734.44,1652.22 3703.18,1636.34Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3519.13,1660.01L3541.01,1637.41C3545.23,1633.05 3551,1630.52 3556.96,1630.56C3559.1,1630.57 3561.26,1630.76 3563.26,1631.14C3565.95,1631.65 3567.31,1633.36 3567.4,1635.9L3567.89,1640.63C3568.45,1648.55 3569.4,1651.18 3564.07,1653.64L3532.47,1668.25C3525.92,1672.76 3507.98,1670 3519.13,1660.01Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3564.15,1632.42C3563.35,1637.71 3558.71,1642.11 3553.49,1641.68C3550.91,1641.46 3549.09,1639.99 3548.6,1635.15C3547.99,1580.65 3541.53,1535.36 3540.93,1484.42C3540.25,1427.21 3542.22,1444.04 3541.32,1356.35L3593.36,1344.21C3583.79,1407.7 3580.51,1487.52 3580.51,1487.52C3595.79,1545.74 3574.53,1563.75 3564.15,1632.42Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3598.16,1324.32L3583.94,1458.69C3582.13,1473.13 3582.41,1484.59 3585.36,1498.81C3589.73,1519.87 3588.14,1562.39 3571.15,1624.37C3562.95,1631.1 3554.26,1631.14 3545.14,1625.48C3545.14,1625.48 3534.48,1488.6 3534.05,1462.18C3533.63,1435.76 3536.99,1293.3 3536.99,1293.3L3598.16,1324.32Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3654.45,1651.43L3655.36,1668.65C3655.57,1670.45 3657.74,1679.92 3658.61,1681.53C3661.14,1686.18 3665.11,1687.93 3668.9,1688.01C3673.92,1688.12 3683.75,1679.41 3679.85,1669.26C3676.45,1660.41 3669.77,1646.35 3669.77,1646.35C3662.79,1649.69 3659.55,1651.21 3654.45,1651.43Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3669.66,1651.26C3670.82,1657.99 3659.93,1661.33 3655.18,1654.64C3648.39,1600.74 3637.38,1552.06 3631,1501.68C3623.84,1445.11 3618.82,1448.66 3607.98,1361.93L3665.32,1363.05C3662.98,1426.73 3668.76,1506.03 3668.76,1506.03C3691.45,1564.54 3670.9,1575.59 3669.66,1651.26Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3669.54,1309.89C3669.54,1309.89 3665.82,1424.61 3669.76,1449.7C3672.82,1469.21 3680.34,1501.13 3683.54,1514.39C3684.59,1518.72 3685.21,1523.1 3685.4,1527.55C3686.24,1547.07 3685.41,1600.79 3678.99,1633.85C3673.06,1643.03 3659.5,1641.51 3649.19,1636.5L3623.49,1505.8C3623.11,1504.27 3604.2,1402 3604.2,1402L3571.79,1396.49L3587.7,1310.06C3587.7,1310.06 3673.63,1317.02 3669.54,1309.89Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3597.07,1148.27C3610.04,1145.04 3615.64,1148.25 3617,1149.12L3654.39,1162.52C3686.99,1173.39 3683.21,1205.12 3683.94,1224.78C3683.94,1224.78 3672.47,1247.26 3671.41,1265.83C3670.5,1281.88 3660.61,1318.17 3663.19,1323.31C3665.12,1327.15 3664.94,1351.07 3645.12,1357.71C3627.61,1363.57 3599.46,1376.21 3584.52,1376.22C3505.92,1376.27 3527.84,1338.96 3529.59,1312.41C3530.69,1295.7 3535.02,1246.35 3535.14,1233.12C3535.2,1226.48 3534.79,1210.25 3536.83,1194.1C3538.86,1178.07 3552.98,1155.76 3552.98,1155.76C3565.82,1152.09 3583.36,1151.68 3597.07,1148.27Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3677.62,1337.51C3676.47,1328.49 3676.69,1281.92 3677.61,1265.87C3678.21,1255.36 3679.77,1224.75 3681.32,1209.18C3673.46,1224.12 3667.89,1229.87 3667.05,1233.51C3660.42,1262.17 3658.8,1277.79 3644.83,1294.6C3618.03,1326.86 3610.95,1343.3 3584.52,1376.22C3639.07,1378.65 3655.95,1374.69 3667.46,1367.78C3681.76,1359.19 3678.22,1342.14 3677.62,1337.51Z"
          style="fill: rgb(240, 191, 112); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3535.46,1193.97L3534.44,1204.08C3534.37,1204.83 3534.92,1205.51 3535.67,1205.59C3536.43,1205.66 3537.11,1205.11 3537.18,1204.35L3538.2,1194.24C3538.28,1193.49 3537.73,1192.81 3536.97,1192.73C3536.21,1192.66 3535.54,1193.21 3535.46,1193.97Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3581.59,1334.4C3581.3,1338.24 3577.93,1341.15 3574.1,1340.87L3564.72,1340.18C3560.88,1339.9 3557.97,1336.52 3558.25,1332.68C3558.53,1328.85 3561.91,1325.94 3565.74,1326.22L3575.12,1326.91C3578.96,1327.19 3581.87,1330.56 3581.59,1334.4Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3601.35,1316.31L3574.81,1330.04L3577.47,1340.07L3617.53,1330.36L3601.35,1316.31Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3679.59,1185.62C3685.39,1202.07 3688.94,1243.51 3689.16,1249.08C3690.78,1290.4 3678.64,1310.3 3667.91,1320.2C3664.33,1323.5 3589.05,1342.11 3589.05,1342.11L3582.41,1322.12L3642.98,1286.88L3635.24,1200.97L3679.59,1185.62Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3646.27,1305.72L3646.34,1303.68L3643.25,1305.6L3642.66,1304.49L3568.21,1289.05C3567.32,1288.87 3565.7,1289.4 3564.61,1290.25L3531.06,1316.3L3531.08,1315.85L3524.92,1319.74L3524.82,1321.91L3524.86,1321.9C3524.93,1322.09 3525.11,1322.23 3525.42,1322.29L3602.89,1338.36C3603.78,1338.54 3605.4,1338.01 3606.49,1337.16L3645.34,1306.99C3645.94,1306.53 3646.25,1306.07 3646.27,1305.72Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3602.94,1336.21L3525.47,1320.15C3524.57,1319.96 3524.73,1319.12 3525.81,1318.28L3564.67,1288.1C3565.75,1287.26 3567.37,1286.72 3568.27,1286.91L3645.74,1302.97C3646.64,1303.16 3646.48,1304 3645.4,1304.84L3606.54,1335.02C3605.46,1335.86 3603.84,1336.4 3602.94,1336.21Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3591.86,1281.76C3591.38,1280.21 3589.56,1278.67 3587.8,1278.33L3506.01,1262.28C3505.28,1262.14 3504.68,1262.22 3504.26,1262.48L3504.25,1262.48L3504.24,1262.49C3504.09,1262.58 3503.96,1262.69 3503.86,1262.83L3501.49,1265.59L3503.83,1264.86L3515.07,1300.8C3515.55,1302.35 3517.37,1303.89 3519.13,1304.24L3596.47,1319.41L3599.07,1320.94L3604.95,1336.17C3606.48,1336.06 3607.94,1333.93 3607.94,1333.93L3591.86,1281.76Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3503.73,1265.13L3585.52,1281.18C3587.28,1281.52 3589.1,1283.06 3589.58,1284.61L3600.95,1320.95L3600.96,1321.03L3605.51,1335.57C3606,1337.12 3604.96,1338.1 3603.2,1337.76L3521.42,1321.71C3519.66,1321.36 3517.84,1319.82 3517.35,1318.27L3505.99,1281.93L3505.97,1281.86L3501.42,1267.31C3500.94,1265.76 3501.97,1264.78 3503.73,1265.13Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3555.94,1305.17C3556.14,1308.98 3553.37,1311.33 3549.75,1310.44C3546.14,1309.54 3543.04,1305.73 3542.84,1301.92C3542.64,1298.11 3545.41,1295.76 3549.03,1296.66C3552.65,1297.55 3555.74,1301.37 3555.94,1305.17Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3630.43,1294.26L3629.23,1294.24L3630.34,1294.71C3630.36,1294.64 3630.42,1294.53 3630.43,1294.26L3629.23,1294.24L3630.34,1294.71L3629.7,1294.44L3630.29,1294.8L3630.34,1294.71L3629.7,1294.44L3630.29,1294.8C3630.32,1294.75 3630.48,1294.56 3630.7,1294.31C3631.57,1293.37 3633.44,1291.58 3635.72,1289.49C3639.13,1286.35 3643.49,1282.48 3646.99,1279.39C3648.74,1277.85 3650.28,1276.5 3651.38,1275.54C3652.47,1274.58 3653.13,1274.01 3653.13,1274.01C3653.46,1273.73 3653.63,1273.3 3653.6,1272.87L3649.64,1216.54C3649.58,1215.78 3648.92,1215.21 3648.16,1215.26C3647.4,1215.31 3646.83,1215.97 3646.89,1216.73L3650.85,1273.07L3652.23,1272.97L3651.32,1271.93C3651.32,1271.93 3645.53,1276.97 3639.73,1282.15C3636.83,1284.74 3633.93,1287.37 3631.74,1289.43C3630.64,1290.46 3629.72,1291.35 3629.06,1292.04C3628.73,1292.39 3628.46,1292.68 3628.25,1292.94C3628.14,1293.08 3628.04,1293.2 3627.95,1293.35C3627.9,1293.43 3627.85,1293.51 3627.8,1293.63C3627.75,1293.76 3627.68,1293.91 3627.67,1294.21C3627.66,1294.97 3628.27,1295.6 3629.03,1295.62C3629.79,1295.63 3630.42,1295.02 3630.43,1294.26Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3604.29,1160.32L3602.84,1159.01C3598.91,1160.07 3595.47,1159.76 3594.86,1156.34L3591.46,1137.07C3590.85,1133.65 3593.58,1129.99 3597.52,1128.93L3598.68,1128.62C3602.62,1127.56 3613.44,1127.54 3614.04,1130.96L3615.85,1151.38C3616.45,1154.8 3608.23,1159.26 3604.29,1160.32Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3593.3,1147.39C3593.3,1147.39 3601.71,1146.62 3606.99,1137.96C3606.99,1137.96 3601.77,1150.68 3594.14,1152.6L3593.3,1147.39Z"
          style="fill: rgb(215, 64, 40); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3610.34,1106.77C3607.22,1101.63 3602.52,1100.79 3598.19,1103.83C3593.91,1102.08 3588.77,1104.82 3585.02,1111.66C3579.8,1121.18 3577.36,1136.2 3581.9,1143.69C3583.88,1146.97 3588.55,1148.84 3591.51,1148.86C3594.27,1148.88 3601.46,1146.51 3604.11,1145.46C3613.62,1141.7 3611.24,1143.69 3614.72,1137.35C3619.95,1127.84 3614.88,1114.27 3610.34,1106.77Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3609.59,1111.15C3613.24,1120.19 3611.3,1135.63 3611.87,1138.4C3611.87,1138.4 3626.45,1128.05 3625.55,1119.95C3622.56,1092.95 3606.7,1107.6 3606.7,1107.6L3609.59,1111.15Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3609.73,1097.54C3615.38,1098.83 3618.83,1103.03 3620.67,1110.42C3620.91,1111.36 3622.94,1115.61 3623.02,1116.41L3619.39,1115.4C3620.05,1121.79 3618.33,1113.94 3611.89,1115.18C3601.9,1111.63 3593.33,1104.01 3581.81,1119.39C3580.79,1120.75 3577.05,1118.52 3578.41,1115.38C3583.68,1103.26 3595.17,1098.84 3598.86,1097.86L3600.72,1097.56C3604.2,1096.98 3607.19,1096.97 3609.73,1097.54Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3588.6,1141.73C3586.39,1141.29 3584.92,1138.65 3585.33,1135.83L3593.37,1137.43C3592.96,1140.25 3590.82,1142.17 3588.6,1141.73Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3619.48,1132.74C3619.74,1135.75 3617.5,1138.48 3614.48,1138.83C3611.47,1139.18 3608.81,1137.02 3608.55,1134C3608.29,1130.99 3610.53,1128.26 3613.55,1127.91C3616.56,1127.56 3619.22,1129.72 3619.48,1132.74Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3593.72,1148.59C3591.56,1149.12 3589.54,1148.83 3587.6,1148.04C3587.6,1148.04 3584.81,1160.69 3589.37,1164.55L3597.88,1159.12C3597.88,1159.12 3593.44,1154.31 3593.72,1148.59Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3598.26,1158.9L3603.78,1167.51C3603.78,1167.51 3624.02,1161.57 3624.88,1151.59L3616.06,1147.59C3616.06,1147.59 3606.05,1159.73 3598.26,1158.9Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3612.02,1112.49L3617.65,1107.6C3617.94,1107.35 3617.97,1106.92 3617.72,1106.63C3617.47,1106.34 3617.03,1106.31 3616.75,1106.56L3611.12,1111.45C3610.83,1111.7 3610.8,1112.13 3611.05,1112.42C3611.3,1112.71 3611.73,1112.74 3612.02,1112.49Z"
          style="fill: rgb(78, 36, 17); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.16055,0,0,1.16055,-2635.71,-261.61)">
        <path
          d="M3594.68,1293.26L3605.66,1328.86C3605.77,1329.22 3606.16,1329.43 3606.52,1329.31C3606.88,1329.2 3607.09,1328.82 3606.98,1328.45L3596,1292.85C3595.89,1292.49 3595.5,1292.29 3595.14,1292.4C3594.78,1292.51 3594.57,1292.9 3594.68,1293.26Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default { name: "PlanetImg" };
</script>
