<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 2000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <rect
      id="Artboard1"
      x="0"
      y="0"
      width="2000"
      height="2000"
      style="fill: none"
    />
    <g id="Artboard11" serif:id="Artboard1">
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1929.64,1569.88C1929.64,1607.96 1864.74,1638.84 1784.69,1638.84C1704.64,1638.84 1639.75,1607.96 1639.75,1569.88C1639.75,1531.79 1704.64,1500.91 1784.69,1500.91C1864.74,1500.91 1929.64,1531.79 1929.64,1569.88Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M2012.04,1064.27C2152.29,681.225 1742.58,244.737 1207.61,350.962C949.888,402.134 460.638,754.634 536.735,1245.4C571.575,1470.08 909.396,1673.95 1186.73,1327.06C1389.45,1073.5 1886.4,1407.39 2012.04,1064.27Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1620.1,1613.3L1787.03,1453.71C1810.19,1430.93 1799.34,1390.77 1767.7,1382.12L1309.81,1256.87L1620.1,1613.3Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1902.2,1061.62C1902.2,1124.38 1851.33,1175.25 1788.58,1175.25C1725.83,1175.25 1674.96,1124.38 1674.96,1061.62C1674.96,998.873 1725.83,948.004 1788.58,948.004C1851.34,948.004 1902.21,998.873 1902.2,1061.62Z"
          style="fill: rgb(145, 200, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1960.14,1252.75C1960.14,1349.65 1881.59,1428.2 1784.69,1428.2C1687.8,1428.2 1609.25,1349.65 1609.25,1252.75C1609.25,1155.86 1687.8,1077.31 1784.69,1077.31C1881.59,1077.31 1960.14,1155.86 1960.14,1252.75Z"
          style="fill: rgb(145, 200, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1888.85,1112.54C1896.55,1097.22 1900.91,1079.94 1900.91,1061.62C1900.91,1005.58 1860.32,959.046 1806.94,949.732C1817.98,967.246 1824.38,987.968 1824.38,1010.2C1824.38,1028.51 1820.02,1045.8 1812.33,1061.12C1854.83,1093.13 1882.32,1144.02 1882.32,1201.33C1882.32,1298.22 1803.77,1376.77 1706.87,1376.77C1687.25,1376.77 1668.41,1373.5 1650.8,1367.56C1682.97,1404.68 1730.42,1428.2 1783.4,1428.2C1880.29,1428.2 1958.84,1349.65 1958.84,1252.75C1958.84,1195.44 1931.35,1144.56 1888.85,1112.54Z"
          style="fill: rgb(128, 178, 107); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1786.76,1562.53L1786.76,1088.93C1786.76,1087.79 1785.84,1086.86 1784.69,1086.86C1783.55,1086.86 1782.63,1087.79 1782.63,1088.93L1782.63,1562.53C1782.63,1563.67 1783.55,1564.6 1784.69,1564.6C1785.84,1564.6 1786.76,1563.67 1786.76,1562.53Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1849.78,1245.55L1783.02,1338.25C1782.35,1339.17 1782.56,1340.47 1783.49,1341.13C1784.41,1341.8 1785.7,1341.59 1786.37,1340.66L1853.14,1247.96C1853.8,1247.04 1853.59,1245.74 1852.67,1245.08C1851.74,1244.41 1850.45,1244.62 1849.78,1245.55Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1724.38,1148.66L1779.44,1227.59C1780.09,1228.53 1781.38,1228.76 1782.32,1228.1C1783.25,1227.45 1783.48,1226.16 1782.83,1225.23L1727.77,1146.3C1727.12,1145.36 1725.83,1145.13 1724.89,1145.78C1723.96,1146.44 1723.73,1147.72 1724.38,1148.66Z"
          style="fill: rgb(44, 94, 46); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1301.51,1613.57C1301.51,1665.63 1194.19,1700.45 1061.79,1691.33C929.403,1682.21 822.078,1632.62 822.078,1580.56C822.078,1528.5 929.403,1493.68 1061.79,1502.8C1194.19,1511.92 1301.51,1561.51 1301.51,1613.57Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1570.61,1626.4L1102.94,1522.3C1087.66,1518.63 1077.74,1506.11 1077.89,1490.66L1084.49,786.002C1084.74,759.799 1112.71,737.703 1139.03,742.926L1606.81,835.744C1622.86,838.93 1633.47,851.718 1633.32,867.702L1626.53,1591.84C1626.28,1618.67 1597.15,1632.77 1570.61,1626.4Z"
          style="fill: rgb(58, 94, 41); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1558.83,1633.51L1091.16,1529.41C1075.87,1525.74 1065.96,1513.22 1066.11,1497.78L1072.71,793.117C1072.96,766.913 1100.93,744.818 1127.25,750.04L1595.02,842.859C1611.08,846.044 1621.69,858.832 1621.54,874.816L1614.75,1598.95C1614.5,1625.78 1585.37,1639.88 1558.83,1633.51Z"
          style="fill: rgb(76, 121, 54); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1118.9,810.802L1113.17,1477.53L1582.36,1582.25L1587.92,911.226L1118.9,810.802Z"
          style="fill: rgb(212, 220, 205); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1559.54,1601.34L1090.34,1496.61L1096.08,829.885L1565.09,930.309L1559.54,1601.34Z"
          style="fill: rgb(250, 251, 255); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1342.6,823.266L1337.85,822.253C1329.24,820.413 1322.44,812.166 1322.74,803.926C1323.04,795.686 1330.34,790.45 1338.95,792.29L1343.7,793.303C1352.31,795.143 1359.11,803.39 1358.81,811.63C1358.5,819.87 1351.21,825.106 1342.6,823.266ZM1429.95,853.822L1403.14,848.095L1403.58,836.064C1404.81,802.731 1377.3,769.368 1342.46,761.926C1307.62,754.483 1278.11,775.667 1276.88,809L1276.44,821.031L1249.63,815.304C1225.09,810.063 1204.31,824.979 1203.45,848.45C1202.58,871.921 1221.95,895.412 1246.49,900.652L1426.81,939.17C1451.34,944.411 1472.12,929.495 1472.99,906.024C1473.85,882.553 1454.49,859.062 1429.95,853.822Z"
          style="fill: rgb(189, 208, 178); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1473.26,1455.98L1422.53,1445.68C1421.8,1445.54 1421.09,1446.01 1420.94,1446.74C1420.79,1447.47 1421.26,1448.18 1421.99,1448.33L1472.72,1458.62C1473.45,1458.77 1474.16,1458.3 1474.31,1457.57C1474.46,1456.84 1473.99,1456.12 1473.26,1455.98Z"
          style="fill: rgb(64, 64, 64); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1473.28,1486.87L1382.36,1468.42C1381.63,1468.28 1380.91,1468.75 1380.77,1469.48C1380.62,1470.21 1381.09,1470.92 1381.82,1471.07L1472.75,1489.52C1473.48,1489.67 1474.19,1489.19 1474.34,1488.46C1474.48,1487.73 1474.01,1487.02 1473.28,1486.87Z"
          style="fill: rgb(64, 64, 64); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1477.94,1298.68C1430.26,1384.38 1323.66,1405.2 1239.85,1345.19C1156.04,1285.18 1126.74,1167.05 1174.42,1081.35C1222.1,995.652 1328.69,974.829 1412.51,1034.84C1496.32,1094.86 1525.61,1212.98 1477.94,1298.68Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1381.32,1298.81C1385.62,1229.4 1410.08,1143.27 1323.04,1123.08C1281.11,1113.36 1260.42,1126.46 1250.22,1141.99C1249.28,1143.42 1248.43,1144.89 1247.66,1146.4C1238.41,1164.58 1243.76,1188.75 1258.63,1205.36L1278.08,1227.08C1298.67,1250.08 1308.61,1278.95 1304.68,1305.79C1304.17,1309.25 1303.62,1312.71 1303.08,1316.16C1296.96,1355.56 1289.15,1394.29 1326.15,1402.49C1372.37,1412.74 1379.71,1324.87 1381.32,1298.81Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1241.72,1072.8C1250.78,1094.09 1248.47,1114.99 1236.57,1119.49C1224.66,1123.99 1207.66,1110.38 1198.6,1089.09C1189.54,1067.8 1191.84,1046.9 1203.75,1042.4C1215.66,1037.9 1232.66,1051.51 1241.72,1072.8Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1294.69,1074.84C1297.84,1088.28 1294.16,1100.1 1286.47,1101.25C1278.78,1102.41 1269.99,1092.45 1266.84,1079.01C1263.69,1065.58 1267.37,1053.75 1275.06,1052.6C1282.75,1051.45 1291.54,1061.4 1294.69,1074.84Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1337.9,1082.66C1337.7,1093.91 1332.16,1102.31 1325.53,1101.41C1318.89,1100.51 1313.67,1090.66 1313.87,1079.41C1314.06,1068.15 1319.6,1059.76 1326.24,1060.65C1332.87,1061.55 1338.09,1071.4 1337.9,1082.66Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1380.44,1103.45C1378.27,1112.63 1372,1118.41 1366.45,1116.36C1360.9,1114.3 1358.16,1105.19 1360.34,1096.01C1362.51,1086.82 1368.78,1081.04 1374.33,1083.1C1379.88,1085.16 1382.62,1094.27 1380.44,1103.45Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1405.2,1134.48C1401.87,1141.19 1395.78,1144.47 1391.61,1141.82C1387.44,1139.17 1386.76,1131.59 1390.1,1124.88C1393.43,1118.18 1399.52,1114.89 1403.69,1117.54C1407.87,1120.19 1408.54,1127.78 1405.2,1134.48Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1744.69,696.601L1741.35,696.601C1741.35,700.715 1739.69,704.424 1736.99,707.122C1734.3,709.817 1730.59,711.479 1726.47,711.48C1722.36,711.479 1718.65,709.817 1715.95,707.122C1713.26,704.424 1711.59,700.715 1711.59,696.601C1711.59,692.488 1713.26,688.779 1715.95,686.081C1718.65,683.386 1722.36,681.724 1726.47,681.723C1730.59,681.724 1734.3,683.386 1736.99,686.081C1739.69,688.779 1741.35,692.488 1741.35,696.601L1744.69,696.601C1744.69,686.54 1736.53,678.385 1726.47,678.384C1716.41,678.385 1708.26,686.54 1708.26,696.601C1708.26,706.663 1716.41,714.818 1726.47,714.819C1736.53,714.818 1744.69,706.663 1744.69,696.601Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M760.786,1208.61L757.447,1208.61C757.446,1212.73 755.784,1216.44 753.089,1219.13C750.391,1221.83 746.682,1223.49 742.568,1223.49C738.455,1223.49 734.746,1221.83 732.048,1219.13C729.353,1216.44 727.691,1212.73 727.69,1208.61C727.691,1204.5 729.353,1200.79 732.048,1198.09C734.746,1195.4 738.455,1193.74 742.568,1193.74C746.682,1193.74 750.391,1195.4 753.089,1198.09C755.784,1200.79 757.446,1204.5 757.447,1208.61L760.786,1208.61C760.784,1198.55 752.63,1190.4 742.568,1190.4C732.507,1190.4 724.352,1198.55 724.351,1208.61C724.352,1218.68 732.507,1226.83 742.568,1226.83C752.63,1226.83 760.784,1218.68 760.786,1208.61Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1043.32,609.2L1039.98,609.2C1039.98,613.313 1038.31,617.023 1035.62,619.72C1032.92,622.415 1029.21,624.077 1025.1,624.078C1020.98,624.077 1017.28,622.415 1014.58,619.72C1011.88,617.023 1010.22,613.314 1010.22,609.2C1010.22,605.086 1011.88,601.377 1014.58,598.679C1017.28,595.984 1020.98,594.322 1025.1,594.322C1029.21,594.322 1032.92,595.984 1035.62,598.679C1038.31,601.377 1039.98,605.086 1039.98,609.2L1043.32,609.2C1043.31,599.138 1035.16,590.984 1025.1,590.983C1015.04,590.984 1006.88,599.138 1006.88,609.2C1006.88,619.261 1015.04,627.416 1025.1,627.417C1035.16,627.416 1043.31,619.261 1043.32,609.2Z"
          style="fill: rgb(56, 89, 37); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1587.35,611.779C1598.61,613.036 1605.88,600.907 1600.71,589.405C1591.04,567.903 1573.67,540.003 1548.18,538.242C1521.72,536.415 1505.5,555.11 1497.16,569.072C1493.02,576.001 1484.3,577.65 1477.25,572.755C1468.35,566.578 1455.19,559.688 1438.87,557.643C1430.56,556.603 1422.6,558.264 1415.4,561.278C1400.97,567.307 1406.05,591.542 1422.14,593.337L1587.35,611.779Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M775.842,1031.52C764.891,1030.19 757.736,1017.12 762.704,1007.43C771.991,989.299 788.73,966.855 813.529,970.89C839.275,975.079 855.179,996.344 863.382,1011.38C867.453,1018.85 875.958,1022.35 882.792,1019.31C891.416,1015.47 904.182,1011.91 920.064,1013.63C928.147,1014.5 935.899,1017.85 942.935,1022.3C957.014,1031.22 952.213,1052.95 936.567,1051.05L775.842,1031.52Z"
          style="fill: white"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1149.26,1303.62L1131.29,1300.77L1122.07,1295.11L1153.54,1283.22L1149.26,1303.62Z"
          style="fill: rgb(222, 168, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1007.87,1599.56L976.173,1611.55L964.735,1602.04L996.391,1582.58L1007.87,1599.56Z"
          style="fill: rgb(222, 168, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M981.711,1292.59C961.671,1289.41 945.366,1304.67 945.366,1326.61C945.366,1348.55 961.671,1368.99 981.711,1372.18C1001.75,1375.36 1018.05,1360.1 1018.05,1338.15C1018.05,1316.21 1001.75,1295.77 981.711,1292.59ZM981.711,1411.32C941.958,1405.01 909.615,1364.46 909.615,1320.93C909.615,1277.41 941.958,1247.13 981.711,1253.44C1021.46,1259.76 1053.8,1300.31 1053.8,1343.83C1053.8,1387.36 1021.46,1417.64 981.711,1411.32Z"
          style="fill: rgb(222, 168, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M987.877,1594.78C977.49,1586.22 974.156,1570.73 980.469,1560.36L1144.51,1290.84C1150.83,1280.47 1164.49,1278.99 1174.88,1287.55C1185.26,1296.11 1188.6,1311.6 1182.28,1321.97L1018.24,1591.49C1011.93,1601.86 998.263,1603.34 987.877,1594.78Z"
          style="fill: rgb(222, 168, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M953.006,1305.53C932.966,1302.35 916.665,1317.61 916.665,1339.55C916.665,1361.49 932.966,1381.93 953.006,1385.12C973.046,1388.3 989.347,1373.04 989.347,1351.1C989.347,1329.15 973.046,1308.71 953.006,1305.53ZM953.006,1424.26C913.253,1417.95 880.914,1377.4 880.914,1333.88C880.914,1290.35 913.253,1260.07 953.006,1266.39C992.759,1272.7 1025.1,1313.25 1025.1,1356.77C1025.1,1400.3 992.759,1430.58 953.006,1424.26Z"
          style="fill: rgb(251, 199, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1183.3,1510.08C1163.26,1506.89 1146.96,1522.16 1146.96,1544.1C1146.96,1566.04 1163.26,1586.48 1183.3,1589.66C1203.34,1592.84 1219.64,1577.58 1219.64,1555.64C1219.64,1533.7 1203.34,1513.26 1183.3,1510.08ZM1183.3,1628.81C1143.55,1622.49 1111.21,1581.95 1111.21,1538.42C1111.21,1494.89 1143.55,1464.62 1183.3,1470.93C1223.06,1477.24 1255.39,1517.79 1255.39,1561.32C1255.39,1604.84 1223.06,1635.12 1183.3,1628.81Z"
          style="fill: rgb(222, 168, 81); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1154.6,1523.02C1134.56,1519.83 1118.26,1535.1 1118.26,1557.04C1118.26,1578.98 1134.56,1599.42 1154.6,1602.6C1174.64,1605.78 1190.94,1590.52 1190.94,1568.58C1190.94,1546.64 1174.64,1526.2 1154.6,1523.02ZM1154.6,1641.75C1114.84,1635.44 1082.5,1594.89 1082.5,1551.36C1082.5,1507.83 1114.84,1477.56 1154.6,1483.87C1194.35,1490.18 1226.69,1530.73 1226.69,1574.26C1226.69,1617.78 1194.35,1648.06 1154.6,1641.75Z"
          style="fill: rgb(251, 199, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M955.188,1607.09C944.802,1598.53 941.468,1583.04 947.78,1572.67L1111.82,1303.15C1118.14,1292.78 1131.8,1291.3 1142.19,1299.86C1152.57,1308.42 1155.91,1323.91 1149.6,1334.28L985.55,1603.8C979.238,1614.17 965.575,1615.65 955.188,1607.09Z"
          style="fill: rgb(251, 199, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1632.34,1226.42C1632.34,1226.42 1641.33,1229.77 1641.94,1223.95C1642.56,1218.12 1647.7,1225.52 1650.58,1219.66C1653.45,1213.79 1662.74,1217.22 1659.07,1208.44C1655.41,1199.65 1654.99,1196.54 1658.47,1192.52C1661.95,1188.51 1653.69,1181.21 1655.48,1176.32C1657.26,1171.44 1655.63,1169.37 1652.71,1163.97C1649.79,1158.57 1648.54,1157.17 1644.28,1154.71C1640.01,1152.26 1630.59,1151.84 1629.41,1150.83C1620.36,1143.09 1617.22,1148.69 1612.26,1148.73C1607.31,1148.77 1608.8,1146.61 1601.37,1148.2C1593.93,1149.8 1594.68,1159.96 1590.34,1159.74C1586,1159.53 1581.17,1165.18 1582.75,1168.68C1584.33,1172.17 1579.98,1174.41 1581.37,1179.89C1582.59,1184.76 1577.14,1187.91 1580.8,1193.09C1584.46,1198.26 1586.16,1200.35 1583.03,1203.99C1576.23,1211.89 1581.34,1211.23 1584.41,1216.38C1587.48,1221.54 1584.65,1225.5 1587.82,1225.11C1591,1224.72 1597.48,1222.4 1600.02,1223.34C1602.56,1224.29 1603.97,1226.84 1609.21,1227.15C1614.46,1227.46 1627.15,1223.95 1632.34,1226.42Z"
          style="fill: rgb(109, 52, 29)"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1702.38,1681.6C1671.13,1664.63 1616.73,1662.36 1580.88,1676.53C1574.77,1678.95 1569.61,1681.69 1565.37,1684.66C1543.17,1677.37 1512.71,1677.52 1491.47,1685.91C1466.28,1695.87 1463.66,1713.6 1485.62,1725.53C1506.72,1736.98 1542.81,1738.87 1567.95,1730.17C1569.39,1731.1 1570.91,1732.01 1572.55,1732.9C1603.8,1749.87 1658.19,1752.14 1694.04,1737.97C1729.89,1723.8 1733.62,1698.56 1702.38,1681.6Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1525.87,1699.67L1544.28,1679.68C1548.68,1675.37 1554.6,1672.97 1560.64,1673.17C1562.81,1673.24 1565,1673.5 1567.01,1673.94C1569.72,1674.52 1571.06,1676.3 1571.07,1678.88L1571.44,1683.69C1571.78,1691.73 1572.67,1694.42 1567.21,1696.76L1534.76,1710.7C1528,1715.09 1514.29,1709.48 1525.87,1699.67Z"
          style="fill: rgb(86, 40, 20); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1567.63,1676.27C1566.67,1681.6 1562.3,1684.24 1557.02,1683.66C1554.41,1683.37 1552.61,1681.83 1552.25,1676.9C1553.15,1621.64 1547.85,1575.56 1548.66,1523.9C1549.57,1465.9 1551.1,1483.01 1552.63,1394.1L1605.72,1383.24C1594.25,1447.33 1588.71,1528.15 1588.71,1528.15C1602.56,1587.59 1580.07,1606.95 1567.63,1676.27Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1611.14,1363.21L1592.99,1499.02C1590.75,1513.6 1590.71,1525.23 1593.3,1539.73C1597.14,1561.2 1594.35,1604.26 1575.4,1666.6C1566.9,1673.2 1558.09,1673 1549,1667C1549,1667 1542.01,1527.97 1542.32,1501.17C1542.62,1474.37 1555.24,1337.62 1555.24,1337.62L1611.14,1363.21Z"
          style="fill: rgb(135, 168, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1659.08,1696.35L1659.52,1713.84C1659.69,1715.67 1661.63,1725.32 1662.46,1726.98C1664.89,1731.77 1668.87,1733.65 1672.71,1733.84C1677.8,1734.09 1688.01,1725.53 1684.34,1715.13C1681.14,1706.07 1674.76,1691.64 1674.76,1691.64C1667.58,1694.82 1664.26,1696.28 1659.08,1696.35Z"
          style="fill: rgb(86, 40, 20); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1674.51,1696.61C1675.49,1703.46 1664.36,1706.54 1659.74,1699.63C1654.36,1644.81 1644.55,1595.16 1639.49,1543.91C1633.81,1486.37 1628.62,1489.83 1620.04,1401.61L1678.14,1404.35C1673.99,1468.83 1677.64,1549.37 1677.64,1549.37C1699.01,1609.32 1677.88,1619.94 1674.51,1696.61Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1660.8,1351.91C1671.09,1351.79 1679.47,1360.08 1679.28,1370.37C1678.66,1404.51 1677.78,1473.33 1680.22,1492.31C1682.78,1512.16 1689.52,1544.73 1692.39,1558.26C1693.33,1562.67 1693.83,1567.13 1693.91,1571.64C1694.22,1591.46 1691.88,1645.88 1684.45,1679.22C1678.18,1688.36 1664.48,1686.45 1654.17,1681.08L1631.76,1547.88C1631.42,1546.32 1616.2,1446.87 1616.2,1446.87L1579.19,1437.75L1600.93,1348.47C1600.93,1348.47 1632.8,1352.21 1660.8,1351.91Z"
          style="fill: rgb(135, 168, 117); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1425.21,1178.56C1422.06,1181.8 1421.62,1186.49 1424.22,1188.97L1436.22,1195.8C1438.82,1198.29 1437.23,1196.29 1440.38,1193.05C1443.52,1189.8 1446.39,1191.77 1442.48,1185.91L1434.96,1179.06C1433.98,1178.16 1432.88,1177.4 1431.66,1176.87C1429.38,1175.9 1427.68,1176.01 1425.21,1178.56Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1579.54,1275.59C1601.82,1274.89 1596.62,1254.61 1596.62,1254.61L1575.65,1216.55C1561.5,1213.08 1528.18,1229.08 1500.9,1235.43C1493.5,1235.11 1488.58,1232.21 1483.73,1226.93L1444.29,1187.06L1433.85,1194.71L1461.63,1249.81C1471.17,1271.26 1503.82,1272.82 1509.25,1273.57C1509.25,1273.57 1553.03,1276.42 1579.54,1275.59Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1571.89,1257.62C1571.89,1257.62 1571.39,1213.07 1595.8,1213.35C1599.2,1213.39 1604.84,1210.14 1608.77,1210.27C1629.14,1210.94 1626.26,1211.1 1641.13,1216.56C1646.73,1218.61 1650.51,1234.65 1649.71,1240.69L1659.01,1258.61L1571.89,1257.62Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1582.87,1214.48C1586.85,1213.87 1595.8,1213.35 1595.8,1213.35C1593.6,1223.56 1594.36,1230.7 1598.38,1232.96C1616.92,1243.38 1634.62,1212.97 1636.13,1213.87L1655.92,1220.16C1672.12,1225.98 1690.18,1285.16 1679.92,1308.58C1679.92,1308.58 1655.04,1305.91 1654.89,1322.72C1654.76,1337.25 1668.24,1359.42 1670.81,1364.02C1674.14,1369.98 1655.44,1408.12 1599.33,1402.86C1586.19,1401.63 1555.17,1393.05 1548.58,1389.12C1543.36,1386.01 1542.67,1385.11 1545.57,1371.28C1551.32,1343.91 1560.79,1296.97 1560.44,1287.81C1559.97,1275.84 1550.56,1268.29 1556.55,1248.92C1562.55,1229.55 1566.39,1216.39 1566.39,1216.39L1582.87,1214.48Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1687.08,1375.15C1684.51,1370.55 1674.03,1348.47 1674.03,1302.98C1674.03,1293.46 1665.76,1277.86 1669.18,1264.63C1662.17,1279.35 1666.4,1294.89 1660.6,1306.09C1653.36,1320.05 1642.55,1335.59 1645.98,1350.94C1651.87,1377.27 1636.9,1396.75 1617.74,1403.5C1659.85,1400.15 1690.02,1380.43 1687.08,1375.15Z"
          style="fill: rgb(242, 186, 96); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1628.01,1217.85L1621.21,1190.12C1619.91,1185.33 1614.37,1182.91 1609.52,1183.92L1608.08,1184.22C1603.22,1185.24 1600.31,1189.99 1601.6,1194.79L1604.76,1221.58C1606.05,1226.37 1629.31,1222.64 1628.01,1217.85Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1602.4,1198.89C1602.4,1198.89 1614.1,1198.86 1620.17,1191.15C1620.17,1191.15 1614.61,1204.56 1602.86,1204.25L1602.4,1198.89Z"
          style="fill: rgb(121, 59, 31); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1627.67,1162.18C1625.19,1156.75 1620.71,1155.29 1616.17,1157.66C1612.19,1155.36 1606.9,1157.33 1602.52,1163.47C1596.42,1172.02 1593.55,1186.42 1597.17,1194.33C1598.75,1197.79 1601.72,1198.86 1604.59,1199.29C1607.28,1199.68 1610.06,1199.48 1612.76,1198.83C1622.41,1196.47 1622.43,1197.87 1626.49,1192.17C1632.6,1183.63 1631.28,1170.1 1627.67,1162.18Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1603.17,1193.36C1600.93,1192.78 1599.19,1189.66 1599.27,1186.38L1607.38,1188.48C1607.29,1191.76 1605.41,1193.94 1603.17,1193.36Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1633.69,1187.67C1633.62,1190.64 1631.15,1192.99 1628.18,1192.92C1625.21,1192.85 1622.86,1190.38 1622.93,1187.41C1623,1184.43 1625.47,1182.08 1628.44,1182.16C1631.41,1182.23 1633.77,1184.7 1633.69,1187.67Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1594.89,1176.78C1597.38,1174.93 1604.82,1175.01 1605.76,1167.2C1606.71,1159.38 1607.75,1170.71 1614.59,1171.37C1621.44,1172.03 1617.29,1174.26 1619.57,1177.81C1621.85,1181.36 1625.34,1187.38 1631.17,1185.93C1636.99,1184.47 1637.2,1167.75 1636.58,1167.2C1635.96,1166.64 1621.4,1150.6 1621.4,1150.6L1601.29,1153.51L1594.89,1167.2L1594.89,1176.78Z"
          style="fill: rgb(109, 52, 29)"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1751.1,1417.43C1755.08,1414.98 1756.72,1410.39 1754.75,1407.24L1744.46,1397.33C1742.49,1394.17 1743.57,1396.58 1739.59,1399.03C1735.61,1401.48 1733.23,1398.78 1735.66,1405.64L1741.45,1414.44C1742.2,1415.58 1743.11,1416.63 1744.2,1417.47C1746.23,1419.03 1747.97,1419.35 1751.1,1417.43Z"
          style="fill: rgb(153, 74, 36); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1656.77,1220.32C1634.26,1215.33 1636.62,1274.06 1636.62,1274.06C1649.91,1281.16 1669.71,1302.72 1694.03,1328.71C1706.79,1349.5 1734.14,1404.03 1734.14,1404.03L1746.56,1399.02L1732.78,1336.69C1727.57,1317.72 1726.82,1316.17 1715.74,1295.38C1715.74,1295.38 1683.56,1226.25 1656.77,1220.32Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1637.01,1178.46L1637.01,1178.49C1636.98,1178.68 1636.8,1179.63 1636.16,1180.65C1635.51,1181.68 1634.43,1182.77 1632.54,1183.31C1632.32,1183.37 1632.2,1183.59 1632.26,1183.81C1632.32,1184.02 1632.54,1184.14 1632.76,1184.08C1635,1183.45 1636.28,1182.06 1636.98,1180.85C1637.67,1179.64 1637.8,1178.59 1637.81,1178.56C1637.83,1178.34 1637.68,1178.14 1637.46,1178.11C1637.24,1178.08 1637.04,1178.24 1637.01,1178.46Z"
          style="fill: rgb(38, 14, 5); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1605.6,1162.71L1605.58,1162.69C1605.44,1162.49 1604.17,1160.58 1604.17,1158.35C1604.18,1156.61 1604.89,1154.67 1607.6,1152.97C1607.78,1152.85 1607.84,1152.6 1607.72,1152.42C1607.61,1152.23 1607.36,1152.17 1607.17,1152.29C1604.27,1154.08 1603.36,1156.37 1603.37,1158.35C1603.38,1161.05 1604.93,1163.15 1604.95,1163.18C1605.08,1163.36 1605.33,1163.4 1605.51,1163.27C1605.69,1163.14 1605.73,1162.89 1605.6,1162.71Z"
          style="fill: rgb(38, 14, 5); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1595.7,1175.88C1595.7,1175.88 1595.65,1175.91 1595.55,1175.97C1594.83,1176.4 1591.67,1178.13 1588.72,1178.12C1587.88,1178.12 1587.06,1177.98 1586.32,1177.66C1586.11,1177.57 1585.88,1177.66 1585.79,1177.87C1585.7,1178.07 1585.79,1178.31 1586,1178.4C1586.87,1178.77 1587.79,1178.92 1588.72,1178.92C1592.43,1178.91 1596.1,1176.57 1596.13,1176.56C1596.32,1176.44 1596.38,1176.19 1596.26,1176C1596.14,1175.81 1595.89,1175.76 1595.7,1175.88Z"
          style="fill: rgb(38, 14, 5); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1651.24,1271.02L1662.19,1297.1C1662.49,1297.8 1663.29,1298.13 1664,1297.83C1664.7,1297.54 1665.03,1296.73 1664.73,1296.03L1653.78,1269.95C1653.49,1269.25 1652.68,1268.92 1651.98,1269.21C1651.27,1269.51 1650.94,1270.32 1651.24,1271.02Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1.27599,0,0,1.27599,-640.722,-392.932)">
        <path
          d="M1558.84,1276L1564.19,1256.75C1564.39,1256.02 1563.96,1255.26 1563.23,1255.05C1562.49,1254.85 1561.73,1255.28 1561.53,1256.01L1556.19,1275.26C1555.98,1275.99 1556.41,1276.75 1557.15,1276.96C1557.88,1277.16 1558.64,1276.73 1558.84,1276Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default { name: "PlanetImg" };
</script>
