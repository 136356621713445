<template>
  <section class="people-section">
    <div class="container people-container">
      <div class="row align-items-center pb-4">
        <div class="col-md-4 align-middle">
          <PeopleImg />
        </div>
        <div class="col-md-8">
          <h1>Quantes staat voor goed werkgeverschap</h1>
          <p class="lead">
            Een sociaal prettige en veilige omgeving waarbij de teamleden de
            gelegenheid krijgen zich te bekwamen en zich te ontwikkelen en waar
            ruim aandacht is voor de mens achter de medewerker.
          </p>
          <a type="button" class="btn btn-primary text-white" href="/people">
            Lees meer...
          </a>
        </div>
      </div>
      <div class="row">
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2022 ? 'active' : '']"
              aria-current="page"
              @click="setYear(2022)"
              >2022</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2023 ? 'active' : '']"
              @click="setYear(2023)"
              >2023</a
            >
          </li>
        </ul>
      </div>
      <div class="row pt-5">
        <div class="col-md-4 text-center">
          <h3 class="overlay">Leeftijdsopbouw</h3>
          <div class="ps-2 pe-2"><AgeStructure /></div>
        </div>
        <div class="col-md-4 text-center">
          <h3 class="overlay">Geslacht</h3>
          <div class="ps-2 pe-2"><GenderStructure /></div>
        </div>
        <div class="col-md-4 text-center">
          <h3 class="overlay">Ziekteverzuim</h3>
          <div class="ps-2 pe-2"><SickLeaveInfo /></div>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-md-6 text-center">
          <InOutFlowInfo />
        </div>
        <div class="col-md-6 text-center">
          <AccidentsInfo />
        </div>
      </div>
      <!-- <div class="row pt-5">
        <div class="col-md-12 text-center">
          <div class="d-grid">
            <button type="button" class="btn btn-primary text-white">
              Acties en maatregelen in 2023
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import PeopleImg from "@/components/PeopleImg.vue";
import AgeStructure from "@/components/AgeStructure.vue";
import GenderStructure from "@/components/GenderStructure.vue";
import SickLeaveInfo from "@/components/SickLeaveInfo.vue";
import InOutFlowInfo from "@/components/InOutFlowInfo.vue";
import AccidentsInfo from "@/components/AccidentsInfo.vue";

export default {
  name: "PeopleSection",
  components: {
    PeopleImg,
    AgeStructure,
    GenderStructure,
    SickLeaveInfo,
    InOutFlowInfo,
    AccidentsInfo,
  },
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
  },
  created() {
    this.$store.commit("setActiveYear", 0);
    this.$store.commit("setActiveYear", 2023);
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
  },
};
</script>

<style scoped>
.people-section {
  padding: 2em;
}
.people-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.active {
  font-weight: 500;
}
.nav-link {
  cursor: pointer;
}
</style>
