<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(1.88192,0,0,1.88192,-6347.84,-341.579)"
      >
        <path
          d="M4366.32,548.441C4417.7,673.197 4468.99,962.977 4226.36,1012.33C4034.4,1051.37 4027.13,842.409 3700.99,829.162C3700.99,829.162 3469.81,845.832 3406.21,597.289C3358.7,411.617 3572.9,168.937 3912.42,213.413C4141.97,243.483 4290.39,364.082 4366.32,548.441Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6390.53,-212.281)">
      <path
        d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6375.02,227.566)">
      <path
        d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6772.07,-314.876)">
      <path
        d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1566.43,-561.718)">
      <g transform="matrix(50,0,0,50,1105.28,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Bold', 'Lexend';
          font-weight: 700;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Totaal
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-2073.42,-372.344)">
      <g transform="matrix(50,0,0,50,1168.08,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(92, 41, 21);
        "
      >
        Recycling
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1566.43,-182.97)">
      <g transform="matrix(50,0,0,50,1097.13,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Papier
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1566.43,9.85723)">
      <g transform="matrix(50,0,0,50,1105.53,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Karton
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1566.43,202.684)">
      <g transform="matrix(50,0,0,50,1105.28,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Bold', 'Lexend';
          font-weight: 700;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Totaal
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-904.405,-561.718)">
      <g transform="matrix(50,0,0,50,1180.93,438.662)"></g>
      <text
        x="1180px"
        y="438.662px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getPaperUsage() }} kg
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-904.405,-182.97)">
      <g transform="matrix(50,0,0,50,1180.93,438.662)"></g>
      <text
        x="1180px"
        y="438.662px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getPaperWaste() }} kg
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-904.405,9.85723)">
      <g transform="matrix(50,0,0,50,1180.93,438.662)"></g>
      <text
        x="1180px"
        y="438.662px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getCardboardWaste() }} kg
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-904.405,202.684)">
      <g transform="matrix(50,0,0,50,1180.93,438.662)"></g>
      <text
        x="1180px"
        y="438.662px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getCardboardWaste() + getPaperWaste() }} kg
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-904.405,395.512)">
      <g transform="matrix(50,0,0,50,1066.88,438.662)"></g>
      <text
        x="1180px"
        y="438.662px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getPercentage() }} %
      </text>
    </g>
    <path
      d="M612.017,1088.69L1805.69,1088.69"
      style="
        fill: none;
        stroke: rgb(29, 89, 28);
        stroke-width: 4.17px;
        stroke-linejoin: round;
        stroke-miterlimit: 1.5;
      "
    />
  </svg>
</template>

<script>
export default {
  name: "PaperUsage",
  data: () => ({
    paperusage: 0,
    paperwaste: 0,
    cardboardwaste: 0,
  }),
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  created() {
    // this.animateValues();
  },
  methods: {
    getPaperUsage() {
      let value = this.getInfoByActiveYear.paperusage.paperusage;
      return value;
    },
    getPaperWaste() {
      let value = this.getInfoByActiveYear.paperwaste.paperwaste;
      return value;
    },
    getCardboardWaste() {
      let value = this.getInfoByActiveYear.cardboardwaste.cardboardwaste;
      return value;
    },
    getPercentage() {
      let recyclingtotal = this.getPaperWaste() + this.getCardboardWaste();
      let percent = (recyclingtotal / this.getPaperUsage()) * 100;
      return percent.toFixed(2);
    },
  },
};
</script>
