<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 2000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(1.3704,0,0,1.3704,-5746.34,-397.277)"
      >
        <path
          d="M5588.52,751.273C5651.15,871.73 5748.59,1287.37 5424.12,1435.88C5189.5,1543.27 5053.88,1466.6 4942.47,1389.94C4853.06,1328.42 4779.23,1266.89 4682.35,1300.5C4682.35,1300.5 4371.68,1365.99 4226.68,1005.66C4081.67,645.328 4419.77,289.126 5002.88,342.873C5232.22,364.011 5465.24,514.153 5588.52,751.273Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(1.3704,0,0,1.3704,-5735.86,-600.768)">
      <path
        id="AardeSchaduw"
        d="M5258.97,1566.3C5260.04,1638.92 5116.13,1699.94 4937.53,1702.57C4758.93,1705.21 4613.28,1648.47 4612.21,1575.84C4611.14,1503.22 4755.05,1442.2 4933.65,1439.57C5112.25,1436.93 5257.9,1493.67 5258.97,1566.3Z"
        style="fill: rgb(233, 242, 225); fill-rule: nonzero"
      />
      <g id="Aarde">
        <path
          d="M5203.2,1398.25C5153.41,1590.59 4957.12,1706.14 4764.78,1656.34C4572.44,1606.55 4456.88,1410.26 4506.68,1217.92C4556.48,1025.58 4752.77,910.023 4945.11,959.82C5137.45,1009.62 5253,1205.91 5203.2,1398.25Z"
          style="fill: rgb(128, 195, 91); fill-rule: nonzero"
        />
        <path
          d="M5191.19,1180.12C5176.38,1173.69 5024.1,1109.81 4974.74,1185.96C4922.95,1265.87 4853.29,1232.28 4854.96,1329.14C4856.63,1426.01 4912.98,1496.58 5003.46,1507.57C5107.45,1520.19 4876.49,1619.6 4948.18,1655.59C5139.67,1607.08 5268.14,1394.77 5191.19,1180.12Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
        <path
          d="M4740.73,966.91C4740.73,966.91 4895,997.373 4886.96,1054.37C4878.92,1111.37 4842.86,1092.14 4784.19,1111.02C4725.52,1129.9 4709.75,1098.79 4668.65,1139.93C4627.55,1181.08 4540.95,1132.32 4540.95,1132.32C4540.95,1132.32 4591.87,1016.84 4740.73,966.91Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
        <path
          d="M4497.28,1283.89C4497.28,1283.89 4615.26,1288.86 4635.7,1378.14C4656.13,1467.41 4696.5,1443.65 4713.68,1485.42C4718.51,1497.17 4714.46,1509.96 4706.15,1522.26C4674.18,1569.58 4605.47,1572.84 4568.05,1529.7C4530.26,1486.14 4491.77,1410.27 4497.28,1283.89Z"
          style="fill: rgb(101, 157, 72); fill-rule: nonzero"
        />
        <path
          d="M4854.93,1667.83C4836.63,1667.83 4818.33,1666.44 4800.21,1663.66C4788.4,1661.83 4776.59,1659.4 4764.78,1656.34C4732.5,1647.99 4702.38,1635.5 4674.85,1619.61C4648.72,1604.48 4624.09,1585.79 4601.65,1563.54C4664.11,1582.53 4721.49,1591.15 4773.59,1591.15C4873.11,1591.15 4953.41,1559.71 5013.25,1509.22C5091.5,1527.14 4879.03,1620.87 4948.18,1655.59C4948.21,1655.58 4948.23,1655.58 4948.26,1655.57C4917.73,1663.74 4886.33,1667.83 4854.93,1667.83Z"
          style="fill: rgb(84, 162, 53); fill-rule: nonzero"
        />
        <path
          d="M5171.93,1478.38C5171.92,1478.38 5171.93,1478.38 5171.92,1478.37C5185.24,1453.54 5195.83,1426.74 5203.2,1398.25C5206.17,1386.79 5208.55,1375.33 5210.36,1363.87C5204.18,1403.47 5191.38,1442.22 5171.93,1478.38ZM5201.84,1212.59C5188.28,1163.49 5164.41,1117.8 5132.1,1078.7C5165.44,1118.88 5188.69,1164.64 5201.84,1212.59Z"
          style="fill: rgb(166, 202, 149); fill-rule: nonzero"
        />
        <path
          d="M5167.25,1486.81C5168.84,1484.02 5170.4,1481.21 5171.92,1478.37C5171.93,1478.38 5171.92,1478.38 5171.93,1478.38C5170.41,1481.2 5168.85,1484.01 5167.25,1486.81Z"
          style="fill: rgb(166, 202, 149); fill-rule: nonzero"
        />
        <path
          d="M5122.58,1548.47C5205.19,1455.31 5240.69,1318.21 5191.19,1180.12C5186.12,1177.92 5164.95,1168.99 5137.31,1161.25C5132.44,1124.38 5122.79,1087.75 5108.23,1052.62C5116.69,1061.01 5124.65,1069.72 5132.1,1078.7C5164.41,1117.8 5188.28,1163.49 5201.84,1212.59C5215.37,1261.91 5218.21,1313.56 5210.36,1363.87C5208.55,1375.33 5206.17,1386.79 5203.2,1398.25C5195.83,1426.74 5185.24,1453.54 5171.92,1478.37C5170.4,1481.21 5168.84,1484.02 5167.25,1486.81C5154.81,1508.51 5139.92,1529.2 5122.58,1548.47Z"
          style="fill: rgb(84, 162, 53); fill-rule: nonzero"
        />
        <path
          d="M4948.18,1655.59C4879.03,1620.87 5091.5,1527.14 5013.25,1509.22C5111.46,1426.37 5154.58,1292.2 5137.31,1161.25C5164.95,1168.99 5186.12,1177.92 5191.19,1180.12C5240.69,1318.21 5205.19,1455.31 5122.58,1548.47C5118.65,1552.84 5114.58,1557.15 5110.4,1561.37C5063.93,1608.24 5007.73,1639.65 4948.26,1655.57C4948.23,1655.58 4948.21,1655.58 4948.18,1655.59Z"
          style="fill: rgb(64, 131, 42); fill-rule: nonzero"
        />
        <path
          d="M4948.21,1131.17L4692.36,1083.17C4670.03,1078.98 4662.19,1065.42 4674.92,1053.02C4687.65,1040.63 4716.34,1033.92 4738.66,1038.11L4994.51,1086.12C5016.84,1090.31 5024.68,1103.87 5011.95,1116.26C4999.22,1128.65 4970.53,1135.36 4948.21,1131.17Z"
          style="fill: rgb(41, 67, 27); fill-rule: nonzero"
        />
      </g>
      <g class="downup">
        <g id="Geldstuk">
          <path
            d="M4914.26,1074.9C4920.42,1071.3 4955.33,1042.87 4959.84,1036.25C4976.01,1017.48 4984.73,991.036 4982.49,960.263C4977.57,892.741 4921.87,830.071 4858.06,820.286C4842.8,817.945 4829.11,817.798 4816.61,820.442C4804.75,822.95 4762.88,851.882 4762.88,851.882C4757.21,858.803 4747.08,876.345 4743.54,885.088C4735.68,901.178 4731.91,920.468 4733.46,941.871C4738.38,1009.39 4794.09,1072.06 4857.89,1081.85C4884.76,1085.97 4895.83,1088.18 4914.26,1074.9Z"
            style="fill: rgb(244, 193, 113); fill-rule: nonzero"
          />
          <path
            d="M4843.41,1096.19C4778.49,1089.48 4722.16,1026 4717.6,954.394C4713.05,882.789 4761.98,830.179 4826.91,836.885C4891.83,843.592 4948.16,907.076 4952.71,978.681C4957.27,1050.29 4908.33,1102.9 4843.41,1096.19Z"
            style="fill: rgb(255, 206, 121); fill-rule: nonzero"
          />
          <path
            d="M4903.89,1083.28C4886.73,1093.66 4866.04,1098.53 4843.41,1096.19C4778.49,1089.48 4722.16,1026 4717.6,954.394C4714.76,909.641 4732.8,872.308 4762.59,852.238"
            style="fill: rgb(255, 206, 121); fill-rule: nonzero"
          />
          <path
            d="M4763.54,853.381C4778.44,843.344 4796.31,837.688 4816.02,837.687C4819.6,837.687 4823.25,837.874 4826.94,838.256C4859,841.563 4889.02,858.916 4911.59,884.361C4934.17,909.8 4949.26,943.301 4951.51,978.769C4951.71,981.836 4951.81,984.868 4951.81,987.86C4951.8,1029.95 4932.83,1064.28 4903.36,1082.1C4902.71,1082.5 4902.5,1083.35 4902.89,1084C4903.29,1084.65 4904.13,1084.86 4904.79,1084.46C4935.17,1066.09 4954.57,1030.77 4954.56,987.86C4954.56,984.809 4954.46,981.719 4954.27,978.594C4951.96,942.455 4936.61,908.412 4913.66,882.532C4890.7,856.656 4860.1,838.914 4827.23,835.514C4823.44,835.123 4819.7,834.931 4816.02,834.931C4795.78,834.929 4777.35,840.752 4762,851.096C4761.37,851.521 4761.2,852.377 4761.63,853.008C4762.05,853.64 4762.91,853.806 4763.54,853.381Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4838.63,1059.02C4793.24,1054.33 4753.86,1009.95 4750.68,959.897C4747.49,909.839 4781.7,873.06 4827.09,877.748C4863.55,881.515 4896.13,910.892 4909.19,948.25C4912.39,957.399 4914.41,967.027 4915.04,976.875C4918.22,1026.93 4884.01,1063.71 4838.63,1059.02Z"
            style="fill: white; fill-rule: nonzero"
          />
          <path
            d="M4929.56,894.865L4948.46,879.382C4949.05,878.899 4949.13,878.031 4948.65,877.442C4948.17,876.854 4947.3,876.767 4946.71,877.25L4927.82,892.733C4927.23,893.215 4927.14,894.083 4927.62,894.672C4928.11,895.261 4928.97,895.347 4929.56,894.865Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4944.48,919.704L4963.79,903.531C4964.38,903.042 4964.45,902.173 4963.96,901.59C4963.48,901.006 4962.61,900.93 4962.02,901.418L4942.71,917.591C4942.13,918.08 4942.05,918.949 4942.54,919.533C4943.03,920.116 4943.9,920.193 4944.48,919.704Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4955.43,953.69L4975.15,937.895C4975.74,937.419 4975.83,936.552 4975.36,935.958C4974.88,935.364 4974.02,935.269 4973.42,935.745L4953.71,951.539C4953.12,952.015 4953.02,952.882 4953.5,953.476C4953.97,954.07 4954.84,954.166 4955.43,953.69Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4958.24,990.203L4977.99,973.443C4978.57,972.951 4978.64,972.081 4978.15,971.501C4977.66,970.921 4976.79,970.85 4976.21,971.342L4956.46,988.102C4955.88,988.595 4955.81,989.464 4956.3,990.044C4956.79,990.625 4957.66,990.696 4958.24,990.203Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4950.03,1029.13L4967.78,1013.39C4968.35,1012.88 4968.4,1012.01 4967.9,1011.44C4967.39,1010.87 4966.52,1010.82 4965.95,1011.33L4948.2,1027.07C4947.63,1027.57 4947.58,1028.44 4948.08,1029.01C4948.59,1029.58 4949.46,1029.64 4950.03,1029.13Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4906.74,870.901L4924.46,855.965C4925.04,855.474 4925.11,854.605 4924.62,854.023C4924.13,853.441 4923.26,853.367 4922.68,853.858L4904.97,868.794C4904.39,869.284 4904.31,870.154 4904.8,870.735C4905.29,871.317 4906.16,871.391 4906.74,870.901Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4880,850.419L4897.53,836.305C4898.12,835.827 4898.22,834.96 4897.74,834.367C4897.26,833.774 4896.39,833.681 4895.8,834.158L4878.27,848.273C4877.68,848.75 4877.59,849.618 4878.07,850.21C4878.54,850.803 4879.41,850.897 4880,850.419Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4844.84,838.338L4863.08,826.506C4863.71,826.092 4863.9,825.239 4863.48,824.6C4863.07,823.962 4862.21,823.78 4861.58,824.194L4843.34,836.025C4842.7,836.44 4842.52,837.293 4842.93,837.931C4843.35,838.57 4844.2,838.752 4844.84,838.338Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4810.87,835.391L4827.83,824.216C4828.47,823.798 4828.64,822.943 4828.22,822.308C4827.8,821.672 4826.95,821.496 4826.31,821.915L4809.35,833.089C4808.71,833.508 4808.54,834.362 4808.96,834.998C4809.38,835.633 4810.23,835.809 4810.87,835.391Z"
            style="fill: rgb(217, 173, 100); fill-rule: nonzero"
          />
          <path
            d="M4861.87,836.479L4867.25,763.322C4867.35,762.048 4866.39,760.94 4865.11,760.846C4863.84,760.752 4862.73,761.709 4862.64,762.982L4857.25,836.139C4857.16,837.413 4858.12,838.522 4859.39,838.615C4860.66,838.709 4861.77,837.753 4861.87,836.479Z"
            style="fill: rgb(44, 94, 46); fill-rule: nonzero"
          />
          <path
            d="M4863.67,788.146C4863.67,788.146 4856.1,679.547 4964.43,706.284C4964.43,706.284 4953.95,797.675 4863.67,788.146Z"
            style="fill: rgb(123, 187, 87); fill-rule: nonzero"
          />
          <path
            d="M4864.01,788.246C4864.01,788.246 4787.35,763.471 4777.75,653.105C4777.75,653.105 4885.53,696.256 4864.01,788.246Z"
            style="fill: rgb(123, 187, 87); fill-rule: nonzero"
          />
          <g transform="matrix(5.04549,1.46367,0,5.04549,-18791.1,-10808.5)">
            <g transform="matrix(12,0,0,12,4689.03,980.316)"></g>
            <g transform="matrix(12,0,0,12,4692.54,980.316)"></g>
            <text
              x="4670.98px"
              y="980.316px"
              style="
                font-family: 'BrandonGrotesque-Medium', 'Brandon Grotesque',
                  sans-serif;
                font-weight: 420;
                font-size: 12px;
                fill: rgb(255, 206, 121);
              "
            >
              CO²
            </text>
          </g>
        </g>
      </g>
    </g>
    <g class="updown">
      <g id="Balletjes" transform="matrix(1.3704,0,0,1.3704,-370.402,-370.402)">
        <g transform="matrix(1,0,0,1,-3849.73,-219.158)">
          <path
            d="M5376.41,1311.67L5373.07,1311.67C5373.07,1315.78 5371.41,1319.49 5368.71,1322.19C5366.01,1324.88 5362.3,1326.55 5358.19,1326.55C5354.08,1326.55 5350.37,1324.88 5347.67,1322.19C5344.97,1319.49 5343.31,1315.78 5343.31,1311.67C5343.31,1307.55 5344.97,1303.85 5347.67,1301.15C5350.37,1298.45 5354.08,1296.79 5358.19,1296.79C5362.3,1296.79 5366.01,1298.45 5368.71,1301.15C5371.41,1303.85 5373.07,1307.55 5373.07,1311.67L5376.41,1311.67C5376.41,1301.61 5368.25,1293.45 5358.19,1293.45C5348.13,1293.45 5339.97,1301.61 5339.97,1311.67C5339.97,1321.73 5348.13,1329.88 5358.19,1329.89C5368.25,1329.88 5376.41,1321.73 5376.41,1311.67Z"
            style="fill: rgb(56, 89, 37); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1,0,0,1,-3915.24,-144.083)">
          <path
            d="M5052.61,642.74L5049.27,642.74C5049.27,646.854 5047.61,650.563 5044.91,653.261C5042.21,655.956 5038.5,657.618 5034.39,657.618C5030.28,657.618 5026.57,655.956 5023.87,653.261C5021.17,650.563 5019.51,646.854 5019.51,642.74C5019.51,638.626 5021.17,634.917 5023.87,632.22C5026.57,629.525 5030.28,627.863 5034.39,627.862C5038.5,627.863 5042.21,629.525 5044.91,632.22C5047.61,634.917 5049.27,638.626 5049.27,642.74L5052.61,642.74C5052.61,632.678 5044.45,624.524 5034.39,624.523C5024.33,624.524 5016.17,632.678 5016.17,642.74C5016.17,652.802 5024.33,660.956 5034.39,660.957C5044.45,660.956 5052.61,652.802 5052.61,642.74Z"
            style="fill: rgb(56, 89, 37); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1,0,0,1,-3915.24,-144.083)">
          <path
            d="M4375.89,980.773L4372.55,980.773C4372.55,984.886 4370.89,988.595 4368.19,991.293C4365.49,993.988 4361.79,995.65 4357.67,995.651C4353.56,995.65 4349.85,993.988 4347.15,991.293C4344.46,988.595 4342.79,984.886 4342.79,980.773C4342.79,976.659 4344.46,972.95 4347.15,970.252C4349.85,967.557 4353.56,965.895 4357.67,965.894C4361.79,965.895 4365.49,967.557 4368.19,970.252C4370.89,972.95 4372.55,976.659 4372.55,980.773L4375.89,980.773C4375.89,970.711 4367.73,962.557 4357.67,962.555C4347.61,962.557 4339.46,970.711 4339.45,980.773C4339.46,990.834 4347.61,998.989 4357.67,998.99C4367.73,998.989 4375.89,990.834 4375.89,980.773Z"
            style="fill: rgb(56, 89, 37); fill-rule: nonzero"
          />
        </g>
      </g>
    </g>
    <g class="cloud">
      <g id="wolk2" transform="matrix(1.3704,0,0,1.3704,-5735.86,-567.853)">
        <path
          d="M5343.13,1009.18C5354.39,1010.43 5361.67,998.306 5356.49,986.805C5346.82,965.302 5329.45,937.402 5303.97,935.641C5277.51,933.814 5261.29,952.509 5252.95,966.471C5248.81,973.4 5240.08,975.049 5233.03,970.154C5224.13,963.977 5210.98,957.087 5194.65,955.042C5186.34,954.002 5178.39,955.664 5171.18,958.677C5156.75,964.706 5161.83,988.941 5177.92,990.736L5343.13,1009.18Z"
          style="fill: white"
        />
      </g>
      <g id="wolk1" transform="matrix(1.3704,0,0,1.3704,-5735.86,-567.853)">
        <path
          d="M4500.87,743.743C4489.92,742.413 4482.76,729.343 4487.73,719.646C4497.02,701.519 4513.76,679.074 4538.55,683.109C4564.3,687.298 4580.2,708.563 4588.41,723.602C4592.48,731.065 4600.98,734.571 4607.82,731.528C4616.44,727.689 4629.21,724.127 4645.09,725.847C4653.17,726.723 4660.92,730.068 4667.96,734.522C4682.04,743.436 4677.24,765.167 4661.59,763.267L4500.87,743.743Z"
          style="fill: white"
        />
      </g>
    </g>
    <g id="Man" transform="matrix(-1.3704,0,0,1.3704,6080.58,-644.115)">
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3396.07,1144.97C3394.43,1149.31 3395.85,1153.91 3399.22,1155.21L3413,1156.81C3416.38,1158.11 3414.13,1156.86 3415.78,1152.53C3417.42,1148.19 3420.85,1148.88 3414.94,1144.94L3405.29,1141.5C3404.04,1141.06 3402.72,1140.78 3401.38,1140.78C3398.9,1140.77 3397.36,1141.57 3396.07,1144.97Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3552.98,1155.76L3490.85,1172.2C3489.04,1172.75 3487.09,1172.66 3485.33,1171.94L3425.23,1147.16C3421.2,1145.5 3416.58,1147.28 3414.72,1151.22L3414.09,1152.57C3412.59,1155.74 3413.25,1159.49 3415.78,1161.92C3428.26,1173.95 3465.3,1208.68 3480.28,1211.99C3482.48,1212.48 3521.33,1205.44 3535.16,1202.91C3538.15,1202.36 3540.56,1200.21 3541.47,1197.31L3550.9,1167.12C3552.86,1160.84 3559.27,1153.83 3552.98,1155.76Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3703.18,1636.34C3671.91,1620.46 3618.23,1619.7 3583.27,1634.64C3577.31,1637.18 3572.29,1640.03 3568.2,1643.07C3546.11,1636.49 3516.09,1637.46 3495.39,1646.31C3470.82,1656.81 3468.72,1674.36 3490.69,1685.52C3511.8,1696.24 3547.42,1697.12 3571.97,1687.86C3573.42,1688.74 3574.94,1689.6 3576.58,1690.43C3607.84,1706.31 3661.53,1707.08 3696.49,1692.14C3731.45,1677.2 3734.44,1652.22 3703.18,1636.34Z"
          style="fill: rgb(233, 242, 225); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3519.13,1660.01L3541.01,1637.41C3545.23,1633.05 3551,1630.52 3556.96,1630.56C3559.1,1630.57 3561.26,1630.76 3563.26,1631.14C3565.95,1631.65 3567.31,1633.36 3567.4,1635.9L3567.89,1640.63C3568.45,1648.55 3569.4,1651.18 3564.07,1653.64L3532.47,1668.25C3525.92,1672.76 3507.98,1670 3519.13,1660.01Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3564.15,1632.42C3563.35,1637.71 3558.71,1642.11 3553.49,1641.68C3550.91,1641.46 3549.09,1639.99 3548.6,1635.15C3547.99,1580.65 3541.53,1535.36 3540.93,1484.42C3540.25,1427.21 3542.22,1444.04 3541.32,1356.35L3593.36,1344.21C3583.79,1407.7 3580.51,1487.52 3580.51,1487.52C3595.79,1545.74 3574.53,1563.75 3564.15,1632.42Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3598.16,1324.32L3583.94,1458.69C3582.13,1473.13 3582.41,1484.59 3585.36,1498.81C3589.73,1519.87 3588.14,1562.39 3571.15,1624.37C3562.95,1631.1 3554.26,1631.14 3545.14,1625.48C3545.14,1625.48 3534.48,1488.6 3534.05,1462.18C3533.63,1435.76 3536.99,1293.3 3536.99,1293.3L3598.16,1324.32Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3654.45,1651.43L3655.36,1668.65C3655.57,1670.45 3657.74,1679.92 3658.61,1681.53C3661.14,1686.18 3665.11,1687.93 3668.9,1688.01C3673.92,1688.12 3683.75,1679.41 3679.85,1669.26C3676.45,1660.41 3669.77,1646.35 3669.77,1646.35C3662.79,1649.69 3659.55,1651.21 3654.45,1651.43Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3669.66,1651.26C3670.82,1657.99 3659.93,1661.33 3655.18,1654.64C3648.39,1600.74 3637.38,1552.06 3631,1501.68C3623.84,1445.11 3618.82,1448.66 3607.98,1361.93L3665.32,1363.05C3662.98,1426.73 3668.76,1506.03 3668.76,1506.03C3691.45,1564.54 3670.9,1575.59 3669.66,1651.26Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3669.54,1309.89C3669.54,1309.89 3665.82,1424.61 3669.76,1449.7C3672.82,1469.21 3680.34,1501.13 3683.54,1514.39C3684.59,1518.72 3685.21,1523.1 3685.4,1527.55C3686.24,1547.07 3685.41,1600.79 3678.99,1633.85C3673.06,1643.03 3659.5,1641.51 3649.19,1636.5L3623.49,1505.8C3623.11,1504.27 3604.2,1402 3604.2,1402L3571.79,1396.49L3587.7,1310.06C3587.7,1310.06 3673.63,1317.02 3669.54,1309.89Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3597.07,1148.27C3610.04,1145.04 3615.64,1148.25 3617,1149.12L3654.39,1162.52C3686.99,1173.39 3683.21,1205.12 3683.94,1224.78C3683.94,1224.78 3672.47,1247.26 3671.41,1265.83C3670.5,1281.88 3660.61,1318.17 3663.19,1323.31C3665.12,1327.15 3664.94,1351.07 3645.12,1357.71C3627.61,1363.57 3599.46,1376.21 3584.52,1376.22C3505.92,1376.27 3527.84,1338.96 3529.59,1312.41C3530.69,1295.7 3535.02,1246.35 3535.14,1233.12C3535.2,1226.48 3534.79,1210.25 3536.83,1194.1C3538.86,1178.07 3552.98,1155.76 3552.98,1155.76C3565.82,1152.09 3583.36,1151.68 3597.07,1148.27Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3677.62,1337.51C3676.47,1328.49 3676.69,1281.92 3677.61,1265.87C3678.21,1255.36 3679.77,1224.75 3681.32,1209.18C3673.46,1224.12 3667.89,1229.87 3667.05,1233.51C3660.42,1262.17 3658.8,1277.79 3644.83,1294.6C3618.03,1326.86 3610.95,1343.3 3584.52,1376.22C3639.07,1378.65 3655.95,1374.69 3667.46,1367.78C3681.76,1359.19 3678.22,1342.14 3677.62,1337.51Z"
          style="fill: rgb(240, 191, 112); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3535.46,1193.97L3534.44,1204.08C3534.37,1204.83 3534.92,1205.51 3535.67,1205.59C3536.43,1205.66 3537.11,1205.11 3537.18,1204.35L3538.2,1194.24C3538.28,1193.49 3537.73,1192.81 3536.97,1192.73C3536.21,1192.66 3535.54,1193.21 3535.46,1193.97Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3581.59,1334.4C3581.3,1338.24 3577.93,1341.15 3574.1,1340.87L3564.72,1340.18C3560.88,1339.9 3557.97,1336.52 3558.25,1332.68C3558.53,1328.85 3561.91,1325.94 3565.74,1326.22L3575.12,1326.91C3578.96,1327.19 3581.87,1330.56 3581.59,1334.4Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3601.35,1316.31L3574.81,1330.04L3577.47,1340.07L3617.53,1330.36L3601.35,1316.31Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3679.59,1185.62C3685.39,1202.07 3688.94,1243.51 3689.16,1249.08C3690.78,1290.4 3678.64,1310.3 3667.91,1320.2C3664.33,1323.5 3589.05,1342.11 3589.05,1342.11L3582.41,1322.12L3642.98,1286.88L3635.24,1200.97L3679.59,1185.62Z"
          style="fill: rgb(255, 206, 121); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3646.27,1305.72L3646.34,1303.68L3643.25,1305.6L3642.66,1304.49L3568.21,1289.05C3567.32,1288.87 3565.7,1289.4 3564.61,1290.25L3531.06,1316.3L3531.08,1315.85L3524.92,1319.74L3524.82,1321.91L3524.86,1321.9C3524.93,1322.09 3525.11,1322.23 3525.42,1322.29L3602.89,1338.36C3603.78,1338.54 3605.4,1338.01 3606.49,1337.16L3645.34,1306.99C3645.94,1306.53 3646.25,1306.07 3646.27,1305.72Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3602.94,1336.21L3525.47,1320.15C3524.57,1319.96 3524.73,1319.12 3525.81,1318.28L3564.67,1288.1C3565.75,1287.26 3567.37,1286.72 3568.27,1286.91L3645.74,1302.97C3646.64,1303.16 3646.48,1304 3645.4,1304.84L3606.54,1335.02C3605.46,1335.86 3603.84,1336.4 3602.94,1336.21Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3591.86,1281.76C3591.38,1280.21 3589.56,1278.67 3587.8,1278.33L3506.01,1262.28C3505.28,1262.14 3504.68,1262.22 3504.26,1262.48L3504.25,1262.48L3504.24,1262.49C3504.09,1262.58 3503.96,1262.69 3503.86,1262.83L3501.49,1265.59L3503.83,1264.86L3515.07,1300.8C3515.55,1302.35 3517.37,1303.89 3519.13,1304.24L3596.47,1319.41L3599.07,1320.94L3604.95,1336.17C3606.48,1336.06 3607.94,1333.93 3607.94,1333.93L3591.86,1281.76Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3503.73,1265.13L3585.52,1281.18C3587.28,1281.52 3589.1,1283.06 3589.58,1284.61L3600.95,1320.95L3600.96,1321.03L3605.51,1335.57C3606,1337.12 3604.96,1338.1 3603.2,1337.76L3521.42,1321.71C3519.66,1321.36 3517.84,1319.82 3517.35,1318.27L3505.99,1281.93L3505.97,1281.86L3501.42,1267.31C3500.94,1265.76 3501.97,1264.78 3503.73,1265.13Z"
          style="fill: rgb(92, 42, 21); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3555.94,1305.17C3556.14,1308.98 3553.37,1311.33 3549.75,1310.44C3546.14,1309.54 3543.04,1305.73 3542.84,1301.92C3542.64,1298.11 3545.41,1295.76 3549.03,1296.66C3552.65,1297.55 3555.74,1301.37 3555.94,1305.17Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3630.43,1294.26L3629.23,1294.24L3630.34,1294.71C3630.36,1294.64 3630.42,1294.53 3630.43,1294.26L3629.23,1294.24L3630.34,1294.71L3629.7,1294.44L3630.29,1294.8L3630.34,1294.71L3629.7,1294.44L3630.29,1294.8C3630.32,1294.75 3630.48,1294.56 3630.7,1294.31C3631.57,1293.37 3633.44,1291.58 3635.72,1289.49C3639.13,1286.35 3643.49,1282.48 3646.99,1279.39C3648.74,1277.85 3650.28,1276.5 3651.38,1275.54C3652.47,1274.58 3653.13,1274.01 3653.13,1274.01C3653.46,1273.73 3653.63,1273.3 3653.6,1272.87L3649.64,1216.54C3649.58,1215.78 3648.92,1215.21 3648.16,1215.26C3647.4,1215.31 3646.83,1215.97 3646.89,1216.73L3650.85,1273.07L3652.23,1272.97L3651.32,1271.93C3651.32,1271.93 3645.53,1276.97 3639.73,1282.15C3636.83,1284.74 3633.93,1287.37 3631.74,1289.43C3630.64,1290.46 3629.72,1291.35 3629.06,1292.04C3628.73,1292.39 3628.46,1292.68 3628.25,1292.94C3628.14,1293.08 3628.04,1293.2 3627.95,1293.35C3627.9,1293.43 3627.85,1293.51 3627.8,1293.63C3627.75,1293.76 3627.68,1293.91 3627.67,1294.21C3627.66,1294.97 3628.27,1295.6 3629.03,1295.62C3629.79,1295.63 3630.42,1295.02 3630.43,1294.26Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3604.29,1160.32L3602.84,1159.01C3598.91,1160.07 3595.47,1159.76 3594.86,1156.34L3591.46,1137.07C3590.85,1133.65 3593.58,1129.99 3597.52,1128.93L3598.68,1128.62C3602.62,1127.56 3613.44,1127.54 3614.04,1130.96L3615.85,1151.38C3616.45,1154.8 3608.23,1159.26 3604.29,1160.32Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3593.3,1147.39C3593.3,1147.39 3601.71,1146.62 3606.99,1137.96C3606.99,1137.96 3601.77,1150.68 3594.14,1152.6L3593.3,1147.39Z"
          style="fill: rgb(215, 64, 40); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3610.34,1106.77C3607.22,1101.63 3602.52,1100.79 3598.19,1103.83C3593.91,1102.08 3588.77,1104.82 3585.02,1111.66C3579.8,1121.18 3577.36,1136.2 3581.9,1143.69C3583.88,1146.97 3588.55,1148.84 3591.51,1148.86C3594.27,1148.88 3601.46,1146.51 3604.11,1145.46C3613.62,1141.7 3611.24,1143.69 3614.72,1137.35C3619.95,1127.84 3614.88,1114.27 3610.34,1106.77Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3609.59,1111.15C3613.24,1120.19 3611.3,1135.63 3611.87,1138.4C3611.87,1138.4 3626.45,1128.05 3625.55,1119.95C3622.56,1092.95 3606.7,1107.6 3606.7,1107.6L3609.59,1111.15Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3609.73,1097.54C3615.38,1098.83 3618.83,1103.03 3620.67,1110.42C3620.91,1111.36 3622.94,1115.61 3623.02,1116.41L3619.39,1115.4C3620.05,1121.79 3618.33,1113.94 3611.89,1115.18C3601.9,1111.63 3593.33,1104.01 3581.81,1119.39C3580.79,1120.75 3577.05,1118.52 3578.41,1115.38C3583.68,1103.26 3595.17,1098.84 3598.86,1097.86L3600.72,1097.56C3604.2,1096.98 3607.19,1096.97 3609.73,1097.54Z"
          style="fill: rgb(115, 55, 29); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3588.6,1141.73C3586.39,1141.29 3584.92,1138.65 3585.33,1135.83L3593.37,1137.43C3592.96,1140.25 3590.82,1142.17 3588.6,1141.73Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3619.48,1132.74C3619.74,1135.75 3617.5,1138.48 3614.48,1138.83C3611.47,1139.18 3608.81,1137.02 3608.55,1134C3608.29,1130.99 3610.53,1128.26 3613.55,1127.91C3616.56,1127.56 3619.22,1129.72 3619.48,1132.74Z"
          style="fill: rgb(254, 87, 60); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3593.72,1148.59C3591.56,1149.12 3589.54,1148.83 3587.6,1148.04C3587.6,1148.04 3584.81,1160.69 3589.37,1164.55L3597.88,1159.12C3597.88,1159.12 3593.44,1154.31 3593.72,1148.59Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3598.26,1158.9L3603.78,1167.51C3603.78,1167.51 3624.02,1161.57 3624.88,1151.59L3616.06,1147.59C3616.06,1147.59 3606.05,1159.73 3598.26,1158.9Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3612.02,1112.49L3617.65,1107.6C3617.94,1107.35 3617.97,1106.92 3617.72,1106.63C3617.47,1106.34 3617.03,1106.31 3616.75,1106.56L3611.12,1111.45C3610.83,1111.7 3610.8,1112.13 3611.05,1112.42C3611.3,1112.71 3611.73,1112.74 3612.02,1112.49Z"
          style="fill: rgb(78, 36, 17); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,567.404,100.53)">
        <path
          d="M3594.68,1293.26L3605.66,1328.86C3605.77,1329.22 3606.16,1329.43 3606.52,1329.31C3606.88,1329.2 3607.09,1328.82 3606.98,1328.45L3596,1292.85C3595.89,1292.49 3595.5,1292.29 3595.14,1292.4C3594.78,1292.51 3594.57,1292.9 3594.68,1293.26Z"
          style="fill: rgb(217, 173, 100); fill-rule: nonzero"
        />
      </g>
    </g>
    <g id="Vrouw" transform="matrix(1.3704,0,0,1.3704,-5334.17,-603.835)">
      <path
        d="M4952.11,1742.3C4929.29,1729.63 4890.11,1728.56 4864.61,1739.9C4860.26,1741.84 4856.6,1744.01 4853.61,1746.35C4837.49,1741.01 4815.59,1741.51 4800.48,1748.23C4782.56,1756.2 4781.03,1769.87 4797.06,1778.77C4812.47,1787.32 4838.47,1788.33 4856.38,1781.33C4857.44,1782.02 4858.55,1782.71 4859.74,1783.37C4882.56,1796.04 4921.74,1797.11 4947.24,1785.77C4972.75,1774.43 4974.93,1754.97 4952.11,1742.3Z"
        style="fill: rgb(233, 242, 225); fill-rule: nonzero"
      />
      <path
        d="M4897.6,1224.01C4897.6,1224.01 4908.64,1244.57 4927.52,1248.54C4951.25,1253.53 4982.67,1254.32 4974.66,1281.05C4966.65,1307.79 5007.41,1292.79 5007.06,1326.2C5006.71,1359.61 4988.33,1367.25 4988.33,1367.25C4988.33,1367.25 4997.76,1406.31 4959.58,1408.91C4921.4,1411.51 4913.86,1396.99 4913.86,1396.99C4913.86,1396.99 4873.27,1379.57 4880.37,1354.49C4882.86,1345.66 4862.78,1343.69 4866.68,1327.92C4873.25,1301.38 4841.12,1279.5 4852.11,1236L4861.8,1225.3L4897.6,1224.01Z"
        style="fill: rgb(86, 39, 19)"
      />
      <path
        d="M4677.99,1301.56C4676.72,1306.23 4678.76,1310.77 4682.53,1311.67L4697.46,1311.52C4701.23,1312.42 4704.97,1302.36 4698.2,1299.07L4687.48,1296.8C4686.09,1296.5 4684.65,1296.38 4683.21,1296.56C4680.55,1296.88 4678.99,1297.89 4677.99,1301.56Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4845.29,1269.69L4775.96,1305.87L4699.61,1299.55L4699.33,1314.52C4699.79,1318.83 4758.14,1342.81 4775.08,1347.7C4779.01,1348.84 4783.16,1348.81 4787.08,1347.64C4811.92,1340.26 4874.86,1305.7 4874.86,1305.7L4845.29,1269.69Z"
        style="fill: rgb(255, 206, 121); fill-rule: nonzero"
      />
      <path
        d="M4912.71,1747.1L4913.04,1763.03C4913.17,1764.7 4914.79,1773.51 4915.5,1775.03C4917.56,1779.42 4920.94,1781.19 4924.21,1781.41C4928.54,1781.71 4937.25,1774.06 4934.16,1764.55C4931.46,1756.26 4926.07,1743.03 4926.07,1743.03C4919.95,1745.83 4917.12,1747.11 4912.71,1747.1Z"
        style="fill: rgb(115, 55, 29); fill-rule: nonzero"
      />
      <path
        d="M4817.88,1761.37L4839.94,1735.2C4843.36,1733.37 4849.75,1732.63 4854.97,1733.49C4857.31,1733.88 4858.55,1735.41 4858.72,1737.75L4859.32,1742.09C4860.11,1749.36 4861.02,1751.75 4856.51,1754.17L4829.73,1768.56C4824.23,1772.9 4808.62,1770.89 4817.88,1761.37Z"
        style="fill: rgb(115, 55, 29); fill-rule: nonzero"
      />
      <path
        d="M4840.84,1737.84C4840.84,1737.84 4828.25,1753.42 4829.89,1754.5C4836.71,1759 4852.63,1744.2 4852.63,1744.2C4852.63,1744.2 4843.77,1737.03 4840.84,1737.84Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4852.63,1744.2C4846.47,1744.25 4841,1747.51 4840.27,1738.55C4840.43,1688.38 4829.2,1645.99 4829.32,1599.09C4829.45,1546.43 4833.31,1545.53 4833.64,1464.81L4882.58,1472.61C4873.53,1530.65 4869.69,1603.97 4869.69,1603.97C4882.63,1660.24 4862.07,1675.04 4852.63,1744.2Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4925.84,1747.55C4922.13,1756.45 4914.8,1758.96 4913.26,1750.1C4908.83,1700.13 4892.44,1659.13 4888.28,1612.41C4883.6,1559.96 4887.36,1558.71 4880.31,1478.3L4929.76,1481.59C4926.04,1540.22 4928.92,1613.58 4928.92,1613.58C4946.95,1668.44 4928.93,1677.81 4925.84,1747.55Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4832.71,1398.67C4832.71,1398.67 4799.31,1390.98 4804.25,1576.25C4804.25,1576.25 4838.97,1590.65 4871.25,1599.05C4901.68,1606.97 4921.45,1571.12 4946.07,1579.64C4946.07,1579.64 4930.85,1544.22 4932.89,1527.63C4934.93,1511.05 4945.1,1414.12 4917.85,1410.97C4890.59,1407.81 4832.71,1398.67 4832.71,1398.67Z"
        style="fill: rgb(255, 206, 121); fill-rule: nonzero"
      />
      <path
        d="M4853.38,1266.81C4855.83,1266.22 4858.35,1265.98 4860.88,1266.12C4872.53,1266.77 4888.71,1262.36 4889.91,1263.18L4911.68,1273.96C4953.44,1288.55 4953.94,1300.7 4942.58,1322.91C4942.58,1322.91 4917,1361.21 4915.86,1377.57C4914.88,1391.71 4926.69,1414.09 4928.92,1418.72C4931.81,1424.72 4911.36,1460.75 4857.04,1452.32C4814.27,1445.69 4819.85,1428.17 4821.68,1404.79C4822.84,1390.07 4833.4,1351.12 4833.64,1339.44C4833.89,1327.76 4825.3,1318.65 4832.28,1300.14C4836.01,1290.24 4838.33,1282.43 4839.69,1277.31C4840.83,1272.98 4844.13,1269.59 4848.42,1268.3L4853.38,1266.81Z"
        style="fill: rgb(255, 206, 121); fill-rule: nonzero"
      />
      <path
        d="M4880.6,1274.64L4880.08,1272.76C4876.68,1273.68 4872.82,1273.84 4872.09,1270.47L4868.01,1251.48C4867.29,1248.11 4869.48,1244.6 4872.88,1243.67L4873.89,1243.4C4877.29,1242.47 4882.65,1243.18 4883.37,1246.55L4888.55,1266.58C4889.28,1269.95 4884,1273.71 4880.6,1274.64Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4870.29,1261.63C4870.29,1261.63 4878.59,1260.88 4883.57,1252.33C4883.57,1252.33 4878.77,1264.89 4871.27,1266.78L4870.29,1261.63Z"
        style="fill: rgb(211, 79, 43); fill-rule: nonzero"
      />
      <path
        d="M4885.98,1221.54C4882.75,1216.47 4878.07,1215.64 4873.86,1218.64C4869.57,1216.9 4864.56,1219.61 4861.04,1226.36C4856.14,1235.75 4855.29,1250.54 4860.01,1257.94C4862.08,1261.17 4865.2,1261.83 4868.13,1261.85C4870.87,1261.87 4873.62,1261.28 4876.22,1260.25C4885.53,1256.54 4885.75,1257.93 4889.01,1251.67C4893.91,1242.28 4890.7,1228.95 4885.98,1221.54Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4880.08,1224.98C4883.96,1233.9 4885.54,1254.72 4886.18,1257.47C4886.18,1257.47 4905.14,1255.86 4901.45,1234.56C4896.85,1208.04 4882.41,1222.36 4882.41,1222.36L4880.08,1224.98Z"
        style="fill: rgb(86, 39, 19); fill-rule: nonzero"
      />
      <path
        d="M4886.75,1228.29C4876.16,1224.57 4867.42,1221.66 4852.11,1236C4847.38,1236.99 4849.97,1228.29 4856.89,1222.05C4863.35,1216.21 4866.84,1214.83 4874.35,1212.75L4876.18,1212.44C4887.25,1210.61 4893.38,1214.57 4896.33,1225.15C4896.59,1226.08 4898.73,1230.28 4898.83,1231.07L4895.2,1230.06C4896.05,1236.37 4893.09,1227.06 4886.75,1228.29Z"
        style="fill: rgb(86, 39, 19); fill-rule: nonzero"
      />
      <path
        d="M4865.36,1255.79C4863.3,1255.27 4861.63,1252.15 4861.63,1248.83L4869.07,1250.71C4869.08,1254.04 4867.42,1256.31 4865.36,1255.79Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4892.57,1247.43C4892.92,1250.41 4890.78,1253.1 4887.8,1253.45C4884.82,1253.79 4882.13,1251.65 4881.79,1248.68C4881.44,1245.7 4883.58,1243 4886.56,1242.66C4889.54,1242.32 4892.23,1244.45 4892.57,1247.43Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4925.04,1401.81C4919.93,1396.01 4916.52,1388.76 4916.18,1381.03C4916.14,1380.01 4916.15,1379.01 4916.21,1378.04C4916.86,1368.79 4932.57,1347.38 4930.58,1337.17C4916.32,1362.98 4911.02,1374.55 4894.74,1377.12C4900.89,1387.16 4904.31,1395.7 4906.01,1403.1C4912.33,1434.53 4913.22,1478.17 4916.33,1496.07C4926.34,1553.72 4899.78,1583.37 4881.21,1600.2C4906.22,1599.42 4925.78,1600.2 4946.07,1579.68C4946.07,1579.68 4944.03,1541.04 4946.07,1524.46C4947.87,1509.83 4944.56,1423.98 4925.04,1401.81Z"
        style="fill: rgb(240, 191, 112); fill-rule: nonzero"
      />
      <path
        d="M4870.5,1263.08L4863.75,1265.6C4863.75,1265.6 4862.26,1274.76 4866.89,1278.57L4875.17,1273.22C4875.17,1273.22 4872.31,1270.15 4871.07,1265.73C4870.82,1264.87 4870.7,1264.05 4870.5,1263.08Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4875.17,1273.22L4880.36,1281.42C4880.36,1281.42 4896.43,1275.2 4896.59,1265.63L4887.19,1261.3C4887.19,1261.3 4881.86,1273.94 4875.17,1273.22Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4913.26,1750.1C4913.26,1750.1 4916.15,1767.23 4923.33,1765.79C4930.51,1764.34 4925.84,1747.55 4925.84,1747.55L4913.26,1750.1Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4926.49,1457.07C4930.87,1456.91 4934.34,1453.83 4934.21,1450.23L4930.47,1436.98C4930.34,1433.38 4930.3,1430.74 4925.91,1430.9C4921.54,1431.06 4919.12,1433.25 4919.44,1439.77L4920.04,1449.94C4920.12,1451.26 4920.37,1452.58 4920.86,1453.81C4921.79,1456.09 4923.05,1457.19 4926.49,1457.07Z"
        style="fill: rgb(230, 87, 49); fill-rule: nonzero"
      />
      <path
        d="M4943.77,1291.35C4943.77,1291.35 4985.24,1327.08 4998.75,1367.05C5012.26,1407.02 4932.09,1442.17 4932.09,1442.17L4919.9,1433.46C4918.08,1432.16 4918.02,1429.37 4919.79,1427.86C4930.82,1418.46 4963.5,1389.72 4958.92,1382.8C4951.29,1371.29 4899.99,1321.28 4899.99,1321.28L4943.77,1291.35Z"
        style="fill: rgb(255, 206, 121); fill-rule: nonzero"
      />
      <path
        d="M4882.09,1225.81L4891.8,1220.16C4892.12,1219.97 4892.23,1219.56 4892.04,1219.24C4891.86,1218.92 4891.44,1218.81 4891.12,1218.99L4881.41,1224.65C4881.09,1224.83 4880.98,1225.25 4881.17,1225.57C4881.35,1225.89 4881.77,1226 4882.09,1225.81Z"
        style="fill: rgb(38, 14, 4); fill-rule: nonzero"
      />
      <path
        d="M4832.84,1326.49L4830.66,1317.53C4830.48,1316.79 4829.73,1316.34 4828.99,1316.52C4828.25,1316.7 4827.8,1317.44 4827.98,1318.18L4830.16,1327.14C4830.34,1327.88 4831.09,1328.33 4831.83,1328.15C4832.56,1327.97 4833.02,1327.23 4832.84,1326.49Z"
        style="fill: rgb(217, 173, 100); fill-rule: nonzero"
      />
      <path
        d="M4925.99,1342.35L4914.94,1328.37C4914.47,1327.78 4913.6,1327.68 4913,1328.15C4912.41,1328.62 4912.3,1329.49 4912.78,1330.08L4923.82,1344.06C4924.3,1344.66 4925.16,1344.76 4925.76,1344.29C4926.36,1343.82 4926.46,1342.95 4925.99,1342.35Z"
        style="fill: rgb(217, 173, 100); fill-rule: nonzero"
      />
      <path
        d="M4853.08,1236.01L4856.68,1233.55C4856.99,1233.34 4857.07,1232.92 4856.86,1232.61C4856.65,1232.3 4856.23,1232.22 4855.92,1232.43L4852.32,1234.89C4852.01,1235.1 4851.94,1235.52 4852.15,1235.83C4852.36,1236.14 4852.78,1236.22 4853.08,1236.01Z"
        style="fill: rgb(38, 14, 4); fill-rule: nonzero"
      />
    </g>
    <g id="Procent" transform="matrix(1.3704,0,0,1.3704,-570.849,-384.98)">
      <path
        d="M1301.51,1613.57C1301.51,1665.63 1194.19,1700.45 1061.79,1691.33C929.403,1682.21 822.078,1632.62 822.078,1580.56C822.078,1528.5 929.403,1493.68 1061.79,1502.8C1194.19,1511.92 1301.51,1561.51 1301.51,1613.57Z"
        style="fill: rgb(233, 242, 225); fill-rule: nonzero"
      />
      <path
        d="M1149.26,1303.62L1131.29,1300.77L1122.07,1295.11L1153.54,1283.22L1149.26,1303.62Z"
        style="fill: rgb(222, 168, 81); fill-rule: nonzero"
      />
      <path
        d="M1007.87,1599.56L976.173,1611.55L964.735,1602.04L996.391,1582.58L1007.87,1599.56Z"
        style="fill: rgb(222, 168, 81); fill-rule: nonzero"
      />
      <path
        d="M981.711,1292.59C961.671,1289.41 945.366,1304.67 945.366,1326.61C945.366,1348.55 961.671,1368.99 981.711,1372.18C1001.75,1375.36 1018.05,1360.1 1018.05,1338.15C1018.05,1316.21 1001.75,1295.77 981.711,1292.59ZM981.711,1411.32C941.958,1405.01 909.615,1364.46 909.615,1320.93C909.615,1277.41 941.958,1247.13 981.711,1253.44C1021.46,1259.76 1053.8,1300.31 1053.8,1343.83C1053.8,1387.36 1021.46,1417.64 981.711,1411.32Z"
        style="fill: rgb(222, 168, 81); fill-rule: nonzero"
      />
      <path
        d="M987.877,1594.78C977.49,1586.22 974.156,1570.73 980.469,1560.36L1144.51,1290.84C1150.83,1280.47 1164.49,1278.99 1174.88,1287.55C1185.26,1296.11 1188.6,1311.6 1182.28,1321.97L1018.24,1591.49C1011.93,1601.86 998.263,1603.34 987.877,1594.78Z"
        style="fill: rgb(222, 168, 81); fill-rule: nonzero"
      />
      <path
        d="M953.006,1305.53C932.966,1302.35 916.665,1317.61 916.665,1339.55C916.665,1361.49 932.966,1381.93 953.006,1385.12C973.046,1388.3 989.347,1373.04 989.347,1351.1C989.347,1329.15 973.046,1308.71 953.006,1305.53ZM953.006,1424.26C913.253,1417.95 880.914,1377.4 880.914,1333.88C880.914,1290.35 913.253,1260.07 953.006,1266.39C992.759,1272.7 1025.1,1313.25 1025.1,1356.77C1025.1,1400.3 992.759,1430.58 953.006,1424.26Z"
        style="fill: rgb(251, 199, 117); fill-rule: nonzero"
      />
      <path
        d="M1183.3,1510.08C1163.26,1506.89 1146.96,1522.16 1146.96,1544.1C1146.96,1566.04 1163.26,1586.48 1183.3,1589.66C1203.34,1592.84 1219.64,1577.58 1219.64,1555.64C1219.64,1533.7 1203.34,1513.26 1183.3,1510.08ZM1183.3,1628.81C1143.55,1622.49 1111.21,1581.95 1111.21,1538.42C1111.21,1494.89 1143.55,1464.62 1183.3,1470.93C1223.06,1477.24 1255.39,1517.79 1255.39,1561.32C1255.39,1604.84 1223.06,1635.12 1183.3,1628.81Z"
        style="fill: rgb(222, 168, 81); fill-rule: nonzero"
      />
      <path
        d="M1154.6,1523.02C1134.56,1519.83 1118.26,1535.1 1118.26,1557.04C1118.26,1578.98 1134.56,1599.42 1154.6,1602.6C1174.64,1605.78 1190.94,1590.52 1190.94,1568.58C1190.94,1546.64 1174.64,1526.2 1154.6,1523.02ZM1154.6,1641.75C1114.84,1635.44 1082.5,1594.89 1082.5,1551.36C1082.5,1507.83 1114.84,1477.56 1154.6,1483.87C1194.35,1490.18 1226.69,1530.73 1226.69,1574.26C1226.69,1617.78 1194.35,1648.06 1154.6,1641.75Z"
        style="fill: rgb(251, 199, 117); fill-rule: nonzero"
      />
      <path
        d="M955.188,1607.09C944.802,1598.53 941.468,1583.04 947.78,1572.67L1111.82,1303.15C1118.14,1292.78 1131.8,1291.3 1142.19,1299.86C1152.57,1308.42 1155.91,1323.91 1149.6,1334.28L985.55,1603.8C979.238,1614.17 965.575,1615.65 955.188,1607.09Z"
        style="fill: rgb(251, 199, 117); fill-rule: nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "HeroImg",
};
</script>

<style scoped></style>
