<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(1.3039,0,0,1.3039,-673.762,-462.974)"
      >
        <path
          d="M587.657,1104.31C512.065,879.747 550.444,549.028 891.813,410.401C1113.41,320.413 1219.31,450.574 1335.09,571.745C1430.85,671.956 1569.73,725.263 1718.08,716.572C1818.31,710.7 1966.65,742.614 2005.27,969.647C2065.07,1321.18 1737.84,1667.84 1102.02,1557.37C839.675,1511.79 666.485,1338.49 587.657,1104.31Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
    </g>
    <g id="Verwarming">
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1586.52,1046.74L1007.91,1046.74C996.753,1046.74 987.626,1037.62 987.626,1026.46C987.626,1015.31 996.753,1006.18 1007.91,1006.18L1586.52,1006.18C1597.68,1006.18 1606.8,1015.31 1606.8,1026.46C1606.8,1037.62 1597.68,1046.74 1586.52,1046.74Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1586.52,1399.38L1007.91,1399.38C996.753,1399.38 987.626,1390.25 987.626,1379.09C987.626,1367.94 996.753,1358.81 1007.91,1358.81L1586.52,1358.81C1597.68,1358.81 1606.8,1367.94 1606.8,1379.09C1606.8,1390.25 1597.68,1399.38 1586.52,1399.38Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1083.58,1472.9C1057.33,1472.9 1035.84,1451.42 1035.84,1425.16L1035.84,970.982C1035.84,944.726 1057.33,923.244 1083.58,923.244C1109.84,923.244 1131.32,944.726 1131.32,970.982L1131.32,1425.16C1131.32,1451.42 1109.84,1472.9 1083.58,1472.9Z"
          style="fill: rgb(101, 157, 69); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1221.35,1472.9C1195.1,1472.9 1173.61,1451.42 1173.61,1425.16L1173.61,970.982C1173.61,944.726 1195.1,923.244 1221.35,923.244C1247.61,923.244 1269.09,944.726 1269.09,970.983L1269.09,1425.16C1269.09,1451.42 1247.61,1472.9 1221.35,1472.9Z"
          style="fill: rgb(101, 157, 69); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1363.92,1472.9C1337.66,1472.9 1316.18,1451.42 1316.18,1425.16L1316.18,970.983C1316.18,944.726 1337.66,923.244 1363.92,923.244C1390.17,923.244 1411.66,944.726 1411.66,970.983L1411.66,1425.16C1411.66,1451.42 1390.17,1472.9 1363.92,1472.9Z"
          style="fill: rgb(101, 157, 69); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1505.09,1472.9C1478.83,1472.9 1457.35,1451.42 1457.35,1425.16L1457.35,970.983C1457.35,944.726 1478.83,923.244 1505.09,923.244C1531.34,923.244 1552.82,944.726 1552.82,970.983L1552.82,1425.16C1552.82,1451.42 1531.34,1472.9 1505.09,1472.9Z"
          style="fill: rgb(101, 157, 69); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M992.062,1416.94C980.74,1416.94 971.476,1407.67 971.476,1396.35L971.476,1361.84C971.476,1350.52 980.74,1341.25 992.062,1341.25C1003.38,1341.25 1012.65,1350.52 1012.65,1361.84L1012.65,1396.35C1012.65,1407.67 1003.38,1416.94 992.062,1416.94Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M992.062,1063.17C980.74,1063.17 971.476,1053.91 971.476,1042.58L971.476,1008.07C971.476,996.75 980.74,987.487 992.062,987.487C1003.38,987.487 1012.65,996.75 1012.65,1008.07L1012.65,1042.58C1012.65,1053.91 1003.38,1063.17 992.062,1063.17Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1599.85,1416.94C1588.53,1416.94 1579.26,1407.67 1579.26,1396.35L1579.26,1361.84C1579.26,1350.52 1588.53,1341.25 1599.85,1341.25C1611.17,1341.25 1620.44,1350.52 1620.44,1361.84L1620.44,1396.35C1620.44,1407.67 1611.17,1416.94 1599.85,1416.94Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1599.85,1065.8C1588.53,1065.8 1579.26,1056.54 1579.26,1045.22L1579.26,1010.7C1579.26,999.381 1588.53,990.117 1599.85,990.117C1611.17,990.117 1620.44,999.381 1620.44,1010.7L1620.44,1045.22C1620.44,1056.54 1611.17,1065.8 1599.85,1065.8Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1671.75,1055.15L1644.81,1055.15C1628.41,1055.15 1614.99,1041.73 1614.99,1025.33C1614.99,1008.93 1628.41,995.509 1644.81,995.509L1671.75,995.509C1688.15,995.509 1701.57,1008.93 1701.57,1025.33C1701.57,1041.73 1688.15,1055.15 1671.75,1055.15Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1620.44,1010.7L1620.44,1045.22"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1579.26,1361.84L1579.26,1396.35"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1012.65,1361.84L1012.65,1396.35"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1012.65,1009.21L1012.65,1043.72"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1066.7,719.141C1065.56,764.926 1111.34,755.769 1111.34,795.83C1111.34,835.892 1064.37,819.867 1066.68,890.833"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1199.08,719.141C1197.94,764.926 1243.72,755.769 1243.72,795.83C1243.72,835.892 1196.75,819.867 1199.06,890.833"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1331.47,719.141C1330.32,764.926 1376.11,755.769 1376.11,795.83C1376.11,835.892 1329.14,819.867 1331.45,890.833"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1463.32,719.141C1462.17,764.926 1507.96,755.769 1507.96,795.83C1507.96,835.892 1460.99,819.867 1463.3,890.833"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1328.66,1312.24L1248.48,1312.24C1226.76,1312.24 1208.98,1294.46 1208.98,1272.73L1208.98,1123.41C1208.98,1101.68 1226.76,1083.9 1248.48,1083.9L1328.66,1083.9C1350.39,1083.9 1368.17,1101.68 1368.17,1123.41L1368.17,1272.73C1368.17,1294.46 1350.39,1312.24 1328.66,1312.24Z"
          style="fill: rgb(166, 202, 141); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <rect
          x="1232.99"
          y="1114.74"
          width="111.17"
          height="57.01"
          style="fill: rgb(54, 92, 38); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1283.52,1204.55C1283.52,1209.7 1279.34,1213.88 1274.18,1213.88C1269.02,1213.88 1264.84,1209.7 1264.84,1204.55C1264.84,1199.39 1269.02,1195.21 1274.18,1195.21C1279.34,1195.21 1283.52,1199.39 1283.52,1204.55Z"
          style="fill: rgb(122, 159, 96); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1312.3,1204.55C1312.3,1209.7 1308.12,1213.88 1302.96,1213.88C1297.81,1213.88 1293.63,1209.7 1293.63,1204.55C1293.63,1199.39 1297.81,1195.21 1302.96,1195.21C1308.12,1195.21 1312.3,1199.39 1312.3,1204.55Z"
          style="fill: rgb(122, 159, 96); fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1252.65,1156.72C1253.34,1156.72 1253.96,1156.53 1254.52,1156.15C1255.07,1155.77 1255.55,1155.18 1255.96,1154.38C1256.37,1153.58 1256.68,1152.55 1256.9,1151.31C1257.12,1150.07 1257.23,1148.59 1257.23,1146.86C1257.23,1145.14 1257.12,1143.66 1256.9,1142.43C1256.68,1141.2 1256.37,1140.2 1255.96,1139.42C1255.55,1138.64 1255.07,1138.07 1254.52,1137.7C1253.96,1137.33 1253.34,1137.15 1252.65,1137.15C1251.96,1137.15 1251.34,1137.33 1250.77,1137.7C1250.2,1138.07 1249.72,1138.64 1249.31,1139.42C1248.91,1140.2 1248.59,1141.2 1248.37,1142.43C1248.15,1143.66 1248.04,1145.14 1248.04,1146.86C1248.04,1150.31 1248.46,1152.81 1249.31,1154.38C1250.16,1155.94 1251.27,1156.72 1252.65,1156.72ZM1252.65,1159.16C1250.27,1159.16 1248.41,1158.1 1247.08,1155.98C1245.75,1153.87 1245.08,1150.83 1245.08,1146.86C1245.08,1142.9 1245.75,1139.88 1247.08,1137.81C1248.41,1135.75 1250.27,1134.71 1252.65,1134.71C1255.02,1134.71 1256.86,1135.75 1258.19,1137.81C1259.52,1139.88 1260.19,1142.9 1260.19,1146.86C1260.19,1150.83 1259.52,1153.87 1258.19,1155.98C1256.86,1158.1 1255.02,1159.16 1252.65,1159.16Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1271.01,1156.72C1271.7,1156.72 1272.32,1156.53 1272.87,1156.15C1273.43,1155.77 1273.91,1155.18 1274.31,1154.38C1274.72,1153.58 1275.03,1152.55 1275.26,1151.31C1275.48,1150.07 1275.59,1148.59 1275.59,1146.86C1275.59,1145.14 1275.48,1143.66 1275.26,1142.43C1275.03,1141.2 1274.72,1140.2 1274.31,1139.42C1273.91,1138.64 1273.43,1138.07 1272.87,1137.7C1272.32,1137.33 1271.7,1137.15 1271.01,1137.15C1270.32,1137.15 1269.69,1137.33 1269.13,1137.7C1268.56,1138.07 1268.07,1138.64 1267.67,1139.42C1267.26,1140.2 1266.95,1141.2 1266.72,1142.43C1266.5,1143.66 1266.39,1145.14 1266.39,1146.86C1266.39,1150.31 1266.82,1152.81 1267.67,1154.38C1268.52,1155.94 1269.63,1156.72 1271.01,1156.72ZM1271.01,1159.16C1268.62,1159.16 1266.76,1158.1 1265.43,1155.98C1264.1,1153.87 1263.44,1150.83 1263.44,1146.86C1263.44,1142.9 1264.1,1139.88 1265.43,1137.81C1266.76,1135.75 1268.62,1134.71 1271.01,1134.71C1273.37,1134.71 1275.22,1135.75 1276.55,1137.81C1277.88,1139.88 1278.54,1142.9 1278.54,1146.86C1278.54,1150.83 1277.88,1153.87 1276.55,1155.98C1275.22,1158.1 1273.37,1159.16 1271.01,1159.16Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1283.08,1156.2L1288.48,1156.2L1288.48,1138.85L1284.19,1138.85L1284.19,1136.89C1285.28,1136.69 1286.22,1136.45 1287.02,1136.17C1287.82,1135.89 1288.54,1135.55 1289.18,1135.15L1291.5,1135.15L1291.5,1156.2L1296.38,1156.2L1296.38,1158.72L1283.08,1158.72L1283.08,1156.2Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1307.72,1156.72C1308.41,1156.72 1309.03,1156.53 1309.58,1156.15C1310.14,1155.77 1310.62,1155.18 1311.02,1154.38C1311.43,1153.58 1311.74,1152.55 1311.96,1151.31C1312.19,1150.07 1312.3,1148.59 1312.3,1146.86C1312.3,1145.14 1312.19,1143.66 1311.96,1142.43C1311.74,1141.2 1311.43,1140.2 1311.02,1139.42C1310.62,1138.64 1310.14,1138.07 1309.58,1137.7C1309.03,1137.33 1308.41,1137.15 1307.72,1137.15C1307.03,1137.15 1306.4,1137.33 1305.83,1137.7C1305.27,1138.07 1304.78,1138.64 1304.38,1139.42C1303.97,1140.2 1303.66,1141.2 1303.43,1142.43C1303.21,1143.66 1303.1,1145.14 1303.1,1146.86C1303.1,1150.31 1303.53,1152.81 1304.38,1154.38C1305.22,1155.94 1306.34,1156.72 1307.72,1156.72ZM1307.72,1159.16C1305.33,1159.16 1303.47,1158.1 1302.14,1155.98C1300.81,1153.87 1300.15,1150.83 1300.15,1146.86C1300.15,1142.9 1300.81,1139.88 1302.14,1137.81C1303.47,1135.75 1305.33,1134.71 1307.72,1134.71C1310.08,1134.71 1311.93,1135.75 1313.26,1137.81C1314.59,1139.88 1315.25,1142.9 1315.25,1146.86C1315.25,1150.83 1314.59,1153.87 1313.26,1155.98C1311.93,1158.1 1310.08,1159.16 1307.72,1159.16Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1318.35,1156.91C1320.13,1155.13 1321.7,1153.54 1323.08,1152.11C1324.46,1150.68 1325.62,1149.36 1326.55,1148.14C1327.49,1146.92 1328.2,1145.8 1328.69,1144.78C1329.19,1143.75 1329.43,1142.76 1329.43,1141.8C1329.43,1140.45 1329.06,1139.34 1328.33,1138.48C1327.59,1137.62 1326.47,1137.19 1324.96,1137.19C1323.98,1137.19 1323.07,1137.46 1322.23,1138.02C1321.39,1138.57 1320.63,1139.24 1319.94,1140.03L1318.21,1138.29C1319.19,1137.21 1320.24,1136.34 1321.34,1135.69C1322.45,1135.04 1323.78,1134.71 1325.33,1134.71C1327.52,1134.71 1329.25,1135.35 1330.5,1136.61C1331.76,1137.88 1332.39,1139.56 1332.39,1141.65C1332.39,1142.76 1332.15,1143.88 1331.67,1145C1331.19,1146.12 1330.52,1147.27 1329.67,1148.47C1328.82,1149.66 1327.81,1150.91 1326.64,1152.2C1325.47,1153.49 1324.19,1154.86 1322.79,1156.32C1323.42,1156.27 1324.09,1156.22 1324.78,1156.17C1325.47,1156.12 1326.12,1156.09 1326.74,1156.09L1333.57,1156.09L1333.57,1158.72L1318.35,1158.72L1318.35,1156.91Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1233.87,1183.36L1233.87,1274.93L1344.16,1274.93L1344.16,1184.31"
          style="fill: none; stroke: rgb(40, 89, 42); stroke-width: 1.68px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1642.12,1004.51L1642.12,1048.01"
          style="fill: none; stroke: white; stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1658.28,1004.51L1658.28,1048.01"
          style="fill: none; stroke: white; stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,158.413,157.906)">
        <path
          d="M1674.44,1004.51L1674.44,1048.01"
          style="fill: none; stroke: white; stroke-width: 3.65px"
        />
      </g>
      <g transform="matrix(0.860488,0,0,0.860488,152.76,129.245)">
        <path
          d="M1687.36,1631.81C1687.36,1652.91 1515.62,1670.02 1303.78,1670.02C1091.94,1670.02 920.204,1652.91 920.204,1631.81C920.204,1610.71 1091.94,1593.6 1303.78,1593.6C1515.62,1593.6 1687.36,1610.71 1687.36,1631.81Z"
          style="fill: rgb(215, 233, 204)"
        />
      </g>
    </g>
    <g transform="matrix(0.860488,0,0,0.860488,162.433,294.53)">
      <path
        d="M1749.66,1208.05C1749.66,1214.82 1744.17,1220.32 1737.39,1220.32C1730.61,1220.32 1725.12,1214.82 1725.12,1208.05C1725.12,1201.27 1730.61,1195.78 1737.39,1195.78C1744.17,1195.78 1749.66,1201.27 1749.66,1208.05Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.2px"
      />
    </g>
    <g transform="matrix(1.3039,0,0,1.3039,-673.762,-462.974)">
      <path
        d="M970.93,623.69C970.93,630.467 965.436,635.961 958.659,635.961C951.882,635.961 946.388,630.467 946.388,623.69C946.388,616.913 951.882,611.42 958.659,611.42C965.436,611.42 970.93,616.913 970.93,623.69Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 2.11px"
      />
    </g>
    <g transform="matrix(1.3039,0,0,1.3039,-673.762,-462.974)">
      <path
        d="M737.85,1139.03C737.85,1145.81 732.356,1151.3 725.58,1151.3C718.803,1151.3 713.309,1145.81 713.309,1139.03C713.309,1132.25 718.803,1126.76 725.58,1126.76C732.356,1126.76 737.85,1132.25 737.85,1139.03Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 2.11px"
      />
    </g>
    <g id="Icoon" transform="matrix(1.64158,0,0,1.64158,-2039.03,-54.6019)">
      <g transform="matrix(1,0,0,1,-3383.51,-127.709)">
        <path
          d="M5206.2,857.513C5206.2,923.316 5152.86,976.66 5087.05,976.66C5021.25,976.66 4967.91,923.316 4967.91,857.513C4967.91,791.711 5021.25,738.367 5087.05,738.367C5152.86,738.367 5206.2,791.71 5206.2,857.513Z"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
      <g transform="matrix(1,0,0,1,-3383.51,-127.709)">
        <path
          d="M5092.21,978.044C5036.06,981.213 4983.84,944.099 4969.93,887.505"
          style="fill: none; stroke: rgb(28, 67, 31); stroke-width: 1.55px"
        />
      </g>
      <g transform="matrix(1.01856,0,0,1.11574,1637.34,642.777)">
        <path
          d="M0,100.8C0,86 4,74 10.4,64.8C10.4,83 14.8,89.4 24.4,89.4C30.8,89.4 37.4,84.6 37.4,76.4C37.4,69 32.4,55.6 32.4,41.2C32.4,26.8 37.2,11.4 56.8,0C52.4,11.6 50.6,21.4 50.6,29.4C50.6,50.6 63.4,60.2 74.8,60.2C84,60.2 93.6,54.2 93.6,36.8C99.8,43.6 102.6,51.4 102.6,59.2C102.6,71.6 95.8,82.6 95.8,92.6C95.8,100.8 102.4,105.6 108.8,105.6C120.2,105.6 123.6,95.4 123.6,78.6C127,84.4 130,95.8 130,105.6C130,134.8 102.4,156 71.6,156C97.6,146.2 102.4,133.2 102.4,118.6C96,130 89.4,131.6 86.2,131.6C66.6,131.6 87.8,94.2 53.6,68.2C59.2,80.2 61.4,90.6 61.4,99C61.4,117 51.6,126.8 42.2,126.8C34.2,126.8 26.2,120.4 23,106.8C23,125.8 28.6,143.8 53.6,156C14.6,149.6 -0,125.2 -0,100.8Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(4.8519,0,0,4.8519,-4181.27,-1429.8)">
      <text
        x="1100px"
        y="420px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 40px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getGas() }} m³
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PaperUsage",
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  created() {},
  methods: {
    getGas() {
      let value = this.getInfoByActiveYear.gas.gas;
      return value;
    },
  },
};
</script>
