<template>
  <h3>
    In- en Uitstroom&nbsp;
    <span class="badge rounded-pill bg-primary">+{{ getInOutFlow("in") }}</span
    >&nbsp;
    <span class="badge rounded-pill bg-danger">-{{ getInOutFlow("out") }}</span>
  </h3>
</template>

<script>
export default {
  name: "InOutFlowInfo",
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  methods: {
    getInOutFlow(direction) {
      let value = 0;
      if (this.getInfoByActiveYear) {
        if (direction == "in") {
          value = this.getInfoByActiveYear.inflow_outflow.inflow;
        } else if (direction == "out") {
          value = this.getInfoByActiveYear.inflow_outflow.outflow;
        }
      }
      return value;
    },
  },
};
</script>
