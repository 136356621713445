<template>
  <section class="people-section">
    <div class="border-bottom">
      <div class="container nav-container pt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">|</li>
            <li class="breadcrumb-item active" aria-current="page">Planet</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="container header-container p-5">
      <div class="row pb-4">
        <div class="col-md-7">
          <h1>{{ getPillarContent.header }}</h1>
          <p class="lead" v-html="getPillarContent.main"></p>
        </div>
        <div class="col-md-5">
          <PlanetImg />
        </div>
      </div>
    </div>
    <div class="action-row">
      <div class="container p-5">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <h3>Acties en Maatregelen</h3>
            <div class="row row-cols-1 row-cols-md-1 g-2">
              <div
                class="col"
                v-for="actie in getPillarContent.actions"
                v-bind:key="actie"
              >
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text">
                      {{ actie }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-5">
      <div class="row">
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2022 ? 'active' : '']"
              aria-current="page"
              @click="setYear(2022)"
              >2022</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2023 ? 'active' : '']"
              @click="setYear(2023)"
              >2023</a
            >
          </li>
        </ul>
      </div>

      <div class="row pt-5">
        <div class="col-md-4">
          <h3 class="overlay">Papierverbruik</h3>
          <div class="ps-2 pe-2"><PaperUsage /></div>
        </div>
        <div class="col-md-4">
          <h3 class="overlay">Gas</h3>
          <div class="ps-2 pe-2"><GasInfo /></div>
        </div>
        <div class="col-md-4">
          <h3 class="overlay">Electriciteit</h3>
          <div class="ps-2 pe-2"><ElectricityInfo /></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlanetImg from "@/components/PlanetImg.vue";
import PaperUsage from "@/components/PaperUsage.vue";
import GasInfo from "@/components/GasInfo.vue";
import ElectricityInfo from "@/components/ElectricityInfo.vue";

export default {
  name: "PeopleSection",
  components: {
    PlanetImg,
    PaperUsage,
    GasInfo,
    ElectricityInfo,
  },
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getPillarContent() {
      let pillars = this.$store.getters.getPillars;
      let result = pillars.filter((obj) => obj.pillar == "planet");
      if (result) {
        return result[0];
      }
      return null;
    },
  },
  created() {
    this.$store.dispatch("fetchInfoValues");
    this.$store.dispatch("fetchPillars");
    this.$store.commit("setActiveYear", 0);
    this.$store.commit("setActiveYear", 2023);
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
  },
};
</script>

<style scoped>
.header-container {
  padding: 2em;
}
.action-row {
  background-color: #f5f9f0;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.active {
  font-weight: 500;
}
.nav-link {
  cursor: pointer;
}
</style>
