<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(1.88192,0,0,1.88192,-6347.84,-341.579)"
      >
        <path
          d="M4366.32,548.441C4417.7,673.197 4468.99,962.977 4226.36,1012.33C4034.4,1051.37 4027.13,842.409 3700.99,829.162C3700.99,829.162 3469.81,845.832 3406.21,597.289C3358.7,411.617 3572.9,168.937 3912.42,213.413C4141.97,243.483 4290.39,364.082 4366.32,548.441Z"
          style="fill: rgb(245, 249, 241); fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6617.31,-191.023)">
      <path
        d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6375.02,227.566)">
      <path
        d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6772.07,-314.876)">
      <path
        d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.42603,0,0,1.41268,-659.357,-580.661)">
      <rect
        x="873.912"
        y="677.374"
        width="842.042"
        height="110.492"
        style="fill: rgb(233, 174, 95)"
      />
    </g>
    <g transform="matrix(1.42603,0,0,1.41268,-659.357,-190.614)">
      <rect
        x="873.912"
        y="677.374"
        width="842.042"
        height="110.492"
        style="fill: rgb(233, 174, 95)"
      />
    </g>
    <g transform="matrix(1.42603,0,0,1.41268,-659.357,199.432)">
      <rect
        x="873.912"
        y="677.374"
        width="842.042"
        height="110.492"
        style="fill: rgb(233, 174, 95)"
      />
    </g>
    <g transform="matrix(1.41268,0,0,1.41268,-785.246,-488.469)">
      <g transform="matrix(1.00945,0,0,1,69.114,-84.2605)">
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
      <g transform="matrix(1.00945,0,0,1,69.114,191.844)">
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
      <g transform="matrix(1.00945,0,0,1,69.114,467.949)">
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
    </g>
    <g id="ThirdBar" transform="matrix(1.42603,0,0,1.41268,-687.611,-607.502)">
      <rect
        x="873.912"
        y="677.374"
        :width="getWidth(lowerthan35)"
        height="110.492"
        style="fill: rgb(101, 157, 69)"
      />
    </g>
    <g id="SecondBar" transform="matrix(1.42603,0,0,1.41268,-687.611,-217.455)">
      <rect
        x="873.912"
        y="677.374"
        :width="getWidth(between35and44)"
        height="110.492"
        style="fill: rgb(101, 157, 69)"
      />
    </g>
    <g id="FirstBar" transform="matrix(1.42603,0,0,1.41268,-687.611,172.591)">
      <rect
        x="873.912"
        y="677.374"
        :width="getWidth(biggerthan45)"
        height="110.492"
        style="fill: rgb(101, 157, 69)"
      />
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-616.951,-695.758)">
      <g transform="matrix(50,0,0,50,1038.88,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        &lt; 35
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1590.89,-553.173)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        v-if="!loading"
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: white;
        "
      >
        {{ lowerthan35 }}%
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1590.89,-169.29)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: white;
        "
      >
        {{ between35and44 }}%
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1590.89,226.92)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: white;
        "
      >
        {{ biggerthan45 }}%
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-628.345,82.3482)">
      <g transform="matrix(50,0,0,50,1043.43,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        45 &gt;
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-781.082,-306.705)">
      <g transform="matrix(50,0,0,50,1109.28,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        35 - 44
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AgeStructure",
  data: () => ({
    lowerthan35: 0,
    between35and44: 0,
    biggerthan45: 0,
  }),
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  created() {
    this.animateValues();
  },
  methods: {
    getAge(ageGroup) {
      let value = 0;
      if (this.getInfoByActiveYear) {
        if (ageGroup == "lowerthan35") {
          value = this.getInfoByActiveYear.age.lowerthan35;
        } else if (ageGroup == "between35and44") {
          value = this.getInfoByActiveYear.age.between35and44;
        } else if (ageGroup == "biggerthan45") {
          value = this.getInfoByActiveYear.age.biggerthan45;
        }
      }
      return value;
    },
    getWidth(percent) {
      let width = (842 / 100) * percent;
      return width;
    },
    animateValues() {
      clearInterval(this.interval);
      if (
        this.getAge("lowerthan35") !== this.lowerthan35 ||
        this.getAge("between35and44") !== this.between35and44 ||
        this.getAge("biggerthan45") !== this.biggerthan45
      ) {
        let newLowerthan35 = this.getAge("lowerthan35");
        let newBetween35and44 = this.getAge("between35and44");
        let newBiggerthan45 = this.getAge("biggerthan45");

        this.interval = setInterval(() => {
          if (this.lowerthan35 < newLowerthan35) {
            this.lowerthan35++;
          } else if (this.lowerthan35 > newLowerthan35) {
            this.lowerthan35--;
          }

          if (this.between35and44 < newBetween35and44) {
            this.between35and44++;
          } else if (this.between35and44 > newBetween35and44) {
            this.between35and44--;
          }

          if (this.biggerthan45 < newBiggerthan45) {
            this.biggerthan45++;
          } else if (this.biggerthan45 > newBiggerthan45) {
            this.biggerthan45--;
          }
        }, 30);
      }
    },
  },
  watch: {
    getActiveYear() {
      this.animateValues();
    },
  },
};
</script>
