<template>
  <h3>
    Bedrijfsongevallen&nbsp;
    <span v-if="getAccidents() < 1" class="badge rounded-pill bg-primary">{{
      getAccidents()
    }}</span
    >&nbsp;
    <span v-if="getAccidents() > 0" class="badge rounded-pill bg-danger">{{
      getAccidents()
    }}</span>
  </h3>
</template>

<script>
export default {
  name: "AccidentsInfo",
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  methods: {
    getAccidents() {
      let value = 0;
      if (this.getInfoByActiveYear) {
        value = this.getInfoByActiveYear.accidents.accidents;
      }
      return value;
    },
  },
};
</script>
