import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    activeYear: 0,
    infoValues: [],
    pillars: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getActiveYear(state) {
      return state.activeYear;
    },
    getInfoValues(state) {
      return state.infoValues;
    },
    getInfovaluesByActiveYear(state) {
      let result = state.infoValues.filter(
        (obj) => obj.year == state.activeYear
      );
      if (result) {
        return result[0];
      }
      return null;
    },
    getPillars(state) {
      return state.pillars;
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setActiveYear(state, value) {
      state.activeYear = value;
    },
    setInfoValues(state, value) {
      state.infoValues = value;
    },
    setPillars(state, value) {
      state.pillars = value;
    },
  },
  actions: {
    fetchInfoValues(state) {
      state.commit("setLoading", true);
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/items/infoinnumbers", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          state.commit("setInfoValues", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchPillars(state) {
      state.commit("setLoading", true);
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/items/pillars", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          state.commit("setPillars", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
