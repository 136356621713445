<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(-1.88192,0,0,1.88192,8347.84,-353.05)"
      >
        <path
          d="M4366.32,548.441C4417.7,673.197 4468.99,962.977 4226.36,1012.33C4034.4,1051.37 4027.13,842.409 3700.99,829.162C3700.99,829.162 3469.81,845.832 3406.21,597.289C3358.7,411.617 3572.9,168.937 3912.42,213.413C4141.97,243.483 4290.39,364.082 4366.32,548.441Z"
          style="fill: rgb(245, 249, 241); fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(0.766092,-1.32691,1.56438,0.903195,-765.694,1828.56)">
      <path
        d="M1715.95,732.62C1715.95,763.111 1686.77,787.866 1650.82,787.866L939.045,787.866C903.097,787.866 873.912,763.111 873.912,732.62C873.912,702.129 903.097,677.374 939.045,677.374L1650.82,677.374C1686.77,677.374 1715.95,702.129 1715.95,732.62Z"
        style="fill: rgb(253, 204, 121)"
      />
    </g>
    <g transform="matrix(1.30232,0,0,1.30232,261.288,-321.256)">
      <circle
        cx="616.539"
        cy="1250.33"
        r="160.327"
        style="fill: rgb(253, 204, 121)"
      />
    </g>
    <g transform="matrix(0.888645,0.513059,-0.513059,0.888645,1159.3,-120.008)">
      <circle
        cx="616.539"
        cy="1250.33"
        r="160.327"
        style="fill: rgb(255, 93, 0)"
      />
    </g>
    <g
      transform="matrix(0.469868,-0.813834,0.982815,0.567429,-64.9627,1602.92)"
    >
      <path
        d="M1715.95,732.62C1715.95,763.111 1686.06,787.866 1649.24,787.866L940.629,787.866C903.807,787.866 873.912,763.111 873.912,732.62C873.912,702.129 903.807,677.374 940.629,677.374L1649.24,677.374C1686.06,677.374 1715.95,702.129 1715.95,732.62Z"
        style="fill: rgb(255, 93, 0)"
      />
    </g>
    <g transform="matrix(5.10909,0,0,5.10909,-4388.65,-1541.66)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: rgb(101, 157, 69);
        "
      >
        {{ getSickLeave() }}%
      </text>
    </g>
    <!-- <g transform="matrix(1,0,0,1,-47.4212,77.4064)">
      <g transform="matrix(0.999968,0,0,1,0.00239617,0)">
        <g transform="matrix(174.57,0,0,174.57,1275.26,92.1869)"></g>
        <text
          x="74.042px"
          y="92.187px"
          style="
            font-family: 'Lexend-Light', 'Lexend';
            font-size: 174.57px;
            fill: rgb(67, 28, 8);
          "
        >
          Ziekteverzuim
        </text>
      </g>
    </g> -->
    <g>
      <g transform="matrix(1.88192,0,0,1.88192,-6324.83,-789.812)">
        <path
          d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
      <g transform="matrix(1.88192,0,0,1.88192,-6124.34,541.605)">
        <path
          d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
      <g transform="matrix(1.88192,0,0,1.88192,-7111.9,-147.332)">
        <path
          d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
    </g>
    <g
      transform="matrix(-0.948214,0.317632,-0.317632,-0.948214,2337.05,1177.59)"
    >
      <g transform="matrix(1.88192,0,0,1.88192,-6872.6,-817.526)">
        <path
          d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
      <g transform="matrix(1.88192,0,0,1.88192,-5858.39,269.593)">
        <path
          d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
      <g transform="matrix(1.88192,0,0,1.88192,-5721.3,342.897)">
        <path
          d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
          style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
        />
      </g>
    </g>
    <g transform="matrix(1,0,0,1,24.9801,-35.5625)">
      <g transform="matrix(1,-0,-0,1,-24.9801,35.5625)">
        <path
          d="M1260.8,622.265L1279.55,634.765L1260.8,647.265"
          style="
            fill: none;
            stroke: rgb(101, 157, 69);
            stroke-width: 8.33px;
            stroke-miterlimit: 10;
          "
        />
        <path
          d="M839.206,634.765L1279.55,634.765"
          style="
            fill: none;
            stroke: rgb(101, 157, 69);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
    </g>
    <g transform="matrix(1.52874,0,0,1,-781.309,0)">
      <path
        d="M1343.81,634.765L1583.75,634.765"
        style="
          fill: none;
          stroke: rgb(101, 157, 69);
          stroke-width: 6.45px;
          stroke-linejoin: round;
          stroke-miterlimit: 1.5;
          stroke-dasharray: 6.45, 19.35, 0, 0, 0, 0;
        "
      />
    </g>
    <g transform="matrix(0.866025,0.5,-0.5,0.866025,1736.5,-282.149)">
      <path
        d="M153.834,1472.52L265.588,1472.52"
        style="
          fill: none;
          stroke: rgb(92, 41, 21);
          stroke-width: 8.33px;
          stroke-linejoin: round;
          stroke-miterlimit: 1.5;
        "
      />
      <g transform="matrix(0.5,0,0,1,76.9168,-32.1702)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-64.3403)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-96.5105)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,3.55271e-15,-128.681)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-160.851)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-193.021)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-225.191)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-257.361)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-289.531)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-321.702)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-353.872)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-386.042)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-418.212)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-450.382)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-482.552)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-514.723)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-546.893)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-579.063)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-611.233)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-643.403)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-675.573)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-707.744)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-739.914)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-772.084)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-804.254)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-836.424)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(0.5,0,0,1,76.9168,-868.594)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 10.54px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
      <g transform="matrix(1,0,0,1,-5.68434e-14,-900.765)">
        <path
          d="M153.834,1472.52L265.588,1472.52"
          style="
            fill: none;
            stroke: rgb(92, 41, 21);
            stroke-width: 8.33px;
            stroke-linejoin: round;
            stroke-miterlimit: 1.5;
          "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SickLeaveInfo",
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  methods: {
    getSickLeave() {
      let value = 0;
      if (this.getInfoByActiveYear) {
        value = this.getInfoByActiveYear.sickleave.sickleave;
      }
      return value;
    },
  },
};
</script>
