<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-miterlimit: 2;
    "
  >
    <g class="swing">
      <g
        id="Achtergrond"
        transform="matrix(-1.88192,0,0,-1.88192,8347.84,1952.78)"
      >
        <path
          d="M4366.32,548.441C4417.7,673.197 4468.99,962.977 4226.36,1012.33C4034.4,1051.37 4027.13,842.409 3700.99,829.162C3700.99,829.162 3469.81,845.832 3406.21,597.289C3358.7,411.617 3572.9,168.937 3912.42,213.413C4141.97,243.483 4290.39,364.082 4366.32,548.441Z"
          style="fill: rgb(245, 249, 241); fill-rule: nonzero"
        />
      </g>
    </g>
    <g transform="matrix(1.3459,0,0,1.00539,-583.305,-1.41234)">
      <g
        transform="matrix(7.82479e-17,-1.27788,2.48216,1.51989e-16,-704.366,2473.07)"
      >
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(233, 174, 95)"
        />
      </g>
      <g
        transform="matrix(7.82479e-17,-1.27788,2.48216,1.51989e-16,-728.498,2456.47)"
      >
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
    </g>
    <g transform="matrix(1.3459,0,0,1.00539,-583.305,-1.41234)">
      <g
        transform="matrix(7.82479e-17,-1.27788,2.48216,1.51989e-16,-269.263,2471.6)"
      >
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(233, 174, 95)"
        />
      </g>
      <g
        transform="matrix(7.82479e-17,-1.27788,2.48216,1.51989e-16,-293.396,2455)"
      >
        <rect
          x="873.912"
          y="677.374"
          width="842.042"
          height="110.492"
          style="fill: rgb(253, 204, 121)"
        />
      </g>
    </g>
    <g
      transform="matrix(5.26568e-17,-0.638942,3.34074,1.51989e-16,-1563.79,1903.9)"
    >
      <rect
        x="873.912"
        y="677.374"
        :width="getHeight(female)"
        height="110.492"
        style="fill: rgb(101, 157, 69)"
      />
    </g>
    <g
      transform="matrix(5.26568e-17,-0.638942,3.34074,1.51989e-16,-978.186,1902.42)"
    >
      <rect
        x="873.912"
        y="677.374"
        :width="getHeight(male)"
        height="110.492"
        style="fill: rgb(101, 157, 69)"
      />
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-786.499,280.99)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: white;
        "
      >
        {{ male }}%
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1372.1,288.744)">
      <g transform="matrix(36.2006,0,0,36.2006,1014.02,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 36.201px;
          fill: white;
        "
      >
        {{ female }}%
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-1446.73,479.298)">
      <g transform="matrix(50,0,0,50,1093.28,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Vrouw
      </text>
    </g>
    <g transform="matrix(2.30199,0,0,2.30199,-825.564,479.298)">
      <g transform="matrix(50,0,0,50,1047.43,438.662)"></g>
      <text
        x="944.734px"
        y="438.662px"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 50px;
          fill: rgb(29, 89, 28);
        "
      >
        Man
      </text>
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6280.51,-616.674)">
      <path
        d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-6115.87,397.846)">
      <path
        d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
    <g transform="matrix(1.88192,0,0,1.88192,-7111.9,-147.332)">
      <path
        d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
        style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GenderStructure",
  data: () => ({
    male: 0,
    female: 0,
  }),
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  created() {
    this.animateValues();
  },
  methods: {
    getGender(gender) {
      let value = 0;
      if (this.getInfoByActiveYear) {
        if (gender == "male") {
          value = this.getInfoByActiveYear.sex.male;
        } else if (gender == "female") {
          value = this.getInfoByActiveYear.sex.female;
        }
      }
      return value;
    },
    getHeight(percent) {
      let width = (1684 / 100) * percent;
      return width;
    },
    animateValues() {
      clearInterval(this.interval);
      if (
        this.getGender("male") !== this.male ||
        this.getGender("female") !== this.female
      ) {
        let newMale = this.getGender("male");
        let newFemale = this.getGender("female");

        this.interval = setInterval(() => {
          if (this.male < newMale) {
            this.male++;
          } else if (this.male > newMale) {
            this.male--;
          }

          if (this.female < newFemale) {
            this.female++;
          } else if (this.female > newFemale) {
            this.female--;
          }
        }, 30);
      }
    },
  },
  watch: {
    getActiveYear() {
      this.animateValues();
    },
  },
};
</script>

<style scoped></style>
