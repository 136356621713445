<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1620"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1.15284,0,0,1.15284,-4532.65,-370.759)">
      <path
        d="M5417.16,611.442C5608.48,812.246 5740.25,1172.41 5469.59,1463.4C5293.89,1652.29 5065.03,1558.21 4810.46,1528.08C4477.72,1488.7 4221.72,1580.86 4066.17,1361.58C3825.32,1022.04 4081.18,501.146 4609.74,396.279C4898.46,338.998 5217.64,402.041 5417.16,611.442Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(0.710236,0,0,0.710236,-1994.2,353.201)">
      <path
        d="M5240.42,1645.65C5240.42,1672.94 5012.93,1695.07 4732.31,1695.07C4451.7,1695.07 4224.21,1672.94 4224.21,1645.65C4224.21,1618.36 4451.7,1596.24 4732.31,1596.24C5012.93,1596.24 5240.42,1618.36 5240.42,1645.65Z"
        style="fill: white"
      />
      <path
        d="M4717.21,1392.6L4717.21,1633.61"
        style="fill: none; stroke: rgb(177, 195, 167); stroke-width: 34.65px"
      />
      <path
        d="M4834.93,1392.6L4834.93,1633.61"
        style="fill: none; stroke: rgb(177, 195, 167); stroke-width: 34.65px"
      />
      <path
        d="M4717.21,1418.24C4695.7,1418.24 4678.1,1400.64 4678.1,1379.13L4678.1,1277.94C4678.1,1256.42 4695.7,1238.82 4717.21,1238.82C4738.73,1238.82 4756.33,1256.42 4756.33,1277.94L4756.33,1379.13C4756.33,1400.64 4738.73,1418.24 4717.21,1418.24Z"
        style="fill: rgb(75, 123, 54); fill-rule: nonzero"
      />
      <path
        d="M4834.93,1418.24C4813.42,1418.24 4795.81,1400.64 4795.81,1379.13L4795.81,1277.94C4795.81,1256.42 4813.42,1238.82 4834.93,1238.82C4856.45,1238.82 4874.05,1256.42 4874.05,1277.94L4874.05,1379.13C4874.05,1400.64 4856.45,1418.24 4834.93,1418.24Z"
        style="fill: rgb(75, 123, 54); fill-rule: nonzero"
      />
      <path
        d="M4614.12,918.558C4614.12,918.558 4589.72,648.939 4379.59,566.846L4532.3,910.523C4532.3,910.523 4440.72,730.418 4215.79,808.427C4215.79,808.427 4426.17,883.346 4494.03,995.424C4494.03,995.424 4524.71,1086.06 4545.76,1093.5C4674.01,1138.81 4614.12,918.558 4614.12,918.558Z"
        style="fill: rgb(166, 202, 141); fill-rule: nonzero"
      />
      <path
        d="M5047.72,1352.8L4501.96,1352.8C4449.55,1352.8 4406.66,1309.92 4406.66,1257.5L4406.66,927.362C4406.66,874.946 4449.55,832.06 4501.96,832.06L5047.72,832.06C5100.14,832.06 5143.02,874.946 5143.02,927.362L5143.02,1257.5C5143.02,1309.92 5100.14,1352.8 5047.72,1352.8Z"
        style="fill: rgb(75, 123, 54); fill-rule: nonzero"
      />
      <rect
        x="4473.65"
        y="887.697"
        width="602.88"
        height="402.243"
        style="fill: white; fill-rule: nonzero"
      />
      <rect
        x="4555.54"
        y="957.642"
        width="422.2"
        height="99.848"
        style="fill: rgb(75, 123, 54); fill-rule: nonzero"
      />
      <rect
        x="4911.36"
        y="957.642"
        width="66.38"
        height="99.848"
        style="fill: rgb(255, 176, 0); fill-rule: nonzero"
      />
      <path
        d="M4601.56,1029.74C4603.34,1029.74 4604.95,1029.24 4606.38,1028.25C4607.82,1027.27 4609.06,1025.73 4610.12,1023.66C4611.17,1021.59 4611.98,1018.94 4612.56,1015.72C4613.13,1012.5 4613.42,1008.65 4613.42,1004.19C4613.42,999.72 4613.13,995.893 4612.56,992.702C4611.98,989.512 4611.17,986.912 4610.12,984.902C4609.06,982.893 4607.82,981.41 4606.38,980.454C4604.95,979.495 4603.34,979.018 4601.56,979.018C4599.77,979.018 4598.14,979.495 4596.67,980.454C4595.21,981.41 4593.95,982.893 4592.89,984.902C4591.84,986.912 4591.02,989.512 4590.45,992.702C4589.88,995.893 4589.59,999.72 4589.59,1004.19C4589.59,1013.12 4590.69,1019.61 4592.89,1023.66C4595.09,1027.71 4597.98,1029.74 4601.56,1029.74ZM4601.56,1036.05C4595.37,1036.05 4590.55,1033.31 4587.1,1027.82C4583.66,1022.34 4581.94,1014.46 4581.94,1004.19C4581.94,993.914 4583.66,986.1 4587.1,980.739C4590.55,975.381 4595.37,972.701 4601.56,972.701C4607.68,972.701 4612.46,975.381 4615.91,980.739C4619.35,986.1 4621.08,993.914 4621.08,1004.19C4621.08,1014.46 4619.35,1022.34 4615.91,1027.82C4612.46,1033.31 4607.68,1036.05 4601.56,1036.05Z"
        style="fill: white; fill-rule: nonzero"
      />
      <g transform="matrix(1,0,0,1,57.2412,0)">
        <path
          d="M4601.56,1029.74C4603.34,1029.74 4604.95,1029.24 4606.38,1028.25C4607.82,1027.27 4609.06,1025.73 4610.12,1023.66C4611.17,1021.59 4611.98,1018.94 4612.56,1015.72C4613.13,1012.5 4613.42,1008.65 4613.42,1004.19C4613.42,999.72 4613.13,995.893 4612.56,992.702C4611.98,989.512 4611.17,986.912 4610.12,984.902C4609.06,982.893 4607.82,981.41 4606.38,980.454C4604.95,979.495 4603.34,979.018 4601.56,979.018C4599.77,979.018 4598.14,979.495 4596.67,980.454C4595.21,981.41 4593.95,982.893 4592.89,984.902C4591.84,986.912 4591.02,989.512 4590.45,992.702C4589.88,995.893 4589.59,999.72 4589.59,1004.19C4589.59,1013.12 4590.69,1019.61 4592.89,1023.66C4595.09,1027.71 4597.98,1029.74 4601.56,1029.74ZM4601.56,1036.05C4595.37,1036.05 4590.55,1033.31 4587.1,1027.82C4583.66,1022.34 4581.94,1014.46 4581.94,1004.19C4581.94,993.914 4583.66,986.1 4587.1,980.739C4590.55,975.381 4595.37,972.701 4601.56,972.701C4607.68,972.701 4612.46,975.381 4615.91,980.739C4619.35,986.1 4621.08,993.914 4621.08,1004.19C4621.08,1014.46 4619.35,1022.34 4615.91,1027.82C4612.46,1033.31 4607.68,1036.05 4601.56,1036.05Z"
          style="fill: white; fill-rule: nonzero"
        />
      </g>
      <path
        d="M4715.37,1029.74C4717.16,1029.74 4718.77,1029.24 4720.2,1028.25C4721.64,1027.27 4722.88,1025.73 4723.94,1023.66C4724.99,1021.59 4725.8,1018.94 4726.38,1015.72C4726.95,1012.5 4727.24,1008.65 4727.24,1004.19C4727.24,999.72 4726.95,995.893 4726.38,992.702C4725.8,989.512 4724.99,986.912 4723.94,984.902C4722.88,982.893 4721.64,981.41 4720.2,980.454C4718.77,979.495 4717.16,979.018 4715.37,979.018C4713.58,979.018 4711.96,979.495 4710.49,980.454C4709.02,981.41 4707.76,982.893 4706.71,984.902C4705.65,986.912 4704.84,989.512 4704.27,992.702C4703.7,995.893 4703.41,999.72 4703.41,1004.19C4703.41,1013.12 4704.51,1019.61 4706.71,1023.66C4708.91,1027.71 4711.8,1029.74 4715.37,1029.74ZM4715.37,1036.05C4709.18,1036.05 4704.36,1033.31 4700.92,1027.82C4697.48,1022.34 4695.75,1014.46 4695.75,1004.19C4695.75,993.914 4697.48,986.1 4700.92,980.739C4704.36,975.381 4709.18,972.701 4715.37,972.701C4721.49,972.701 4726.28,975.381 4729.72,980.739C4733.17,986.1 4734.89,993.914 4734.89,1004.19C4734.89,1014.46 4733.17,1022.34 4729.72,1027.82C4726.28,1033.31 4721.49,1036.05 4715.37,1036.05Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4771.04,1036.05C4768.61,1036.05 4766.39,1035.81 4764.38,1035.34C4762.38,1034.86 4760.54,1034.22 4758.88,1033.42C4757.22,1032.62 4755.74,1031.71 4754.43,1030.69C4753.12,1029.67 4751.96,1028.59 4750.94,1027.44L4754.96,1022.27C4756.81,1024.19 4758.96,1025.88 4761.42,1027.34C4763.87,1028.81 4766.92,1029.55 4770.56,1029.55C4774.26,1029.55 4777.29,1028.54 4779.65,1026.53C4782.01,1024.52 4783.19,1021.79 4783.19,1018.35C4783.19,1016.56 4782.85,1014.92 4782.19,1013.42C4781.52,1011.92 4780.42,1010.63 4778.88,1009.54C4777.35,1008.46 4775.34,1007.63 4772.85,1007.06C4770.36,1006.48 4767.3,1006.19 4763.67,1006.19L4763.67,1000.17C4766.92,1000.17 4769.65,999.88 4771.85,999.305C4774.05,998.732 4775.84,997.933 4777.21,996.913C4778.58,995.893 4779.55,994.681 4780.13,993.277C4780.7,991.874 4780.99,990.375 4780.99,988.779C4780.99,985.78 4780.05,983.421 4778.16,981.698C4776.28,979.975 4773.71,979.114 4770.46,979.114C4767.91,979.114 4765.56,979.687 4763.43,980.835C4761.29,981.985 4759.3,983.485 4757.45,985.333L4753.23,980.358C4755.59,978.126 4758.2,976.291 4761.03,974.856C4763.87,973.42 4767.11,972.701 4770.75,972.701C4773.43,972.701 4775.88,973.053 4778.11,973.753C4780.35,974.456 4782.28,975.463 4783.91,976.768C4785.53,978.078 4786.79,979.687 4787.69,981.602C4788.58,983.514 4789.03,985.717 4789.03,988.204C4789.03,991.906 4788.01,994.937 4785.97,997.296C4783.92,999.656 4781.24,1001.48 4777.93,1002.75L4777.93,1003.13C4779.78,1003.58 4781.5,1004.23 4783.09,1005.09C4784.69,1005.96 4786.09,1007.04 4787.3,1008.35C4788.52,1009.66 4789.46,1011.17 4790.13,1012.9C4790.79,1014.62 4791.13,1016.53 4791.13,1018.64C4791.13,1021.32 4790.61,1023.74 4789.55,1025.91C4788.5,1028.08 4787.07,1029.91 4785.24,1031.41C4783.43,1032.91 4781.31,1034.06 4778.88,1034.86C4776.46,1035.65 4773.84,1036.05 4771.04,1036.05Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4834.45,1011.74L4834.45,994.042C4834.45,992.383 4834.5,990.422 4834.59,988.156C4834.69,985.892 4834.8,983.932 4834.93,982.27L4834.55,982.27C4833.78,983.74 4832.98,985.176 4832.15,986.577C4831.33,987.983 4830.46,989.418 4829.57,990.883L4815.31,1011.74L4834.45,1011.74ZM4850.24,1018.06L4841.92,1018.06L4841.92,1034.9L4834.45,1034.9L4834.45,1018.06L4806.98,1018.06L4806.98,1012.9L4833.11,973.849L4841.92,973.849L4841.92,1011.74L4850.24,1011.74L4850.24,1018.06Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4880.74,1019.11C4881.5,1014.33 4882.57,1009.78 4883.94,1005.48C4885.31,1001.17 4887.07,997.009 4889.21,992.99C4891.34,988.971 4893.91,984.856 4896.91,980.646L4866.48,980.646L4866.48,973.849L4905.81,973.849L4905.81,978.731C4902.17,983.325 4899.22,987.725 4896.96,991.936C4894.69,996.148 4892.89,1000.44 4891.55,1004.81C4890.21,1009.18 4889.24,1013.8 4888.63,1018.69C4888.02,1023.56 4887.6,1028.97 4887.34,1034.9L4879.21,1034.9C4879.46,1029.16 4879.97,1023.9 4880.74,1019.11Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4941.57,1010.12C4939.15,1010.12 4936.93,1009.88 4934.92,1009.4C4932.91,1008.92 4931.09,1008.3 4929.46,1007.54C4927.84,1006.77 4926.37,1005.9 4925.06,1004.91C4923.75,1003.92 4922.59,1002.88 4921.57,1001.79L4925.4,996.628C4927.18,998.479 4929.31,1000.11 4931.76,1001.51C4934.22,1002.91 4937.23,1003.61 4940.8,1003.61C4942.66,1003.61 4944.4,1003.28 4946.02,1002.61C4947.64,1001.94 4949.06,1001 4950.28,999.786C4951.49,998.574 4952.45,997.105 4953.15,995.384C4953.85,993.661 4954.2,991.746 4954.2,989.642C4954.2,985.431 4953.02,982.145 4950.66,979.785C4948.3,977.425 4945.14,976.243 4941.19,976.243C4939.08,976.243 4937.28,976.563 4935.78,977.202C4934.28,977.838 4932.6,978.765 4930.76,979.977L4926.54,977.295L4928.56,947.917L4959.08,947.917L4959.08,954.713L4935.44,954.713L4933.82,972.799C4935.28,972.033 4936.75,971.428 4938.22,970.98C4939.69,970.535 4941.35,970.31 4943.19,970.31C4945.81,970.31 4948.27,970.693 4950.57,971.46C4952.86,972.224 4954.87,973.388 4956.6,974.951C4958.32,976.515 4959.67,978.493 4960.66,980.885C4961.65,983.277 4962.15,986.134 4962.15,989.45C4962.15,992.769 4961.57,995.703 4960.42,998.255C4959.27,1000.81 4957.74,1002.96 4955.83,1004.71C4953.92,1006.47 4951.73,1007.81 4949.27,1008.73C4946.82,1009.66 4944.25,1010.12 4941.57,1010.12Z"
        style="fill: white; fill-rule: nonzero"
      />
      <path
        d="M4630.66,964.114L4630.66,1044.08"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.29px"
      />
      <path
        d="M4686.82,964.114L4686.82,1044.08"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.29px"
      />
      <path
        d="M4742.98,964.114L4742.98,1044.08"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.29px"
      />
      <path
        d="M4799.15,964.114L4799.15,1044.08"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.29px"
      />
      <path
        d="M4855.31,964.114L4855.31,1044.08"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 3.29px"
      />
      <path
        d="M4966.95,1144.7L4566.33,1144.7C4560.4,1144.7 4555.54,1139.85 4555.54,1133.92C4555.54,1127.98 4560.4,1123.13 4566.33,1123.13L4966.95,1123.13C4972.88,1123.13 4977.74,1127.98 4977.74,1133.92C4977.74,1139.85 4972.88,1144.7 4966.95,1144.7Z"
        style="fill: rgb(228, 238, 221); fill-rule: nonzero"
      />
      <rect
        x="4693.6"
        y="1123.13"
        width="128.2"
        height="21.57"
        style="fill: rgb(255, 176, 0); fill-rule: nonzero"
      />
      <rect
        x="4577.12"
        y="1171.51"
        width="389.52"
        height="78.89"
        style="fill: none; stroke: rgb(28, 67, 31); stroke-width: 3.56px"
      />
      <rect
        x="4608.55"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4624.19"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4894.76"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4910.4"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4653.7"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4683.89"
        y="1189.08"
        width="6.48"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4771.88"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4786.78"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4801.03"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4728.89"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4742.91"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4666.03"
        y="1189.08"
        width="10.55"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4831.52"
        y="1189.08"
        width="6.47"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4843.84"
        y="1189.08"
        width="10.55"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4861.72"
        y="1189.08"
        width="10.55"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4921.57"
        y="1189.08"
        width="10.55"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <rect
        x="4711.94"
        y="1189.08"
        width="10.55"
        height="49.92"
        style="fill: rgb(28, 67, 31); fill-rule: nonzero"
      />
      <g transform="matrix(2.04354,0,0,2.04354,-6423.26,-658.56)">
        <path
          d="M5206.2,857.513C5206.2,923.316 5152.86,976.66 5087.05,976.66C5021.25,976.66 4967.91,923.316 4967.91,857.513C4967.91,791.711 5021.25,738.367 5087.05,738.367C5152.86,738.367 5206.2,791.71 5206.2,857.513Z"
          style="fill: rgb(233, 174, 95)"
        />
        <path
          d="M5069.23,786.608L5120.06,785.913C5120.4,785.909 5120.64,786.244 5120.52,786.562L5096.57,852.509C5096.45,852.828 5096.69,853.165 5097.03,853.157L5142.53,852.103C5142.95,852.093 5143.18,852.586 5142.9,852.903L5062.49,945.768C5062.16,946.144 5061.55,945.843 5061.65,945.356L5075.5,876.584C5075.56,876.283 5075.33,876.003 5075.02,876.005L5041.93,876.165C5041.61,876.167 5041.37,875.851 5041.47,875.539L5068.78,786.949C5068.84,786.749 5069.02,786.611 5069.23,786.608Z"
          style="fill: white; fill-rule: nonzero"
        />
        <path
          d="M5092.21,978.044C5036.06,981.213 4983.84,944.099 4969.93,887.505"
          style="
            fill: rgb(233, 174, 95);
            stroke: rgb(28, 67, 31);
            stroke-width: 1.75px;
          "
        />
      </g>
      <path
        d="M4715.53,1070.83L4719.06,1070.83L4719.06,1091.86L4719.19,1091.86L4728.22,1080.69L4732.19,1080.69L4725.08,1089.2L4733.15,1101.9L4729.23,1101.9L4723.03,1091.69L4719.06,1096.31L4719.06,1101.9L4715.53,1101.9L4715.53,1070.83Z"
        style="fill-rule: nonzero"
      />
      <path
        d="M4734.55,1073.28L4738.3,1073.28L4741.31,1088.85C4741.57,1090.42 4741.85,1091.96 4742.14,1093.48C4742.43,1094.99 4742.71,1096.53 4742.97,1098.1L4743.14,1098.1C4743.46,1096.53 4743.8,1094.98 4744.15,1093.45C4744.5,1091.93 4744.83,1090.39 4745.15,1088.85L4749.12,1073.28L4752.44,1073.28L4756.41,1088.85C4756.76,1090.36 4757.1,1091.89 4757.45,1093.43C4757.8,1094.97 4758.15,1096.53 4758.5,1098.1L4758.67,1098.1C4758.94,1096.53 4759.2,1094.98 4759.46,1093.45C4759.72,1091.93 4760,1090.39 4760.29,1088.85L4763.3,1073.28L4766.79,1073.28L4760.86,1101.9L4756.49,1101.9L4752.17,1084.66C4751.91,1083.56 4751.67,1082.47 4751.46,1081.41C4751.24,1080.35 4751.01,1079.27 4750.78,1078.16L4750.6,1078.16C4750.37,1079.27 4750.13,1080.35 4749.88,1081.41C4749.64,1082.47 4749.4,1083.56 4749.16,1084.66L4744.93,1101.9L4740.61,1101.9L4734.55,1073.28Z"
        style="fill-rule: nonzero"
      />
      <rect
        x="4769.19"
        y="1089.59"
        width="10.03"
        height="2.75"
        style="fill-rule: nonzero"
      />
      <path
        d="M4784.55,1070.83L4788.12,1070.83L4788.12,1079.3L4787.99,1083.66C4789.01,1082.7 4790.07,1081.88 4791.18,1081.2C4792.28,1080.51 4793.58,1080.17 4795.06,1080.17C4797.3,1080.17 4798.94,1080.87 4799.97,1082.26C4801,1083.66 4801.52,1085.72 4801.52,1088.46L4801.52,1101.9L4797.94,1101.9L4797.94,1088.94C4797.94,1086.93 4797.62,1085.48 4796.98,1084.6C4796.34,1083.71 4795.32,1083.27 4793.93,1083.27C4792.82,1083.27 4791.85,1083.54 4791,1084.1C4790.16,1084.65 4789.2,1085.46 4788.12,1086.54L4788.12,1101.9L4784.55,1101.9L4784.55,1070.83Z"
        style="fill-rule: nonzero"
      />
      <path
        d="M4681.09,1352.8L4753.34,1352.8"
        style="fill: none; stroke: rgb(28, 67, 31); stroke-width: 4.39px"
      />
      <path
        d="M4798.81,1352.8L4871.06,1352.8"
        style="fill: none; stroke: rgb(28, 67, 31); stroke-width: 4.39px"
      />
    </g>
    <g transform="matrix(1.15284,0,0,1.15284,-4532.65,-370.759)">
      <path
        d="M4198.9,1234.49C4198.9,1241.21 4193.45,1246.66 4186.72,1246.66C4180,1246.66 4174.55,1241.21 4174.55,1234.49C4174.55,1227.77 4180,1222.32 4186.72,1222.32C4193.45,1222.32 4198.9,1227.77 4198.9,1234.49Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 2.37px"
      />
    </g>
    <g transform="matrix(1.15284,0,0,1.15284,-4532.65,-370.759)">
      <path
        d="M4832.72,545.189C4832.72,551.912 4827.27,557.362 4820.55,557.362C4813.83,557.362 4808.38,551.912 4808.38,545.189C4808.38,538.466 4813.83,533.016 4820.55,533.016C4827.27,533.016 4832.72,538.466 4832.72,545.189Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 2.37px"
      />
    </g>
    <g transform="matrix(1.15284,0,0,1.15284,-4532.65,-370.759)">
      <path
        d="M5512.3,1080.24C5512.3,1086.96 5506.85,1092.41 5500.13,1092.41C5493.41,1092.41 5487.96,1086.96 5487.96,1080.24C5487.96,1073.51 5493.41,1068.06 5500.13,1068.06C5506.85,1068.06 5512.3,1073.51 5512.3,1080.24Z"
        style="fill: none; stroke: rgb(0, 81, 62); stroke-width: 2.37px"
      />
    </g>
    <g transform="matrix(4.8519,0,0,4.8519,-4181.27,-1429.8)">
      <text
        x="1200px"
        y="420px"
        text-anchor="end"
        style="
          font-family: 'Lexend-Light', 'Lexend';
          font-weight: 300;
          font-size: 40px;
          fill: rgb(29, 89, 28);
        "
      >
        {{ getElectricity() }} kWh
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PaperUsage",
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters.getloading;
    },
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
    getInfoByActiveYear() {
      return this.$store.getters.getInfovaluesByActiveYear;
    },
  },
  created() {},
  methods: {
    getElectricity() {
      let value = this.getInfoByActiveYear.electricity.electricity;
      return value;
    },
  },
};
</script>
