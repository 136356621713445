<template>
  <section class="people-section">
    <div class="container people-container">
      <div class="row align-items-center pb-4">
        <div class="col-md-4 align-middle">
          <ProfitImg />
        </div>
        <div class="col-md-8">
          <h1>Wij gaan voor maatschappelijke winst</h1>
          <p class="lead">
            Wij hebben ervoor gekozen om profit zo breed mogelijk op te pakken,
            het gaat ons om maatschappelijke winst. Welke bijdrage kunnen we als
            bedrijf leveren aan een meer duurzame en sociale wereld?
          </p>
          <a type="button" class="btn btn-primary text-white" href="/profit">
            Lees meer...
          </a>
        </div>
      </div>
      <!-- <div class="row">
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2022 ? 'active' : '']"
              aria-current="page"
              @click="setYear(2022)"
              >2022</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2023 ? 'active' : '']"
              @click="setYear(2023)"
              >2023</a
            >
          </li>
        </ul>
      </div>
      <div class="row pt-5"></div> -->
      <!-- <div class="row pt-5">
        <div class="col-md-6 text-center">
          <InOutFlowInfo />
        </div>
        <div class="col-md-6 text-center">
          <AccidentsInfo />
        </div>
      </div> -->
      <!-- <div class="row pt-5">
        <div class="col-md-12 text-center">
          <div class="d-grid">
            <button type="button" class="btn btn-primary text-white">
              Acties en maatregelen in 2023
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import ProfitImg from "@/components/ProfitImg.vue";

export default {
  name: "PeopleSection",
  components: {
    ProfitImg,
  },
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
  },
  created() {
    this.$store.commit("setActiveYear", 0);
    this.$store.commit("setActiveYear", 2023);
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
  },
};
</script>

<style scoped>
.people-section {
  padding: 2em;
}
.people-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.active {
  font-weight: 500;
}
.nav-link {
  cursor: pointer;
}
</style>
