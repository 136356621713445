<template>
  <section class="hero-section">
    <div class="container hero-container">
      <div class="row align-items-center">
        <div class="col-md-6 align-middle">
          <div class="h-100">
            <h1 class="display-1">Hoe Quantes investeert in duurzaamheid</h1>
          </div>
        </div>
        <div class="col-md-6">
          <HeroImg />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeroImg from "./HeroImg.vue";

HeroImg;
export default {
  name: "HeroSection",
  components: {
    HeroImg,
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #f5f9f0;
}
.hero-container {
  padding: 2em;
}
</style>
