<template>
  <section class="footer-section p-5">
    <div class="container">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <p>
            Laan van Zuid Hoorn 60<br />
            2289 DE Rijswijk<br />
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <strong>
              <a href="tel:+0704134013">070 413 40 13</a><br />
              <a href="mailto:info@quantes.nl">info@quantes.nl</a>
            </strong>
          </p>
        </div>
        <div class="col-md-3">
          <a class="navbar-brand" href="https://quantes.nl" target="_blank">
            <img
              class="logo"
              src="../assets/logo.svg"
              alt="Bootstrap"
              width="141"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlanetSection",
  components: {},
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
  },
};
</script>

<style scoped>
.footer-section {
  background-color: #f5f9f0;
}
.footer-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.nav-tabs .nav-link.active {
  background-color: #f5f9f0 !important;
  border-bottom-color: #f5f9f0;
}
.logo {
  filter: brightness(0) saturate(100%) invert(9%) sepia(29%) saturate(6725%)
    hue-rotate(231deg) brightness(85%) contrast(94%);
}
</style>
