<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 2000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-miterlimit: 2;
    "
  >
    <rect
      id="Artboard1"
      x="0"
      y="0"
      width="2000"
      height="2000"
      style="fill: none"
    />
    <g id="Artboard11" serif:id="Artboard1">
      <g
        id="Achtergrond"
        transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)"
      >
        <path
          d="M4366.32,548.441C4417.7,673.197 4468.99,962.977 4226.36,1012.33C4034.4,1051.37 4027.13,842.409 3700.99,829.162C3700.99,829.162 3469.81,845.832 3406.21,597.289C3358.7,411.617 3572.9,168.937 3912.42,213.413C4141.97,243.483 4290.39,364.082 4366.32,548.441Z"
          style="fill: rgb(245, 249, 241); fill-rule: nonzero"
        />
      </g>
      <g id="Raam">
        <g transform="matrix(0.581035,0,0,0.581035,152.896,101.284)">
          <rect
            x="463.912"
            y="355.268"
            width="286.578"
            height="293.747"
            style="fill: white"
          />
        </g>
        <g transform="matrix(0.581035,0,0,0.581035,152.896,296.04)">
          <rect
            x="463.912"
            y="355.268"
            width="286.578"
            height="293.747"
            style="fill: white"
          />
        </g>
        <g transform="matrix(0.581035,0,0,0.581035,349.662,101.284)">
          <rect
            x="463.912"
            y="355.268"
            width="286.578"
            height="293.747"
            style="fill: white"
          />
        </g>
        <g transform="matrix(0.581035,0,0,0.581035,349.662,296.04)">
          <rect
            x="463.912"
            y="355.268"
            width="286.578"
            height="293.747"
            style="fill: white"
          />
        </g>
      </g>
      <g id="Balletjes">
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4316.82,859.866C4316.82,865.26 4312.45,869.633 4307.06,869.633C4301.66,869.633 4297.29,865.26 4297.29,859.866C4297.29,854.472 4301.66,850.099 4307.06,850.099C4312.45,850.099 4316.82,854.472 4316.82,859.866Z"
            style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3572.15,430.848C3572.15,436.242 3567.78,440.615 3562.38,440.615C3556.99,440.615 3552.62,436.242 3552.62,430.848C3552.62,425.454 3556.99,421.081 3562.38,421.081C3567.78,421.081 3572.15,425.454 3572.15,430.848Z"
            style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4028.06,295.628C4028.06,301.022 4023.69,305.395 4018.3,305.395C4012.9,305.395 4008.53,301.022 4008.53,295.628C4008.53,290.234 4012.9,285.862 4018.3,285.862C4023.69,285.862 4028.06,290.234 4028.06,295.628Z"
            style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.39px"
          />
        </g>
      </g>
      <g id="Man">
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3778.73,503.896C3778.73,503.896 3775.49,518.662 3772.81,530.893C3772.1,534.151 3769.38,536.792 3765.7,537.803C3762.02,538.813 3757.96,538.036 3755.06,535.769C3754.11,535.027 3753.19,534.304 3752.32,533.619C3748.34,530.512 3746.78,525.757 3748.3,521.409C3752.19,510.268 3759.28,489.972 3759.28,489.972L3778.73,503.896Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3777.77,512.072L3775.03,520.792L3766.8,511.421L3777.77,512.072Z"
            style="fill: rgb(155, 56, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3755.05,487.53C3754.16,483.084 3757.01,478.752 3761.4,477.855C3766.1,476.898 3772.02,475.691 3776.71,474.734C3781.11,473.837 3784.72,476.906 3786.28,481.161C3788.12,486.182 3789.47,497.103 3789.51,505.147C3789.52,509.683 3786.92,514.401 3782.52,515.298C3777.82,516.254 3762.86,514.602 3759.8,506.394C3757.48,500.145 3756.36,494.075 3755.05,487.53Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3760.66,510.984C3744.25,508.952 3740.63,494.981 3745.62,483.113C3746.7,480.548 3747.93,478.343 3749.32,476.885C3758.02,467.743 3770.02,462.439 3783.77,473.126C3783.77,473.126 3782.4,493.456 3760.68,510.989L3760.66,510.984Z"
            style="fill: rgb(115, 55, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3767.14,501.2C3769.06,504.382 3772.73,505.66 3775.34,504.054C3777.94,502.448 3778.49,498.566 3776.57,495.384C3774.65,492.202 3770.99,490.924 3768.38,492.53C3765.78,494.136 3765.22,498.018 3767.14,501.2Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3786.51,489.211C3786.57,489.437 3789.46,493.026 3794.03,497.821C3796.3,500.204 3791.33,505.638 3788.36,504.582C3785.39,503.527 3781.47,493.944 3781.47,493.944L3786.51,489.211Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3787.04,505.721L3782.76,505.625C3782.76,505.625 3782.92,510.765 3786.37,510.226C3787.56,510.039 3787.04,505.721 3787.04,505.721Z"
            style="fill: white"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3872.42,1136.43C3872.42,1142.08 3810.95,1146.66 3735.12,1146.66C3659.29,1146.66 3597.82,1142.08 3597.82,1136.43C3597.82,1130.78 3659.29,1126.2 3735.12,1126.2C3810.95,1126.2 3872.42,1130.78 3872.42,1136.43Z"
            style="fill: rgb(227, 230, 236)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3771.15,1118.56C3771.15,1118.56 3748.76,994.322 3771.25,945.165L3752.99,776.626L3831.71,782.042L3793.13,1119.76L3771.15,1118.56Z"
            style="fill: rgb(255, 99, 56)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3793.81,1113.68L3805.45,1125.03C3808.6,1127.84 3825.49,1133.29 3825.49,1133.29C3827.26,1136.78 3824.06,1138.61 3821.31,1139.07L3771.93,1138.04C3771.93,1138.04 3760.46,1135.67 3769.89,1113.7C3779,1117.67 3791.68,1120.51 3793.81,1113.68Z"
            style="fill: rgb(82, 38, 18)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3835.24,791.313L3804.65,1094.2L3747.17,1094.2C3747.17,1094.2 3751.78,1069.32 3750.9,1061.43C3750.02,1053.53 3745.64,978.656 3762.11,944.209L3756.42,803.921L3835.24,791.313Z"
            style="fill: rgb(92, 41, 21)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3761.7,944.209L3747.17,803.437L3783.35,805.416L3787.51,908.148C3788.29,927.294 3786.25,946.522 3782.11,965.433C3773.93,1002.81 3777.04,1054.91 3777.76,1061.43C3778.64,1069.32 3774.03,1094.2 3774.03,1094.2L3746.76,1094.2C3746.76,1094.2 3751.37,1069.32 3750.49,1061.43C3749.62,1053.53 3745.24,978.656 3761.7,944.209Z"
            style="fill: rgb(67, 28, 8)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3719.16,693.099L3826.26,698.686C3843.61,703.997 3835.24,791.313 3835.24,791.313L3784.37,802.286C3751.62,802.15 3692.86,817.818 3689.55,770.536C3685.76,716.487 3719.16,693.099 3719.16,693.099Z"
            style="fill: rgb(92, 41, 21)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3658.09,1067.1C3658.18,1067.23 3665.55,1076.26 3666.67,1077.59C3665.22,1091.21 3668.16,1100.66 3668.67,1115.84C3674.46,1116.7 3676.25,1122.96 3682.07,1123.72C3683.71,1123.93 3682.53,1116.21 3684.03,1115.25L3698.97,1079.42L3658.09,1067.1Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3764.12,740.028C3744.18,859.173 3724.23,978.378 3699.87,1099.13C3682.63,1096.36 3650.68,1097.32 3650.68,1097.32C3650.68,1097.32 3654.63,1075.96 3654.87,1072.92C3660.59,1000.14 3653.65,989.722 3664.37,933.436C3670.58,875.708 3669.38,831.759 3674.82,786.947C3677.04,768.698 3675.92,738.511 3679.55,719.412C3708.23,721.724 3734.89,738.408 3763.48,741.444C3763.89,741.496 3764.31,741.549 3764.73,741.602C3765.09,741.941 3765.46,742.279 3764.12,740.028Z"
            style="fill: rgb(92, 41, 21); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3685.46,1111.4C3685.46,1111.4 3687.74,1125.59 3686.79,1134.46C3686.63,1135.98 3685.6,1137.62 3684.01,1138.87C3682.43,1140.12 3680.5,1140.82 3678.82,1140.76C3675.22,1140.62 3671.11,1140.46 3668.57,1140.36C3666.98,1140.3 3665.68,1139.75 3664.85,1138.79C3664.35,1138.22 3663.75,1137.53 3663.12,1136.8C3660.24,1133.47 3662,1129.78 3664.34,1124.57C3666.75,1119.19 3668.66,1113.06 3668.66,1113.06C3678.25,1106.79 3685.46,1111.4 3685.46,1111.4Z"
            style="fill: rgb(67, 28, 10)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3929.25,703.582L3937.39,718.01C3939.01,720.894 3941.65,723.148 3944.81,724.363L3957.6,729.283C3962.06,730.998 3966.81,727.941 3966.79,723.362L3955.52,705.759C3953.03,701.875 3948.88,698.794 3943.91,697.135L3935.63,694.371L3929.25,703.582Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3942.06,712.593C3942.06,712.593 3881.86,703.469 3844.79,695.706C3806.2,687.626 3835.58,644.607 3857.58,655.546C3866.63,660.049 3937.64,695.071 3937.64,695.071L3942.06,712.593Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3825.7,538.398L3784.33,525.327L3748.29,522.012L3713.76,531.591C3698.13,538.796 3684.81,540.389 3684.17,557.766L3678.55,629.522C3665.67,668.504 3665.09,697.539 3666.18,738.007C3730.66,763.544 3828.71,759.939 3849.96,730.846C3848.46,649.859 3849.51,626.109 3825.7,538.398Z"
            style="fill: rgb(253, 204, 121)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3783.99,525.326C3783.99,525.326 3795.75,561.055 3771.27,562.014C3746.79,562.974 3736.13,525.875 3736.13,525.875L3754.5,518.429L3783.99,525.326Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3825.7,538.398C3840.87,550.412 3840.97,575.396 3864.06,646.043L3931.99,689.189L3916.63,711.327L3838.48,697.141C3838.48,697.141 3817.18,686.809 3821.88,671.368C3826.57,655.928 3811.16,603.828 3811.16,603.828C3811.16,603.828 3813.27,530.564 3825.7,538.398Z"
            style="fill: rgb(253, 204, 121); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3840.45,681.94C3839.07,677.242 3831.71,598.404 3825.7,583.847"
            style="
              fill: none;
              stroke: rgb(233, 174, 95);
              stroke-width: 1.34px;
              stroke-linejoin: round;
            "
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3688.17,545.456C3688.17,545.456 3721.09,568.97 3711.95,592.68C3711.95,592.68 3692.24,674.991 3683.58,689.157C3671.97,708.121 3637.16,688.398 3637.16,688.398L3604.6,650.579L3637.81,660.593L3672.81,570.104C3676.33,560.995 3681.54,552.633 3688.17,545.456Z"
            style="fill: rgb(253, 204, 121); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3694.79,633.034L3703.16,589.554"
            style="
              fill: none;
              stroke: rgb(233, 174, 95);
              stroke-width: 1.34px;
              stroke-linejoin: round;
            "
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3637.16,688.398L3623.34,691.33C3620.58,691.916 3617.65,691.484 3615.09,690.113L3604.71,684.561C3601.09,682.626 3600.36,677.774 3603.29,675.164L3620.91,672.468C3624.8,671.873 3629.11,672.822 3632.98,675.124L3635.59,676.672C3637.88,678.034 3639.01,680.71 3638.39,683.285L3637.16,688.398Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3722.47,675.011L3620.04,675.011L3620.04,667.261L3714.72,667.261C3719,667.261 3722.47,670.731 3722.47,675.011Z"
            style="fill: white"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3688.06,675.011L3591,675.011L3561.71,609.743L3656.55,609.742C3657.91,609.742 3659.13,610.537 3659.69,611.771L3688.06,675.011Z"
            style="fill: white"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3684.3,675.011L3587.23,675.011L3559.3,612.747C3558.66,611.337 3559.69,609.743 3561.24,609.743L3653.63,609.743C3654.47,609.743 3655.23,610.235 3655.58,611.001L3684.3,675.011Z"
            style="fill: rgb(92, 41, 21)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3624.65,642.788C3625.8,646.858 3622.65,650.158 3617.61,650.158C3612.57,650.158 3607.55,646.858 3606.4,642.788C3605.24,638.717 3608.39,635.417 3613.43,635.417C3618.47,635.417 3623.49,638.717 3624.65,642.788Z"
            style="fill: white"
          />
        </g>
      </g>
      <g id="Grafiek">
        <g transform="matrix(2.8844,0,0,2.8844,-10279.9,-707.471)">
          <path
            d="M3900.45,379.911C3908.62,375.948 3917.8,373.725 3927.49,373.725C3961.74,373.725 3989.51,401.495 3989.51,435.752C3989.51,470.009 3961.74,497.779 3927.49,497.779C3893.23,497.779 3865.46,470.009 3865.46,435.752C3865.46,427.341 3867.13,419.32 3870.17,412.006"
            style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 0.79px"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2659.02"
            y="463.365"
            width="196.56"
            height="72.893"
            style="
              fill: none;
              stroke: rgb(101, 157, 69);
              stroke-width: 1.6px;
              stroke-linejoin: round;
            "
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2673.41"
            y="463.38"
            width="22.94"
            height="31.256"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2719.91"
            y="464.101"
            width="22.95"
            height="65.428"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2766.42"
            y="462.629"
            width="22.95"
            height="45.162"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2812.93"
            y="462.629"
            width="22.94"
            height="22.758"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2673.41"
            y="434.362"
            width="22.94"
            height="30.137"
            style="fill: rgb(130, 190, 91); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2719.91"
            y="400.116"
            width="22.95"
            height="62.513"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2766.42"
            y="380.035"
            width="22.95"
            height="83.345"
            style="fill: rgb(253, 204, 121); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <rect
            x="2812.93"
            y="413.931"
            width="22.94"
            height="49.449"
            style="fill: rgb(101, 157, 69); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <path
            d="M2648.25,463.365L2871.85,463.365"
            style="fill: none; stroke: rgb(29, 89, 28); stroke-width: 1.91px"
          />
        </g>
        <g transform="matrix(1.23802,0,0,1.18582,-2368.33,0.818708)">
          <path
            d="M2863.21,457.696L2874.95,463.379L2863.21,469.035"
            style="
              fill: none;
              stroke: rgb(29, 89, 28);
              stroke-width: 1.91px;
              stroke-linejoin: round;
            "
          />
        </g>
      </g>
      <g id="Vrouw">
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4117.46,799.821C4117.46,799.821 4135.45,924.181 4137.52,946.692C4138.24,954.472 4240.39,1094.59 4240.39,1094.59C4252.49,1095.49 4255.12,1086.46 4248.8,1074.05C4222.55,957.843 4187.43,940.686 4187.43,940.686C4187.43,940.686 4185.8,886.659 4197.06,789.157L4117.46,799.821Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4054.52,787.978L4084.09,949.774L4107.3,1120.74L4122.13,1121.26C4122.13,1121.26 4129.47,1048.19 4130.75,1039.75C4132.04,1031.32 4143.44,977.334 4127.52,940.518L4141.25,783.209L4054.52,787.978Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4007.39,1135.59C4007.39,1142.88 4072.61,1148.79 4153.07,1148.79C4233.52,1148.79 4298.74,1142.88 4298.74,1135.59C4298.74,1128.3 4233.52,1122.39 4153.07,1122.39C4072.61,1122.39 4007.39,1128.3 4007.39,1135.59Z"
            style="fill: rgb(227, 230, 236)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4106.69,1111.61L4089.3,1123.52C4086.55,1126.53 4069.76,1132.36 4069.76,1132.36C4068.61,1136.09 4072.39,1138.05 4075.4,1138.55L4127.58,1137.44C4127.58,1137.44 4139.26,1134.91 4124.66,1111.4C4115.83,1115.64 4110.38,1118.92 4106.69,1111.61Z"
            style="fill: rgb(82, 38, 18)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4240.42,1092.74L4245.88,1113.1C4247.79,1116.7 4247.71,1134.47 4247.71,1134.47C4250.84,1136.8 4253.95,1133.88 4255.42,1131.21L4270.59,1085.1C4270.59,1085.1 4275.87,1067.78 4248.84,1073.73C4249.91,1083.47 4248.54,1091.69 4240.42,1092.74Z"
            style="fill: rgb(82, 38, 18)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4041.29,720.19L4069.07,933.716C4069.07,933.716 4145.13,942.596 4202.13,931.659C4192.83,799.243 4205.81,745.879 4179.93,720.19L4041.29,720.19Z"
            style="fill: rgb(92, 41, 21)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4105.18,490.525C4101.11,487.436 4077.1,503.032 4103.74,524.361L4117.33,497.229L4105.18,490.525Z"
            style="fill: rgb(115, 55, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4125.85,521.008C4131.29,518.216 4138,519.029 4143.55,521.593C4149.1,524.158 4153.75,528.28 4158.41,532.24C4163.07,536.2 4167.94,540.126 4173.7,542.17C4172.45,548.033 4168.78,553.158 4164.33,557.18C4159.88,561.202 4154.66,564.248 4149.48,567.264C4146.2,569.173 4142.38,571.147 4138.78,569.924C4136.01,568.981 4134.2,566.376 4132.64,563.9C4128.66,557.596 4124.95,550.984 4123.17,543.744C4121.39,536.504 4121.71,528.496 4125.25,521.906C4124.71,522.304 4124.17,522.702 4125.85,521.008Z"
            style="fill: rgb(115, 55, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4006.21,627.065C3979.68,609.668 3970.37,601.773 3944.12,583.691L3931.76,595.138C3978.99,639.307 3972.35,650.825 3999.03,657.665C4022.92,640.75 4011.43,632.442 4006.21,627.065Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4080.37,546.574C4080.37,546.574 3981.4,626.033 3988.11,639.19L3990.91,645.078C3994.55,652.728 4004.42,654.913 4010.95,649.515L4059.64,609.289L4069.29,601.686L4080.37,546.574Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4081.22,545.245L4121.82,540.495L4132.95,540.931L4175.26,555.686C4189.51,562.736 4196.12,579.294 4190.68,594.287L4180.32,642.712C4176.81,685.222 4184.76,717.933 4195.09,742.436C4135.1,764.713 4057.25,765.332 4038.32,737.809C4041.51,662.565 4052,593.668 4081.22,545.245Z"
            style="fill: rgb(253, 204, 121)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4106.32,521.928C4106.32,521.928 4108.18,535.925 4109.73,547.519C4110.14,550.607 4112.17,553.23 4115.03,554.381C4117.89,555.531 4121.15,555.031 4123.55,553.071C4124.33,552.429 4125.1,551.805 4125.82,551.213C4129.11,548.526 4130.57,544.164 4129.58,540.011C4127.02,529.369 4122.37,509.983 4122.37,509.983L4106.32,521.928Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4106.69,529.632L4108.45,537.944L4115.41,529.634L4106.69,529.632Z"
            style="fill: rgb(155, 56, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4099.67,543.197C4099.67,543.197 4086.36,576.126 4108.97,577.573C4131.57,579.019 4143.94,544.792 4143.94,544.792L4127.43,537.457L4099.67,543.197Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4129.5,506.899C4130.82,502.978 4128.75,498.727 4124.87,497.404C4120.73,495.992 4115.52,494.212 4111.38,492.8C4107.5,491.478 4103.88,493.835 4101.97,497.505C4099.72,501.835 4097.23,511.561 4096.26,518.838C4095.72,522.941 4097.53,527.514 4101.41,528.836C4105.55,530.248 4119.29,530.492 4123,523.418C4125.83,518.033 4127.55,512.67 4129.5,506.899Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4137.65,524.512C4137.65,524.512 4140.99,504.839 4135.92,497.93C4129.1,488.645 4118.86,482.45 4105.18,490.525C4105.18,490.525 4110.02,507.74 4135.91,525.431L4137.65,524.512Z"
            style="fill: rgb(115, 55, 29)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4128.56,523.944C4126.45,526.6 4122.98,527.33 4120.81,525.574C4118.64,523.818 4118.59,520.241 4120.7,517.584C4122.8,514.927 4126.27,514.197 4128.44,515.953C4130.61,517.709 4130.66,521.287 4128.56,523.944Z"
            style="fill: rgb(230, 87, 49)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4098.84,516.432L4105.62,518.592C4105.62,518.592 4104.02,523.231 4100.88,521.802C4097.74,520.373 4098.84,516.432 4098.84,516.432Z"
            style="fill: white"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4081.26,545.261C4081.26,545.261 4070.15,547.986 4050.37,565.597C4025.37,587.861 3987.92,626.573 3984.81,634.543C3981.7,642.513 4022.95,646.085 4022.95,646.085L4067.31,607.815L4081.26,545.261Z"
            style="fill: rgb(253, 204, 121)"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4050.86,625.158L4058.17,597.282L4057.05,589.933"
            style="
              fill: none;
              stroke: rgb(233, 174, 95);
              stroke-width: 1.51px;
              stroke-linejoin: round;
            "
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M3942.23,596.59L3925.61,596.201C3922.28,596.123 3919.1,594.856 3916.64,592.629L3906.65,583.613C3903.17,580.47 3903.91,574.815 3908.08,572.661L3928.95,574.33C3933.55,574.698 3938.14,576.917 3941.79,580.54L3947.87,586.577L3942.23,596.59Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4022.95,646.085C4022.95,646.085 3998.9,670.836 3981.24,659.01C3963.57,647.185 3938.43,607.033 3938.43,607.033L3959.81,587.031L4003.32,622.387L4022.95,646.085Z"
            style="fill: rgb(253, 204, 121); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4185.22,563.453C4185.22,563.453 4153,586.786 4162.1,610.416C4162.1,610.416 4198.53,706.137 4207.17,720.254C4218.73,739.154 4225.41,699.063 4225.41,699.063L4269.09,668.116L4235.99,678.095L4234.48,625.348L4185.22,563.453Z"
            style="fill: rgb(253, 204, 121); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4232.88,730.188L4164.9,733.138C4161.41,733.29 4159.5,730.661 4160.64,727.296L4196.42,621.976C4197.56,618.612 4201.35,615.735 4204.84,615.584L4272.81,612.633C4276.3,612.482 4278.21,615.111 4277.07,618.475L4241.29,723.796C4240.15,727.16 4236.36,730.036 4232.88,730.188Z"
            style="fill: rgb(92, 41, 21); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4255.95,689.419L4266.5,680.103C4268.61,678.24 4270,675.637 4270.41,672.767L4272.07,661.141C4272.65,657.09 4269.17,653.608 4265.32,654.4L4252.71,666.903C4249.92,669.661 4248.14,673.682 4247.7,678.155L4247.41,681.165C4247.15,683.811 4248.64,686.317 4251.09,687.356L4255.95,689.419Z"
            style="fill: rgb(230, 87, 49); fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(1.88192,0,0,1.88192,-6347.84,-276.754)">
          <path
            d="M4170.09,599.68L4173.7,644.869"
            style="
              fill: none;
              stroke: rgb(233, 174, 95);
              stroke-width: 1.51px;
              stroke-linejoin: round;
            "
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PeopleImg",
};
</script>

<style scoped></style>
